import React from 'react'
import PropTypes from 'prop-types'
import './DeleteHasManyInput.scss'

const DeleteHasManyInput = ({ record, updateRecord, disabled, label }) => (
  <div className="DeleteHasManyInput">
    {record.id ? (
      <label className={disabled ? 'disabled' : undefined}>
        <input
          type="checkbox"
          checked={!!record._destroy}
          onChange={(e) => updateRecord({ _destroy: e.target.checked })}
          disabled={disabled}
        />
        Delete {label}
      </label>
    ) : (
      <button
        type="button"
        className={disabled ? 'disabled' : undefined}
        onClick={() => updateRecord({ _destroy: true })}
        disabled={disabled}
      >
        Remove {label}
      </button>
    )}
  </div>
)

DeleteHasManyInput.defaultProps = { disabled: false, label: '' }

DeleteHasManyInput.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  record: PropTypes.shape({}).isRequired,
  updateRecord: PropTypes.func.isRequired,
}

export { DeleteHasManyInput }
