import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  OrgInputPlainLabel,
  OrgSelectInput,
  OrgTextInput,
} from './OrgInputs.jsx'
import toOption from '../../../utilities/to_option.js'
import rubyConstants from '../../../ruby_constants.js'
import './HoursInputs.scss'

const normalizeTime = (value) => {
  const timeChunks = value
    .toUpperCase()
    .match(/^([01]\d|2[0-4]|\d):?([0-5]\d)?([AP]M?)?/)
  if (!timeChunks) return value

  let hour = parseInt(timeChunks[1], 10)
  const minutes = timeChunks[2]
  const mer = timeChunks[3]
  if (mer && mer.indexOf('P') > -1 && hour < 12) hour += 12
  if (hour === 24 || (mer === 'A' && hour === 12)) hour = 0

  let normalizedTime = `${String(hour).padStart(2, '0')}:`
  normalizedTime += minutes || '00'
  return normalizedTime
}

const dayHasHours = (currentHours, dayAbrev) =>
  !!currentHours &&
  Array.isArray(currentHours[dayAbrev]) &&
  currentHours[dayAbrev].length > 0

const DayRow = ({
  day,
  prefix,
  currentHours,
  updateNavigatorSupportOrg,
  fromServerErrors,
}) => {
  const dayAbrev = day.slice(0, 3)
  const hasHours = dayHasHours(currentHours, dayAbrev)
  const [inputsVisible, setInputsVisible] = useState(hasHours)
  const [inputValue0, setInputValue0] = useState(
    hasHours ? currentHours[dayAbrev][0] : ''
  )
  const [inputValue1, setInputValue1] = useState(
    hasHours ? currentHours[dayAbrev][1] : ''
  )

  const timeInputName = `${prefix}[${dayAbrev}][]`

  const hasErrors = fromServerErrors && fromServerErrors[dayAbrev]

  function normalizeAndUpdate(e, i) {
    const updateVal = normalizeTime(e.target.value)
    const dayArr = (currentHours && currentHours[dayAbrev]) || ['', '']
    dayArr[i] = updateVal
    updateNavigatorSupportOrg({ business_hours: { [dayAbrev]: dayArr } })
    ;[setInputValue0, setInputValue1][i](updateVal)
  }

  function toggleRow() {
    if (inputsVisible)
      updateNavigatorSupportOrg({ business_hours: { [dayAbrev]: null } })
    setInputsVisible(!inputsVisible)
  }

  function timeInput(i) {
    return (
      <input
        type="text"
        name={timeInputName}
        value={inputsVisible ? [inputValue0, inputValue1][i] : ''}
        onBlur={(e) => normalizeAndUpdate(e, i)}
        onChange={(e) => [setInputValue0, setInputValue1][i](e.target.value)}
      />
    )
  }

  return (
    <>
      <div className="toggles">
        <input
          type="checkbox"
          checked={inputsVisible}
          id={`toggle-hours-${dayAbrev}`}
          onChange={toggleRow}
        />
        <OrgInputPlainLabel label={day} inputId={`toggle-hours-${dayAbrev}`} />
      </div>
      <div
        className={`hours-inputs ${!inputsVisible ? 'invisible' : ''} ${
          hasErrors ? 'has-errors' : ''
        }`}
      >
        {timeInput(0)}
        <span>to</span>
        {timeInput(1)}
        {hasErrors && <span className="warning">{hasErrors}</span>}
      </div>
    </>
  )
}

const HoursInputs = ({
  currentNavigatorSupportOrg,
  updateNavigatorSupportOrg,
  prefix,
  isAdmin,
  fromServerErrors,
}) => {
  const days = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ]

  const hoursId = useRef(
    currentNavigatorSupportOrg.business_hours &&
      currentNavigatorSupportOrg.business_hours.id
  )

  const [destroy, setDestroy] = useState(
    !currentNavigatorSupportOrg.business_hours ||
      currentNavigatorSupportOrg.business_hours._destroy
  )

  return (
    <>
      <div className="toggles">
        <input
          type="checkbox"
          id="hours-toggle"
          checked={!destroy}
          onChange={(e) => {
            if (!e.target.checked)
              updateNavigatorSupportOrg({ business_hours: null })
            setDestroy(!e.target.checked)
          }}
        />
        <OrgInputPlainLabel
          label="Hours"
          isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
          inputId="hours-toggle"
        />
      </div>
      <input type="hidden" name={`${prefix}[_destroy]`} value={destroy} />
      <input
        type="hidden"
        name={`${prefix}[id]`}
        value={hoursId.current || ''}
      />
      {!destroy && (
        <>
          <OrgTextInput
            label="Hours Note (Optional)"
            name={`${prefix}[website_note_en]`}
            value={
              (currentNavigatorSupportOrg.business_hours || {}).website_note_en
            }
            onChange={(v) =>
              updateNavigatorSupportOrg({
                business_hours: { website_note_en: v },
              })
            }
            isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
          />
          {isAdmin && (
            <OrgTextInput
              label="Hours Note (Optional) - Spanish"
              name={`${prefix}[website_note_es]`}
              value={
                (currentNavigatorSupportOrg.business_hours || {})
                  .website_note_es
              }
              onChange={(v) =>
                updateNavigatorSupportOrg({
                  business_hours: { website_note_es: v },
                })
              }
              isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
            />
          )}
          <OrgSelectInput
            name={`${prefix}[time_zone]`}
            label="Timezone"
            value={
              (currentNavigatorSupportOrg.business_hours || {}).time_zone ||
              Intl.DateTimeFormat().resolvedOptions().timeZone
            }
            onChange={(val) =>
              updateNavigatorSupportOrg({ business_hours: { time_zone: val } })
            }
            singleRow={true}
            options={Object.entries(
              rubyConstants.AdminHelper.US_TIME_ZONES
            ).map((e) => toOption(e))}
          />
          <div className="hours-grid">
            {days.map((day) => (
              <DayRow
                key={day}
                day={day}
                prefix={prefix}
                currentHours={currentNavigatorSupportOrg.business_hours || {}}
                updateNavigatorSupportOrg={updateNavigatorSupportOrg}
                fromServerErrors={fromServerErrors}
              />
            ))}
          </div>
        </>
      )}
    </>
  )
}

HoursInputs.propTypes = {
  currentNavigatorSupportOrg: PropTypes.object.isRequired,
  updateNavigatorSupportOrg: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
}

DayRow.propTypes = {
  day: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
  currentHours: PropTypes.object.isRequired,
  updateNavigatorSupportOrg: PropTypes.func.isRequired,
}

export default HoursInputs
