import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FilterDrawerWrapper from './FilterDrawerWrapper.jsx'
import './IndexOrderLinks.scss'

const labelFor = (item, dir, shortOrLong) => item?.labels?.[dir]?.[shortOrLong]

const IndexOrderLinks = ({ currentOrder, orderItems, setCurrentOrder }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [currentOrderKey, currentOrderDir] = currentOrder.split(/_(?=[^_]+$)/)
  const currentOrderItem = orderItems.find((x) => x.key === currentOrderKey)

  return (
    <div className="IndexOrderLinks">
      <FilterDrawerWrapper
        isOpen={isOpen}
        onClear={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        onReset={() => setIsOpen(false)}
        title={['Sort by', labelFor(currentOrderItem, currentOrderDir, 'short')]
          .filter(Boolean)
          .join(' ')}
        showInnerButtons={false}
        extraClass="one-nine-five-wide"
      >
        {orderItems.map((item) =>
          item.directions.map((dir) => (
            <button
              key={`${item.key}_${dir}`}
              type="button"
              className={`order-link ${
                currentOrder === `${item.key}_${dir}` ? 'active' : ''
              }`}
              disabled={item.disabled}
              onClick={() => {
                setCurrentOrder(`${item.key}_${dir}`)
                setIsOpen(false)
              }}
            >
              {labelFor(item, dir, 'long')}
            </button>
          ))
        )}
      </FilterDrawerWrapper>
    </div>
  )
}

IndexOrderLinks.propTypes = {
  currentOrder: PropTypes.string.isRequired,
  orderItems: PropTypes.arrayOf(
    PropTypes.shape({
      directions: PropTypes.arrayOf(PropTypes.oneOf(['asc', 'desc']))
        .isRequired,
      disabled: PropTypes.bool,
      labels: PropTypes.objectOf(
        PropTypes.shape({ short: PropTypes.string, long: PropTypes.string })
      ).isRequired,
    })
  ).isRequired,
  setCurrentOrder: PropTypes.func.isRequired,
}

export default IndexOrderLinks
