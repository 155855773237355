//
//      Private functions
//

// hide/show conditional fields
function showHideBlockedNote(checked) {
  $('#clinic_blocked_note_input').toggle(checked)
}
function showHidePrivateAddressFields(checked) {
  $('#clinic_address_private_text_en_input').toggle(checked)
  $('#clinic_address_private_text_es_input').toggle(checked)
  $('.has_many_container.abortion_fields').toggle(checked)
}
function showHideAlternateBookingUrls(checked) {
  $('#clinic_abortion_booking_url_alt_telehealth_en_input').toggle(checked)
  $('#clinic_abortion_booking_url_alt_telehealth_es_input').toggle(checked)
}

function validateShieldLawServiceArea() {
  const locationType = $('#clinic_location_type').val()

  // A selector that matches all `shield_law` checkboxes with any index
  const shieldLawChecked = $(
    '[name^="clinic[telehealth_services_attributes]"][name$="[shield_law]"]:checked'
  )

  if (locationType === 'hybrid' && shieldLawChecked.length === 1) {
    $('.formtastic.clinic').before(
      '<div id="shield-law-error" class="error-state" style="background: #fae6e4; border-radius: 4px; color: #932419; font-weight: bold; margin-bottom: 10px; padding: 10px; list-style: square;">' +
        'You cannot save a hybrid clinic with only one service area and that service area has shield law enabled.' +
        '</div>'
    )
    return false
  }
  return true
}

//      Global functions
window.clinics = {
  initConditionalFields: () => {
    // and toggle on checkbox click or selection change
    // eslint-disable-next-line func-names
    $('#clinic_status').change(function () {
      showHideBlockedNote(this.value.slice(0, 7) === 'Blocked')
    })
    // eslint-disable-next-line func-names
    $('#clinic_address_private').change(function () {
      showHidePrivateAddressFields(this.checked)
    })

    // eslint-disable-next-line func-names
    $('#clinic_abortion_booking_type').change(function () {
      showHideAlternateBookingUrls(this.value === 'both_with_separate_urls')
    })

    // start out hidden/not hidden
    $('#clinic_status').change()
    $('#clinic_address_private').change()
    $('#clinic_abortion_booking_type').change()

    $(document).ready(() => {
      // Attach the validation on form submission
      $('form#edit_clinic').submit((e) => {
        if (!validateShieldLawServiceArea()) {
          e.preventDefault() // Prevent form submission if validation fails
        }
      })
    })
  },

  toggleConditionalSection: (key, value) => {
    const $section = $(`[data-toggle-on-key="${key}"]`)
    if ($section.length > 0) {
      $section.toggleClass('hidden', !(value === true))
    }
  },
}
