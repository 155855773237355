//
//      Private functions
//

//      Global functions
window.supportOrgs = {
  removeFilter: (key, val = null) => {
    const params = new URLSearchParams(window.location.search)
    for (const paramKey of params.keys()) {
      if (paramKey.includes(key)) {
        const paramVals = params.getAll(paramKey)
        params.delete(paramKey)
        if (val) {
          paramVals.forEach((paramVal) => {
            if (paramVal !== val) params.append(paramKey, paramVal)
          })
        }
        break
      }
    }
    window.location.search = params.toString()
  },
}

export default window.supportOrgs
