import React, { useState, useReducer } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { isEqual } from 'lodash'
import rubyConstants from '../../../ruby_constants.js'
import {
  OrgCheckboxInput,
  OrgCreatableSelectInput,
  OrgEmailInput,
  OrgInputGroup,
  OrgPhoneInput,
  OrgRadioInput,
  OrgSelectInput,
  OrgStringInput,
  OrgUrlInput,
} from '../inputs/OrgInputs.jsx'
import CancelEditModalModal from './CancelEditModalModal.jsx'
import navigatorSupportOrgModalStyles from './navigator_support_org_modal_styles.js'
import blackXIcon from '../../../../assets/images/blackX.svg'
import './EditContactMethodModal.scss'

const customStyles = {
  content: {
    ...navigatorSupportOrgModalStyles.content,
    width: '594px',
    overflow: 'visible',
  },
  overlay: navigatorSupportOrgModalStyles.overlay,
}

const blankContactMethod = {
  labels: [],
  languages: [],
  number: '',
  email: '',
  url: '',
  seeker_assistance: false,
  general: false,
  navigator_referral: false,
}

const EditContactMethodModal = ({
  contactMethod = {},
  existingLabels,
  contactSubtypes,
  contactSubtypeText,
  closeModal,
  saveModalData,
  newCM,
  privateSupportOrg,
}) => {
  const startingContactMethod = {
    ...blankContactMethod,
    ...contactMethod,
  }

  const [currentContactMethod, updateCurrentContactMethod] = useState(
    startingContactMethod
  )
  const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false)
  const [showErrors, setShowErrors] = useState(!newCM)
  const [mainPhoneNumber, setMainPhoneNumber] = useState(
    currentContactMethod.number ? currentContactMethod.number.split('x')[0] : ''
  )

  const contactMethodChanged = !isEqual(
    startingContactMethod,
    currentContactMethod
  )

  const [mainNumber, ext] = currentContactMethod.number
    ? currentContactMethod.number.split('x')
    : ['', '']

  const typeToHuman = {
    PhoneNumber: 'Phone Number',
    Email: 'Email',
    Url: 'Link/URL',
  }[contactMethod.type]
  const phoneMethods = [
    {
      value: 'call',
      checked: currentContactMethod.call && !currentContactMethod.sms,
    },
    {
      value: 'text',
      checked: currentContactMethod.sms && !currentContactMethod.call,
    },
    {
      value: 'call or text',
      checked: currentContactMethod.call && currentContactMethod.sms,
    },
  ]
  const modalName = (newCM ? 'Add ' : 'Edit ') + typeToHuman
  const ourErrors = {
    // OrgPhoneInput, orgUrlInput, orgEmailInput handles its own errors, see theirErrors
    labels:
      (currentContactMethod.labels || []).length < 1 && 'Label is required',
    languages:
      (currentContactMethod.languages || []).length < 1 && 'Select a language',
    contactSubtypes:
      !currentContactMethod.seeker_assistance &&
      !currentContactMethod.general &&
      !currentContactMethod.navigator_referral &&
      'Contact type must be selected',
    phoneMethods:
      currentContactMethod.type === 'PhoneNumber' &&
      !currentContactMethod.sms &&
      !currentContactMethod.call &&
      'Contact method must be selected',
  }
  const [theirErrors, setTheirErrors] = useReducer(
    // can have the shape { phone: true, url: false }
    (oldObj, newObj) => ({ ...oldObj, ...newObj }),
    {}
  )

  const areErrors = [
    ...Object.values(ourErrors),
    ...Object.values(theirErrors),
  ].some((x) => x)

  const handleCancelRequest = (e) => {
    if (contactMethodChanged) {
      setCancelModalIsOpen(true)
    } else {
      closeModal(e)
    }
  }

  const checkContactMethod = () => {
    if (areErrors) {
      setShowErrors(true)
    } else {
      saveModalData(currentContactMethod, newCM)
      closeModal()
    }
  }

  const updateCurrentContactMethodWithMainPhone = (newMain) => {
    const num = newMain + (ext ? `x${ext}` : '')

    updateCurrentContactMethod({
      ...currentContactMethod,
      number: num,
    })
    setMainPhoneNumber(newMain)
  }

  return (
    <Modal onRequestClose={handleCancelRequest} style={customStyles} isOpen>
      <form
        className={`EditContactMethodModal-body ${contactMethod.type} ${
          !showErrors ? 'hide-errors' : ''
        }`}
      >
        <div className="header">
          <h2>{modalName}</h2>
          <button
            className="x-button"
            type="button"
            onClick={handleCancelRequest}
            onKeyDown={handleCancelRequest}
          >
            <img src={blackXIcon} alt={`close ${modalName} modal`} />
          </button>
        </div>
        <div className="modal-row top">
          <OrgCreatableSelectInput
            name={null}
            label="Label (e.g. Hotline)"
            value={currentContactMethod.labels || []}
            onChange={(value) =>
              updateCurrentContactMethod({
                ...currentContactMethod,
                labels: value ? [value] : [],
              })
            }
            isMulti={false}
            options={existingLabels || []}
            unvalid={ourErrors.labels && showErrors}
            errors={showErrors && ourErrors.labels}
            isClearable
          />
          {contactMethod.type === 'PhoneNumber' && (
            <>
              <OrgPhoneInput
                label="Phone Number"
                value={mainPhoneNumber}
                onChange={updateCurrentContactMethodWithMainPhone}
                required={true}
                setErrors={(x) => setTheirErrors({ phone: x })}
              />
              <OrgStringInput
                name={null}
                label="Ext. (Optional)"
                value={ext}
                onChange={(value) => {
                  const newExt = value.replace(/\D/g, '')
                  const num = mainNumber + (newExt ? `x${newExt}` : '')
                  updateCurrentContactMethod({
                    ...currentContactMethod,
                    number: num,
                  })
                }}
              />
            </>
          )}
          {contactMethod.type === 'Email' && (
            <OrgEmailInput
              name={null}
              label="Email Address"
              value={currentContactMethod.email}
              onChange={(value) =>
                updateCurrentContactMethod({
                  ...currentContactMethod,
                  email: value,
                })
              }
              required={true}
              setErrors={(x) => setTheirErrors({ email: x })}
            />
          )}
          {contactMethod.type === 'Url' && (
            <OrgUrlInput
              name={null}
              label="URL"
              value={currentContactMethod.url}
              onChange={(value) =>
                updateCurrentContactMethod({
                  ...currentContactMethod,
                  url: value,
                })
              }
              required={true}
              setErrors={(x) => setTheirErrors({ url: x })}
            />
          )}
        </div>
        <div className="modal-row">
          <OrgSelectInput
            name={null}
            label="Languages Supported"
            value={currentContactMethod.languages || []}
            onChange={(value) =>
              updateCurrentContactMethod({
                ...currentContactMethod,
                languages: value,
              })
            }
            options={rubyConstants.LanguageValidator.LANGUAGES}
            isMulti={true}
            unvalid={showErrors && ourErrors.languages}
            errors={showErrors && ourErrors.languages}
          />
        </div>
        <div className="modal-row bottom">
          <OrgInputGroup
            label="Contact Type"
            required={showErrors && ourErrors.contactSubtypes}
            errors={showErrors && ourErrors.contactSubtypes}
          >
            {contactSubtypes.map((subtype) => (
              <OrgCheckboxInput
                key={subtype}
                checked={currentContactMethod[subtype]}
                label={contactSubtypeText(subtype)}
                onChange={(checked) =>
                  updateCurrentContactMethod({
                    ...currentContactMethod,
                    [subtype]: checked,
                  })
                }
                isInfoPublic={subtype !== 'navigator_referral'}
                privateSupportOrg={privateSupportOrg}
                attribute={subtype}
                required={showErrors && ourErrors.contactSubtypes}
              />
            ))}
          </OrgInputGroup>
          {contactMethod.type === 'PhoneNumber' && (
            <OrgInputGroup
              label="Contact Method"
              errors={showErrors && ourErrors.phoneMethods}
            >
              {phoneMethods.map((method) => (
                <OrgRadioInput
                  key={method.value}
                  checked={!!method.checked}
                  value={method.value}
                  onChange={(e) => {
                    const { value } = e.target
                    updateCurrentContactMethod({
                      ...currentContactMethod,
                      call: value.indexOf('call') > -1,
                      sms: value.indexOf('text') > -1,
                    })
                  }}
                  label={method.value}
                  name="phone_method_radios"
                  required={true}
                />
              ))}
            </OrgInputGroup>
          )}
        </div>
        <div className="modal-buttons">
          <button
            type="button"
            className="white-button"
            onClick={handleCancelRequest}
          >
            Cancel
          </button>
          <button
            type="button"
            className="blue-button"
            disabled={!contactMethodChanged || areErrors}
            onClick={checkContactMethod}
          >
            {newCM ? `Add ${typeToHuman}` : 'Update'}
          </button>
        </div>
      </form>
      {cancelModalIsOpen && (
        <CancelEditModalModal
          confirmModal={closeModal}
          closeModal={() => setCancelModalIsOpen(false)}
        />
      )}
    </Modal>
  )
}

EditContactMethodModal.propTypes = {
  contactMethod: PropTypes.shape({
    type: PropTypes.oneOf(['Email', 'Url', 'PhoneNumber']),
  }).isRequired,
  existingLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  contactSubtypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  contactSubtypeText: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  saveModalData: PropTypes.func.isRequired,
  newCM: PropTypes.bool.isRequired,
}

export default EditContactMethodModal
