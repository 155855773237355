/*
 * An <input> widget for Clinic.abortion_procedure_gal_days,
 * or any attribute that stores days and should be input as weeks + days.
 *
 * Chi: This component treats 0 weeks as verified unknown
 *      so currently the componet does not deal with limits of 1 day to 6 days.
 *      This unsupported range is unlikely to be valid input anyways.
 */

import React, { useState } from 'react'
import './WeekAndDayDropdown.scss'
import _ from 'lodash'
import PropTypes from 'prop-types'

const WeekAndDayDropdown = ({
  name, // e.g. 'clinic_submission[clinic_source][abortion_procedure_gal_days]'
  value, // previous value in days OR a mode; an empty string represents null
  text_when_null, // option will not be included in select menu if this is blank
  text_when_zero, // option will not be included in select menu if this is blank
  gal_mode_input_name,
  galModeDays,
  disabled,
  zeroOptionFirst,
}) => {
  let initialDays = ''
  let initialWeeks = ''
  let totalDays = ''
  let modeInput

  if (value !== '' && value != null && Number.isNaN(parseInt(value))) {
    // is a mode, e.g. VIABILITY
    initialWeeks = value
  } else if (value !== '' && value !== null) {
    initialDays = parseInt(value) % 7
    initialWeeks = Math.floor(parseInt(value) / 7)
  }

  const [days, setDays] = useState(initialDays)
  const [weeksOrMode, setWeeks] = useState(initialWeeks)

  const daysChanged = (event) => setDays(parseInt(event.target.value))
  const weeksChanged = (event) => {
    if (event.target.value === '') {
      setWeeks('')
      setDays('')
    } else if (event.target.value === '0') {
      setWeeks(0)
      setDays(0)
    } else if (Number.isNaN(parseInt(event.target.value))) {
      // is a mode e.g. VIABILITY
      setWeeks(event.target.value)
      setDays('')
    } else {
      setWeeks(parseInt(event.target.value))
    }
  }

  const isShowingDaysDropdown =
    weeksOrMode && !Number.isNaN(parseInt(weeksOrMode))

  if (weeksOrMode === '' && days === '') {
    // user has selected text_when_null
    totalDays = ''
  } else if (galModeDays && weeksOrMode in galModeDays) {
    // user has selected one of the modes
    totalDays = galModeDays[weeksOrMode]
    if (totalDays === null) totalDays = ''
  } else {
    totalDays = days + weeksOrMode * 7
  }

  const nullOption = text_when_null ? (
    <option key="null" value="">
      {text_when_null}
    </option>
  ) : (
    ''
  )
  const zeroOption = text_when_zero ? (
    <option key="zero" value={0}>
      {text_when_zero}
    </option>
  ) : (
    ''
  )

  const modeOptions = []
  if (galModeDays) {
    Object.entries(galModeDays).forEach((entry) => {
      const [mode] = entry
      modeOptions.splice(
        1,
        0,
        <option key={mode} value={mode}>
          {mode.replace(/_/g, ' ')}
        </option>
      )
    })

    let modeValue
    if (weeksOrMode in galModeDays) {
      // user has selected one of the modes
      modeValue = weeksOrMode
    } else if (weeksOrMode === '') {
      modeValue = ''
    } else if (typeof weeksOrMode === 'number') {
      modeValue = 'DAYS'
    } else {
      console.error("Can't determine mode for weeksOrMode value: ", weeksOrMode)
    }
    modeInput = (
      <input type="hidden" name={gal_mode_input_name} value={modeValue} />
    )
  }

  return (
    <div className="WeekAndDayDropdown">
      <div className="container">
        <select
          data-testid="week-dropdown"
          onChange={weeksChanged}
          value={weeksOrMode}
          aria-label="Gestational Age Limit (weeks)"
          disabled={disabled}
        >
          {zeroOptionFirst ? zeroOption : ''}
          {nullOption}
          {modeOptions}
          {zeroOptionFirst ? '' : zeroOption}
          {_.range(1, 101).map((n) => (
            <option key={n} value={n}>
              {n} weeks
            </option>
          ))}
        </select>
      </div>

      {isShowingDaysDropdown && (
        <div className="container">
          <select
            data-testid="day-dropdown"
            onChange={daysChanged}
            value={days}
            aria-label="Gestational Age Limit (days)"
            disabled={disabled}
          >
            {_.range(7).map((n) => (
              <option key={n} value={n}>
                {n} days
              </option>
            ))}
          </select>
        </div>
      )}

      <input type="hidden" name={name} value={totalDays} />
      {modeInput}
    </div>
  )
}

WeekAndDayDropdown.defaultProps = {
  disabled: false,
  gal_mode_input_name: null,
  galModeDays: undefined,
  text_when_null: undefined,
  text_when_zero: undefined,
  value: '',
  zeroOptionFirst: false,
}

WeekAndDayDropdown.propTypes = {
  disabled: PropTypes.bool,
  gal_mode_input_name: PropTypes.string, // form input name, null if this does not accept a mode
  galModeDays: PropTypes.object, // object matching modes to days
  name: PropTypes.string.isRequired, // Rails form input name, e.g. clinic[known_offerings][birth_control]
  text_when_null: PropTypes.string, // null acceptable, meaning don't include this option
  text_when_zero: PropTypes.string, // null acceptable, meaning don't include this option
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zeroOptionFirst: PropTypes.bool,
}

export default WeekAndDayDropdown
