import enYaml from '../../../config/locales/en.yml'

const anywhere_except_pr_states =
  'AKALARAZCACOCTDCDEFLGAHIIAIDILINKSKYLAMAMDMEMIMNMOMSMTNCNDNENHNJNMNVNYOHOKORPARISCSDTNTXUTVAVTWAWIWVWY'

export const anywhere_except_pr_text = enYaml.en.geo_rules.anywhere_except_pr

export const isAnywhereExceptPR = (arr) => {
  const arrOfStates = typeof arr[0] === 'object' ? arr.map((e) => e.state) : arr
  return arrOfStates.sort().join('') === anywhere_except_pr_states
}
