$.fn.reorderable = function (opts) {
  // This helper fixes the table row width collapsing when being dragged
  function reorderableTableHelper(e, ui) {
    ui.children().each(function () {
      const $cell = $(this)

      $cell.width($cell.width())
    })

    return ui
  }

  // This helper sets the table row placeholder height to the height of the row being moved
  function reorderableTableStart(e, ui) {
    ui.placeholder.height(ui.helper.outerHeight())

    return ui
  }

  function reorderableTableStop(e, ui) {
    const $row = ui.item;
      const $rows = $row.parent().children('tr');
      const $table = $row.closest('table');
      const $handle = $row.find('.reorder-handle');
      const url = $handle.data('reorder-url');
      const index = function (i) {
        return $rows.index(i) + 1
      }

    $table.find('tbody tr').each(function (index) {
      const $row = $(this);
        let newClass = ''

      $row.removeClass('odd').removeClass('even')

      if ((index + 1) % 2 == 0) {
        newClass = 'even'
      } else {
        newClass = 'odd'
      }

      $row.addClass(newClass)
    })

    $rows.each(function () {
      $(this)
        .find('.position')
        .text(index($(this)))
    })
    const top_id = $row.prev().find('.reorder-handle').data('reorderId')
    const bottom_id = $row.next().find('.reorder-handle').data('reorderId')

    $.post(url, {
      position: index($row),
      top_id,
      bottom_id,
    })
  }

  return this.each(function () {
    var opts = $.extend(
      {
        items: 'tbody tr',
        handle: '.reorder-handle',
        axis: 'y',
        helper: reorderableTableHelper,
        start: reorderableTableStart,
        stop: reorderableTableStop,
      },
      opts || {}
    )

    $(this).sortable(opts)
  })
}

$(() => {
  $('.aa-reorderable').reorderable()
})
