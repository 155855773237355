const containsErrors = (section) =>
  [...section.querySelectorAll('p.inline-errors, .errors')].some(
    (elem) => elem.innerHTML
  )

const expandSectionWithErrors = (section) => {
  if (containsErrors(section)) {
    section.classList.remove('collapsed')
  }
}

const findTabWithErrors = () => {
  const tabs = document.querySelectorAll('.tabs.ui-tabs')
  Array.from(tabs).forEach((tabContainer) => {
    Array.from(tabContainer.querySelectorAll('.ui-widget-content')).forEach(
      (tabContent) => {
        const tabButton = tabContainer.querySelector(
          `.ui-tabs-anchor[href='#${tabContent.id}']`
        )
        if (containsErrors(tabContent)) {
          tabButton.click()
        }
      }
    )
  })
}

window.expandadoodle = {
  focusTabWithErrors: () => {
    setTimeout(() => findTabWithErrors(), 300)
  },
  init: () => {
    document.querySelectorAll('.expandadoodle').forEach((section) => {
      const handle = section.querySelector('legend span, h3')
      if (!handle) return

      handle.classList.add('expandahandle')

      // Collapse this section by default unless we've been told
      // to expand on init, or if this href has an anchor tag,
      // which happens when the user clicks on a link to a specific
      // sub-section, or if the section contains errors.
      const anc = section.querySelector(`a[href="${window.location.hash}"]`)
      const collapseOnInit =
        !anc &&
        !section.classList.contains('expandadoodle-expanded-on-init') &&
        !containsErrors(section)
      if (collapseOnInit) section.classList.add('collapsed')

      // Add the click handler to the handle
      handle.addEventListener('click', () =>
        section.classList.toggle('collapsed')
      )

      setTimeout(() => expandSectionWithErrors(section), 300)
    })
    setTimeout(() => findTabWithErrors(), 300)
  },

  initAll: () => {
    document
      .querySelectorAll('form fieldset, form div.panel')
      .forEach((section) => {
        const handle = section.querySelector('legend span, h3')

        if (!handle) return

        // Add a class for CSS styling
        section.classList.add('expandadoodle')
        handle.classList.add('expandahandle')

        // Check if the section contains any errors.
        const thisContainsErrors = section.querySelector('p.inline-errors')

        // Collapse this section by default unless we've been told
        // to expand on init, or if this href has an anchor tag,
        // which happens when the user clicks on a link to a specific
        // sub-section, or if the section contains errors.
        const anc = section.querySelector(`a[href="${window.location.hash}"]`)
        const collapseOnInit =
          !anc &&
          !section.classList.contains('expandadoodle-expanded-on-init') &&
          !thisContainsErrors
        if (collapseOnInit) section.classList.add('collapsed')

        // Add the click handler to the handle
        handle.addEventListener('click', () =>
          section.classList.toggle('collapsed')
        )
      })
  },
}
