// DO NOT EDIT - automatically generated by UpdateRubyConstantsJs
// Import this to give React components access to constants in Rails models
// import rubyConstants from '../ruby_constants.js'

/* eslint-disable comma-dangle */

export default {
  'AdminHelper': {
    'US_TIME_ZONES': {
      'Hawaii': 'Pacific/Honolulu',
      'Alaska': 'America/Juneau',
      'Pacific Time (US & Canada)': 'America/Los_Angeles',
      'Arizona': 'America/Phoenix',
      'Mountain Time (US & Canada)': 'America/Denver',
      'Central Time (US & Canada)': 'America/Chicago',
      'Eastern Time (US & Canada)': 'America/New_York',
      'Puerto Rico': 'America/Puerto_Rico'
    }
  },
  'AppointmentAvailability': {
    'KEYS': [
      'abortion_procedure',
      'abortion_pill',
      'counseling',
      'telehealth_pill'
    ],
    'VALUES': {
      '': 'Unknown',
      '0': 'Less than 1 week',
      '1': '1 week',
      '2': '2 weeks',
      '3': '3 weeks',
      '4': '4 weeks or more'
    }
  },
  'Clinic': {
    'ALLOWABLE_ABORTION_BOOKING_TYPES': [
      'virtual_only',
      'in_person_only',
      'both',
      'both_with_separate_urls',
      'unknown'
    ],
    'GAL_COLUMN_TO_OFFERING': {
      'abortion_pill_gal_days': 'medication_abortion_pill',
      'abortion_procedure_gal_days': 'surgical_abortion',
      'abortion_telehealth_gal_days': 'abortion_telemedicine'
    },
    'GAL_MODE_DAYS': {
      'VERIFIED_UNKNOWN': 0,
      'FETAL_TONE': 48,
      'VIABILITY': 202
    }
  },
  'ContactMethod': {
    'CONTACT_SUBTYPES': [
      'seeker_assistance',
      'general',
      'navigator_referral'
    ]
  },
  'GeographicArea': {
    'COVERAGE_ALL': 'All',
    'COVERAGE_CITIES': 'City/Cities Only',
    'COVERAGE_PROVIDER': 'Provider Partner(s) Only',
    'COVERAGE_STATE_REGIONS': 'State Region(s) Only',
    'COVERAGE_TYPES': [
      'All',
      'Provider Partner(s) Only',
      'State Region(s) Only',
      'City/Cities Only'
    ],
    'NATIONAL_COVERAGE_TYPES': [
      'All',
      'Provider Partner(s) Only'
    ]
  },
  'LanguageValidator': {
    'LANGUAGES': [
      'Abkhazian',
      'Afar',
      'Afrikaans',
      'Akan',
      'Albanian',
      'American Sign Language (ASL)',
      'Amharic',
      'Arabic',
      'Aragonese',
      'Armenian',
      'Assamese',
      'Avaric',
      'Avestan',
      'Aymara',
      'Azerbaijani',
      'Bambara',
      'Bashkir',
      'Basque',
      'Belarusian',
      'Bengali',
      'Bislama',
      'Bosnian',
      'Breton',
      'Bulgarian',
      'Burmese',
      'Cantonese Chinese',
      'Catalan',
      'Chamorro',
      'Chechen',
      'Chichewa',
      'Chinese',
      'Church Slavic',
      'Chuvash',
      'Cornish',
      'Corsican',
      'Cree',
      'Croatian',
      'Czech',
      'Danish',
      'Divehi',
      'Dutch',
      'Dzongkha',
      'English',
      'Esperanto',
      'Estonian',
      'Ewe',
      'Faroese',
      'Fijian',
      'Finnish',
      'French',
      'Fulah',
      'Gaelic',
      'Galician',
      'Ganda',
      'Georgian',
      'German',
      'Greek',
      'Kalaallisut',
      'Guarani',
      'Gujarati',
      'Haitian Creole',
      'Hausa',
      'Hebrew',
      'Herero',
      'Hindi',
      'Hiri Motu',
      'Hmong',
      'Hungarian',
      'Icelandic',
      'Ido',
      'Igbo',
      'Ilocano',
      'Indonesian',
      'Interlingua',
      'Interlingue',
      'Inuktitut',
      'Inupiaq',
      'Irish',
      'Italian',
      'Japanese',
      'Javanese',
      'Kannada',
      'Kanuri',
      'Kashmiri',
      'Kazakh',
      'Central Khmer',
      'Kikuyu',
      'Kinyarwanda',
      'Kirghiz',
      'Komi',
      'Kongo',
      'Korean',
      'Kuanyama',
      'Kurdish',
      'Lao',
      'Latin',
      'Latvian',
      'Limburgan',
      'Lingala',
      'Lithuanian',
      'Luba-Katanga',
      'Luxembourgish',
      'Macedonian',
      'Malagasy',
      'Malay',
      'Malayalam',
      'Maltese',
      'Mandarin Chinese',
      'Manx',
      'Maori',
      'Marathi',
      'Marshallese',
      'Mongolian',
      'Nauru',
      'Navajo',
      'North Ndebele',
      'South Ndebele',
      'Ndonga',
      'Nepali',
      'Norwegian',
      'Norwegian Bokmål',
      'Norwegian Nynorsk',
      'Sichuan',
      'Occitan',
      'Ojibwa',
      'Oriya',
      'Oromo',
      'Ossetian',
      'Pali',
      'Pashto',
      'Persian',
      'Polish',
      'Portuguese',
      'Punjabi',
      'Quechua',
      'Romanian',
      'Romansh',
      'Rundi',
      'Russian',
      'Northern Sami',
      'Samoan',
      'Sango',
      'Sanskrit',
      'Sardinian',
      'Serbian',
      'Shona',
      'Sindhi',
      'Sinhala',
      'Slovak',
      'Slovenian',
      'Somali',
      'Southern Sotho',
      'Spanish',
      'Sundanese',
      'Swahili',
      'Swati',
      'Swedish',
      'Tagalog',
      'Taiwanese Hokkien',
      'Tahitian',
      'Tajik',
      'Tamil',
      'Tatar',
      'Tegali',
      'Telugu',
      'Thai',
      'Tibetan',
      'Tigrinya',
      'Tonga',
      'Tsonga',
      'Tswana',
      'Turkish',
      'Turkmen',
      'Twi',
      'Uighur',
      'Ukrainian',
      'Urdu',
      'Uzbek',
      'Venda',
      'Vietnamese',
      'Volapük',
      'Walloon',
      'Waray',
      'Welsh',
      'Western Frisian',
      'Western Persian',
      'Wolof',
      'Xhosa',
      'Yiddish',
      'Yoruba',
      'Zhuang',
      'Zulu'
    ]
  },
  'LinkSharing': {
    'EXPIRATION': '172800'
  },
  'NavigatorClinic': {
    'ABORTION_TYPE_FILTER': {
      'abortion_pill': {
        'label': 'Pill (in-person)',
        'offerings': [
          'medication_abortion_pill'
        ]
      },
      'telehealth_pill': {
        'label': 'Pill (telehealth)',
        'offerings': [
          'abortion_telemedicine'
        ]
      },
      'pill_future_use': {
        'label': 'Pill (for future use)',
        'offerings': [
          'abortion_pills_for_future_use_telehealth',
          'abortion_pills_for_future_use_in_person'
        ]
      },
      'abortion_procedure': {
        'label': 'Procedure',
        'offerings': [
          'surgical_abortion'
        ]
      }
    },
    'AFFILIATES_THAT_MATTER': [
      'ACN',
      'NAF',
      'Planned Parenthood'
    ]
  },
  'Price': {
    'GAL_MODE_DAYS': {
      'UNDEFINED': null,
      'FETAL_TONE': 48,
      'VIABILITY': 202
    }
  },
  'Rule': {
    'RULE_TYPES': [
      'anywhere_to_anywhere',
      'anywhere_to_states',
      'anywhere_to_state_regions',
      'anywhere_to_providers',
      'states_to_anywhere',
      'states_to_states',
      'states_to_state_regions',
      'states_to_providers',
      'state_regions_to_anywhere',
      'state_regions_to_states',
      'state_regions_to_state_regions',
      'state_regions_to_providers'
    ],
    'RULE_TYPE_NO_DUBS': [
      'anywhere_to_anywhere',
      'anywhere_to_states',
      'anywhere_to_state_regions',
      'anywhere_to_providers',
      'states_to_anywhere',
      'state_regions_to_anywhere'
    ],
    'RULE_TYPE_TO_AREA_COVERAGE_TYPE': {
      'anywhere': 'All',
      'states': 'All',
      'state_regions': 'State Region(s) Only',
      'providers': 'Provider Partner(s) Only'
    }
  },
  'State': {
    'NATIONAL': 'National',
    'NATIONAL_DISPLAY_NAME_ADMIN': 'U.S. and Puerto Rico',
    'NATIONAL_DISPLAY_NAME_PUBLIC': 'National'
  },
  'SupportOrg': {
    'ABORTION_SEEKER_CONTACT_PREFERENCES': {
      'not_allowed': 'Abortion seekers CANNOT reach out to us directly',
      'allowed': 'Abortion seekers CAN reach out to us directly',
      'sometimes_allowed': 'Abortion seekers can reach out directly only in certain cases (e.g., If an eligible seeker has an appointment with a provider that does not partner with your organization)'
    },
    'ALLOWABLE_ORG_TYPE': [
      'Fund & PSO',
      'Fund',
      'PSO'
    ],
    'STATUS_VALUES': {
      'active': 'Active',
      'paused_ooto': 'Out of office',
      'paused_funding': 'Out of funding',
      'paused_prefer_not_to_say': 'Prefer not to say',
      'paused_other': 'Other',
      'blocked': 'Blocked',
      'pending': 'Pending'
    }
  }
};
