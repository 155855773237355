import React, { useState, useEffect } from 'react'
import backToTopArrow from '../../../assets/images/back_to_top_arrow.svg'
import useScrollDirection from '../../utilities/use_scroll_direction.js'
import './BackToTopButton.scss'

const BackToTopButton = ({ cardsContainerRef, mapRef }) => {
  const scrollDirection = useScrollDirection(cardsContainerRef)
  const [showBackToTopButton, setShowBackToTopButton] = useState(false)

  useEffect(() => {
    if (scrollDirection === 'up') setShowBackToTopButton(true)
    else setShowBackToTopButton(false)
  }, [scrollDirection])

  const scrollToTop = () => {
    setShowBackToTopButton(false)
    // Scroll to the top of the cards container
    if (cardsContainerRef.current) {
      cardsContainerRef.current.scrollTo({ top: 0, left: 0 })
    }

    // Additionally scroll the page to the top of the map
    if (mapRef.current) {
      const element = document.querySelector('#NavigatorClinicIndex .map')
      const navbar = document.querySelector('#root-sticky-top-navlinks')

      // distance of top of element to viewport + how far we've scrolled
      const y =
        element.getBoundingClientRect().top +
        window.scrollY -
        navbar.getBoundingClientRect().bottom -
        10

      window.scroll({
        top: y,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div className={`BackToTopButton ${showBackToTopButton ? 'show' : 'hide'}`}>
      <button type="button" onClick={scrollToTop}>
        <img src={backToTopArrow} alt="up arrow" />
        Back to Top
      </button>
    </div>
  )
}

export default BackToTopButton
