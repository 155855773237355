/* eslint-disable no-undef, prefer-template, prefer-arrow-callback */
import _ from 'lodash'

const grabVals = ($inputs) => {
  const vals = []
  // eslint-disable-next-line func-names
  $inputs.each(function () {
    const $this = $(this)
    vals.push(
      ['checkbox', 'radio'].indexOf($this.prop('type')) > -1
        ? $this.prop('checked')
        : $this.val()
    )
  })
  return vals
}

const initDrawer = (filter) => {
  const $inputs = $('#' + filter + ' .top-o-the-dropdown').find('select, input')
  const initialVals = grabVals($inputs)
  $inputs.on('change', () => {
    const nowVals = grabVals($inputs)
    const equal = _.isEqual(initialVals, nowVals)
    $('#' + filter + ' .submit-filter').prop('disabled', equal)
  })
  // eslint-disable-next-line func-names
  $('#' + filter + '-input').on('change', function () {
    // resets the inputs when closing
    if ($(this).prop('checked')) return
    // eslint-disable-next-line func-names
    $inputs.each(function (index) {
      const $this = $(this)
      if (['checkbox', 'radio'].indexOf($this.prop('type')) > -1) {
        $this.prop('checked', initialVals[index]).trigger('change')
      } else {
        // trigger change for chosen, others will be chill
        $this.val(initialVals[index]).trigger('chosen:updated')
      }
    })
  })
}

window.drawer_filter = {
  initDrawer,
}
