export default {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    padding: '24px 32px',
    maxHeight: '80vh',
    overflowY: 'overlay',
    zIndex: 6001, // $z_navigator_support_org_modal_content - above most things, but below Tooltips (ariakit-tooltip)
  },
  overlay: {
    backgroundColor: '#69696980',
    zIndex: 6000, // $z_navigator_support_org_modal_overlay - above most things, but below Tooltips (ariakit-tooltip)
  },
}
