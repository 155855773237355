window.user_orgs = {
  organizationTypeHasChanged(value, orgOptions) {
    const orgSelect = $('body.admin_user_orgs select#user_org_organization_id')

    // clear existing options
    orgSelect.children().remove()

    // get new options
    // add empty option at beginning so that we start out with nothing selected
    const optionArr = [[]].concat(orgOptions[value])

    optionArr.forEach((orgOption) => {
      orgSelect.append($('<option>').val(orgOption[1]).text(orgOption[0]))
    })
    orgSelect.trigger('chosen:updated')

    // uncheck &/or disable primary point of contact based on type
    const $primaryInput = $('input.primary_point_of_contact')
    if (value === 'ProviderNetwork') {
      $primaryInput.prop('disabled', false)
    } else {
      $primaryInput.prop('disabled', true).prop('checked', false)
    }
  },
}
