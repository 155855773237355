export const camelToSnake = (str) =>
  str.replace(/(?<!^)[A-Z]/g, '_$&').toLowerCase()

export const humanizeString = (str, format = 'titlecase') =>
  str
    // Removes _id from the end of the string
    .replace(/_id$/, '')
    // Removes _ids from the end of the string, but pluralizes the string
    .replace(/_ids$/, 's')
    // Replaces underscores with spaces
    .replace(/_/g, ' ')
    // Apply format as per user's choice
    .replace(/(?:^|\s)\S/g, (a) => {
      switch (format) {
        case 'capitalize':
          return str.indexOf(a) === 0 ? a.toUpperCase() : a.toLowerCase()
        case 'titlecase':
          return a.toUpperCase()
        case 'uppercase':
          return a.toUpperCase()
        case 'lowercase':
          return a.toLowerCase()
        default:
          return a
      }
    })
