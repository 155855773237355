import React from 'react'
import PropTypes from 'prop-types'
import './IndexScopeLinks.scss'

const IndexScopeLinks = ({
  scopes,
  unscopedLabel,
  handleNewScope,
  currentScope,
  filteredIds,
}) => {
  const getCount = (scope) => {
    if (!scope || !scopes[scope]) return filteredIds.length
    return scopes[scope].ids.filter((id) => filteredIds.includes(id)).length
  }

  return (
    <div className="IndexScopeLinks">
      <ul className="scope-default-group">
        <li className={`scope ${scopes[currentScope] ? '' : 'selected'}`}>
          <button
            type="button"
            className="IndexScopeLinks-button"
            onClick={() => handleNewScope('')}
          >
            {unscopedLabel}
            <span className="count">({filteredIds.length})</span>
          </button>
        </li>
        {Object.entries(scopes).map(([scope, { label }]) => (
          <li
            key={scope}
            className={`scope ${scope} ${
              currentScope === scope ? 'selected' : ''
            }`}
          >
            <button
              type="button"
              className="IndexScopeLinks-button"
              onClick={() => handleNewScope(scope)}
            >
              {label}
              <span className="count">({getCount(scope)})</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export const scopesPropType = PropTypes.objectOf(
  PropTypes.shape({
    label: PropTypes.string,
    ids: PropTypes.arrayOf(PropTypes.number),
  })
)

IndexScopeLinks.propTypes = {
  scopes: scopesPropType.isRequired,
  unscopedLabel: PropTypes.string.isRequired,
  handleNewScope: PropTypes.func.isRequired,
  currentScope: PropTypes.string.isRequired,
  filteredIds: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default IndexScopeLinks
