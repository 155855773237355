import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import DraggableContacts from './DraggableContacts.jsx'
import { deepMerge } from '../../../utilities/compare_and_merge.js'
import './ContactType.scss'

// UncontrolledContactType for standalone use
export const UncontrolledContactType = ({
  contactMethods,
  existingLabels,
  prefix,
  type,
}) => {
  const [currentCMs, updateCurrentCMs] = useReducer(
    (oldValues, newValues) => deepMerge(oldValues, newValues),
    JSON.parse(JSON.stringify(contactMethods)) // start with deep copy
  )

  return (
    <div className="UncontrolledContactType">
      <ContactType
        contactMethods={currentCMs}
        existingLabels={existingLabels}
        onChange={updateCurrentCMs}
        prefix={prefix}
        privateSupportOrg={false}
        type={type}
      />
    </div>
  )
}

const ContactType = ({
  type,
  prefix,
  contactMethods = {},
  onChange,
  existingLabels = [],
  privateSupportOrg,
}) => (
  <div className="ContactType">
    <div className="contact-type-title">
      {type === 'urls'
        ? 'Links/URLs'
        : type
            .split('_')
            .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
            .join(' ')}
      <span>({Object.keys(contactMethods).length})</span>
    </div>
    <DraggableContacts
      prefix={`${prefix}[${type}_attributes]`}
      positionColumn="position"
      contactMethods={contactMethods}
      onChange={onChange}
      existingLabels={existingLabels}
      addNewCmButtons={[type]}
      privateSupportOrg={privateSupportOrg}
    />
  </div>
)

ContactType.defaultProps = {
  prefix: '',
}

ContactType.propTypes = {
  contactMethods: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  prefix: PropTypes.string,
  type: PropTypes.oneOf(['phone_numbers', 'emails', 'urls']).isRequired,
  privateSupportOrg: PropTypes.bool.isRequired,
}

export default ContactType
