window.sorting_table = {
  // Taken from https://stackoverflow.com/questions/14267781/sorting-html-table-with-javascript/49041392#49041392
  // with small adjustments to allow sorting on custom_key instead of cell contents & adding sorted-desc class to th

  activateSortingTable: () => {
    const getCellValue = (tr, idx) =>
      tr.children[idx].dataset.sortingTableKey ||
      tr.children[idx].innerText ||
      tr.children[idx].textContent

    const comparer = (idx, asc) => (a, b) =>
      ((v1, v2) =>
        // suppress eslint warning: Unexpected use of 'isNaN'. Use Number.isNaN
        // In this case, isNaN works and Number.isNaN does not.
        // I'm going with "if it ain't broke don't fix it" and leaving isNan.
        // eslint-disable-next-line no-restricted-globals
        v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2)
          ? v1 - v2
          : v1.toString().localeCompare(v2))(
        getCellValue(asc ? a : b, idx),
        getCellValue(asc ? b : a, idx)
      )

    document.querySelectorAll('th.sortme').forEach((th) =>
      th.addEventListener('click', () => {
        const tableBody = th.closest('table').querySelector('tbody')
        Array.from(tableBody.querySelectorAll('tr'))
          .sort(
            comparer(
              Array.from(th.parentNode.children).indexOf(th),
              (this.asc = !this.asc)
            )
          )
          .forEach((tr) => tableBody.appendChild(tr))
        th.classList.toggle('sorted-desc', !this.asc)
      })
    )
  },
}
