import React from 'react'
import PropTypes from 'prop-types'
import AppointmentAvailabilityPills from './AppointmentAvailabilityPills.jsx'
import './NavigatorClinicCard.scss'
import {
  ExternalLink,
  PillLimitColumn,
  ProcedureLimitColumn,
} from './navigator_clinic_snippets.jsx'

const NavigatorClinicCard = React.forwardRef(
  (
    {
      clinic,
      cssClasses,
      appointment_availability_filter,
      isSelected,
      onClick,
      oneBasedIndex,
      onMouseEnter,
      onMouseLeave,
      setSelectedId,
    },
    ref
  ) => {
    const driveAndDrivetime = (
      <div className="distance miles">
        <span>
          {[clinic.distance, clinic.driveTime]
            .filter((x) => x)
            .join(' \u2022 ')}
        </span>{' '}
      </div>
    )

    return (
      <div
        ref={ref}
        className={`NavigatorClinicCard ${cssClasses}`}
        data-testid={`NavigatorClinicCard-${clinic.id}`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      >
        <div className="header">
          <div className="index-bullet">{oneBasedIndex}</div>
          <div className="formatted-name" data-testid="card-clinic-name">
            {clinic.formatted_name}
          </div>
        </div>
        <div className="location">
          {clinic.full_address_with_private_address_or_virtual_only}
        </div>
        <div className="distance">{driveAndDrivetime}</div>

        <div className="card-grid">
          <ExternalLink
            href={`tel:${clinic.formatted_phone}`}
            text={clinic.formatted_phone}
            withIcon
          />

          <ExternalLink href={clinic.url} withIcon />

          <div className="main-info gal-container">
            <div className="info-header">Gestational Age Limit</div>
            {clinic.pill_limit_json && (
              <div>
                <div className="gal-header">Pill Limit</div>
                <PillLimitColumn json={clinic.pill_limit_json} />
              </div>
            )}
            {clinic.procedure_limit_json && (
              <div className="card-procedure-limit">
                <div className="gal-header">Procedure Limit</div>
                <ProcedureLimitColumn json={clinic.procedure_limit_json} />
              </div>
            )}
          </div>
          <div className="main-info appointment-container">
            <div className="info-header">Appointment Availability</div>
            <AppointmentAvailabilityPills
              clinic={clinic}
              appointment_availability_filter={appointment_availability_filter}
              tooltipDisabled={true}
            />
          </div>

          <div className="send-to-phone-check">
            <input
              type="checkbox"
              id={`card-slct-chk-${clinic.id}`}
              checked={isSelected}
              onChange={(e) => setSelectedId(clinic.id, e.target.checked)}
              onClick={(e) => e.stopPropagation()} // don't call the passed in onClick function when we toggle the checkbox
            />
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <label
              htmlFor={`card-slct-chk-${clinic.id}`}
              onClick={(e) => e.stopPropagation()} // don't call the passed in onClick function when we toggle the checkbox
            >
              Send to Phone
            </label>
          </div>

          <div>
            <a
              className="provider-profile"
              href={`/navigator_clinics/${clinic.id}`}
            >
              Go to Provider Profile
            </a>
          </div>
        </div>
      </div>
    )
  }
)

NavigatorClinicCard.propTypes = {
  clinic: PropTypes.shape({
    formatted_name: PropTypes.string.isRequired,
    full_address_with_private_address_or_virtual_only: PropTypes.string,
    distance: PropTypes.string,
    formatted_phone: PropTypes.string,
    pill_limit_json: PropTypes.object,
    procedure_limit_json: PropTypes.shape({
      limit: PropTypes.string,
      exceptions: PropTypes.string,
    }),
  }).isRequired,
  oneBasedIndex: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  setSelectedId: PropTypes.func.isRequired,
  cssClasses: PropTypes.oneOf(['', 'clicked', 'hovered', 'clicked hovered'])
    .isRequired,
}

export default NavigatorClinicCard
