import React from 'react'
import PropTypes from 'prop-types'
import { OrgInputPlainLabel } from '../navigator_support_orgs/inputs/OrgInputs.jsx'
import './TrueFalseMixedCheckbox.scss'

const TrueFalseMixedCheckbox = ({
  label, // day (eg. 'm', 'tu', 'w')
  onChange,
  preexistingValue,
  value,
}) => {
  let statusSquareClass

  const options = ['true', 'false', 'mixed']
  if (preexistingValue !== 'mixed') options.pop()

  const handleStatusSquareClick = () => {
    const indexBeforeClick = options.indexOf(value || preexistingValue)
    const newValue = options[(indexBeforeClick + 1) % options.length]

    onChange(newValue === preexistingValue ? '' : newValue)
  }

  switch (value) {
    case 'true':
      statusSquareClass = 'true-square'
      break
    case 'false':
      statusSquareClass = 'false-square'
      break
    default:
      statusSquareClass = `${preexistingValue}-square`
  }

  const getFullDayName = () => {
    switch (label) {
      case 'm':
        return 'Monday'
      case 'tu':
        return 'Tuesday'
      case 'w':
        return 'Wednesday'
      case 'th':
        return 'Thursday'
      case 'f':
        return 'Friday'
      case 's':
        return 'Saturday'
      case 'su':
        return 'Sunday'
      default:
        return label
    }
  }

  const ariaCheckboxValue = value || preexistingValue

  return (
    <div className={`TrueFalseMixedCheckbox ${preexistingValue}`}>
      <label htmlFor={`status-square-button ${label}`}>
        <OrgInputPlainLabel label={label} />
        <button
          id={`status-square-button ${label}`}
          aria-label={`${getFullDayName(label)} ${ariaCheckboxValue}`}
          className={`status-square ${statusSquareClass}`}
          onClick={handleStatusSquareClick}
          type="button"
        />
      </label>
    </div>
  )
}

TrueFalseMixedCheckbox.propTypes = {
  value: PropTypes.oneOf(['true', 'false', '']).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  preexistingValue: PropTypes.oneOf(['true', 'false', 'mixed']).isRequired,
}

export default TrueFalseMixedCheckbox
