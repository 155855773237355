import React from 'react'
import './ServerErrorMessage.scss'

const ServerErrorMessage = ({ filterQueryParams, clearFilters, message }) => (
  <div className="ServerErrorMessage">
    <div>An error occured retreiving your search results from the server</div>
    <div>{message}</div>
    {Object.entries(filterQueryParams || {}).length > 0 && (
      <button type="button" onClick={clearFilters}>
        Clear Filters
      </button>
    )}
  </div>
)

export default ServerErrorMessage
