import React, { useState } from 'react'
import './RequestEmailUpdate.scss'
import CancelModal from '../navigator_support_orgs/modals/CancelModal.jsx'
import { OrgEmailInput } from '../navigator_support_orgs/inputs/OrgInputs'
import { getCsrfToken } from '../../utilities/utilities.ts'

const RequestEmailUpdate = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [confirmEmail, setConfirmEmail] = useState('')
  const [errors, setErrors] = useState({})

  const headerContent = 'Request Update to Email Address'

  const handleUpdateEmail = () => {
    if (newEmail !== confirmEmail) {
      setErrors((prev) => ({ ...prev, confirmEmail: 'Emails do not match' }))
      return
    }

    const emailUpdate = async (request) => {
      await fetch('/current_user/request_email_update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCsrfToken(),
        },
        body: JSON.stringify(request),
      })
    }

    emailUpdate({ new_email: newEmail })

    setModalIsOpen(false)
  }

  const bodyContent = (
    <div className="RequestEmailUpdate">
      <div className="request-email-update-subheader">
        Submit email information below
      </div>
      <OrgEmailInput
        label="New Email Address"
        name="newEmail"
        onChange={(value) => setNewEmail(value)}
        required={true}
        setErrors={(x) => setErrors((prev) => ({ ...prev, newEmail: x }))}
        specificClass="request-email-update-input"
        value={newEmail}
      />
      <OrgEmailInput
        label="Confirm Email Address"
        name={confirmEmail}
        onChange={(value) => setConfirmEmail(value)}
        required={true}
        setErrors={(x) => setErrors((prev) => ({ ...prev, confirmEmail: x }))}
        specificClass="request-email-update-input"
        value={confirmEmail}
      />
      {newEmail !== confirmEmail && (
        <div className="errors">Emails do not match</div>
      )}
    </div>
  )

  return (
    <div className="RequestEmailUpdate">
      <button onClick={() => setModalIsOpen(true)} type="button">
        Request Update
      </button>
      <CancelModal
        body={bodyContent}
        cancelButtonText="Cancel"
        closeModal={() => setModalIsOpen(false)}
        confirmButtonText="Request Update"
        confirmButtonDisabled={
          newEmail !== confirmEmail || Object.values(errors).some(Boolean)
        }
        confirmModal={handleUpdateEmail}
        header={headerContent}
        headerClassName="request-email-update-cancel-modal"
        isOpen={modalIsOpen}
      />
    </div>
  )
}

export { RequestEmailUpdate }
