import React from 'react'
import IndexEmptyResults from '../../shared/index_pages/IndexEmptyResults.jsx'
import './NoResultsMapCard.scss'

const NoResultsMapCard = ({ onMapViewText }) => (
  <div className="NoResultsMapCard">
    <IndexEmptyResults onMapViewText={onMapViewText} />
  </div>
)

export default NoResultsMapCard
