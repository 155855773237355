import React from 'react'
import ExpandablePoem from '../ExpandablePoem.jsx'
import ExpandableStanza from '../ExpandableStanza.jsx'
import { OrgStringInput, OrgTextInput } from '../inputs/OrgInputs.jsx'
import phoneNumberFormatter from '../../phone_number_formatter'

const PoemAdminOnly = ({
  currentNavigatorSupportOrg,
  updateNavigatorSupportOrg,
  expanded,
  toggleExpanded,
  prefix,
  isAdmin,
  fromServerErrors = {},
}) => {
  if (!isAdmin) return null

  return (
    <ExpandablePoem
      header="Admin Only"
      subheader="Contact name, phone, and email for admin requests and outreach"
      expanded={expanded}
      toggleExpanded={toggleExpanded}
      poemClass="PoemAdminOnly"
    >
      <ExpandableStanza sidebar="Contact Info">
        <OrgStringInput
          name={`${prefix}[admin_contact_name]`}
          label="Admin Contact Name"
          onChange={(value) =>
            updateNavigatorSupportOrg({ admin_contact_name: value })
          }
          value={currentNavigatorSupportOrg.admin_contact_name}
          errors={fromServerErrors.admin_contact_name}
        />

        <OrgStringInput
          name={`${prefix}[admin_contact_email]`}
          label="Admin Contact Email"
          value={currentNavigatorSupportOrg.admin_contact_email}
          onChange={(value) =>
            updateNavigatorSupportOrg({ admin_contact_email: value })
          }
          type="email"
          errors={fromServerErrors.admin_contact_email}
        />

        <OrgStringInput
          name={`${prefix}[admin_contact_phone]`}
          label="Admin Contact Phone"
          onChange={(value) =>
            updateNavigatorSupportOrg({
              admin_contact_phone: value.replace(/\D/g, '').slice(0, 10),
            })
          }
          value={phoneNumberFormatter(
            currentNavigatorSupportOrg.admin_contact_phone
          )}
          pattern={'^\\(\\d{3}\\)\\s\\d{3}-\\d{4}$'}
          errors={fromServerErrors.admin_contact_phone}
        />
      </ExpandableStanza>

      <ExpandableStanza sidebar="Others">
        <OrgTextInput
          label="Admin Note"
          name={`${prefix}[admin_note]`}
          value={currentNavigatorSupportOrg.admin_note}
          onChange={(value) => updateNavigatorSupportOrg({ admin_note: value })}
        />
      </ExpandableStanza>
    </ExpandablePoem>
  )
}

PoemAdminOnly.openIfErrors = [
  'admin_contact_name',
  'admin_contact_email',
  'admin_contact_phone',
]
export default PoemAdminOnly
