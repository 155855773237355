import React from 'react'
import * as Ariakit from '@ariakit/react'
import './Tooltip.scss'

/*
 * See test for how to use this component
 */

// Contents of the tooltip, i.e. the text you want to appear when hovering
export const Tooltip = ({ children, additionalClassName = '' }) => {
  return (
    <Ariakit.Tooltip
      className={`Tooltip-appears-at-document-root ariakit-tooltip ${additionalClassName}`}
    >
      <Ariakit.TooltipArrow />
      {children}
    </Ariakit.Tooltip>
  )
}

// <TooltipAnchor> should surround the element that should be hovered over to see the tooltip.
// - open = boolean should the tooltip be open? Handy for styling the tooltip
// - disabled = don't show tooltip after all.
export const TooltipAnchor = ({
  additionalClassName = '',
  children,
  disabled, // boolean
  open = undefined,
  placement, // string
  timeout = 50, // amount of milliseconds to wait before showing and hiding the tooltip
}) => {
  if (!(children instanceof Array))
    throw new Error('<TooltipAnchor> must contain a <Tooltip> tag.')

  const tooltipIndex = children.findIndex((el) => el.type === Tooltip)
  if (tooltipIndex === -1)
    throw new Error('<TooltipAnchor> must contain a <Tooltip> tag.')

  const tooltipElement = children[tooltipIndex]
  const childrenMinusTooltip = children
    .slice(0, tooltipIndex)
    .concat(children.slice(tooltipIndex + 1))

  return (
    <div
      className={
        additionalClassName
          ? `TooltipAnchor ${additionalClassName}`
          : 'TooltipAnchor'
      }
    >
      <Ariakit.TooltipProvider
        animate={true}
        open={open}
        placement={placement}
        timeout={timeout}
      >
        <Ariakit.TooltipAnchor className="ariakit-tooltip-anchor">
          {childrenMinusTooltip}
        </Ariakit.TooltipAnchor>
        {!disabled && tooltipElement}
      </Ariakit.TooltipProvider>
    </div>
  )
}
