import React from 'react'
import {
  OrgPhoneInput,
  OrgStringInput,
} from '../navigator_support_orgs/inputs/OrgInputs.jsx'

const PhoneNumberWithExtensionInput = ({
  label,
  value,
  setErrors,
  onChange,
}) => {
  const digits = (string) => string.replace(/\D/g, '')

  const handleChange = (phone, ext) => {
    const phoneWithExt = [digits(phone), digits(ext)].filter(Boolean).join('x')
    onChange(phoneWithExt)
  }

  const splitCombinedValue = () => {
    const [phone, extension] = value.split('x')
    return { phone: phone || '', extension: extension || '' }
  }

  const { phone, extension } = splitCombinedValue()

  return (
    <div
      className="PhoneNumberWithExtensionInput"
      style={{ display: 'grid', gridTemplateColumns: '3fr 1fr', gap: '10px' }}
    >
      <OrgPhoneInput
        label={label}
        onChange={(newPhoneValue) => handleChange(newPhoneValue, extension)}
        setErrors={setErrors}
        value={phone}
      />
      <OrgStringInput
        value={extension}
        onChange={(newExtensionValue) => handleChange(phone, newExtensionValue)}
        label="Extension"
      />
    </div>
  )
}

export default PhoneNumberWithExtensionInput
