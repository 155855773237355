import React, { useState } from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import './CostEntryLabelAndWhatsIncluded.scss'

const CostEntryLabelAndWhatsIncluded = ({
  costEntry,
  // errors,
  options,
  prefix,
}) => {
  const [label, setLabel] = useState(
    options.label.find(({ value }) => value === costEntry.label_id)
  )
  const [whats, setWhats] = useState(
    options.whats_included.filter(({ value }) =>
      costEntry.whats_included_ids?.includes(value)
    )
  )

  const whatsIncludedOptions = options.whats_included.filter(
    ({ display_for_tag_ids }) =>
      display_for_tag_ids.length === 0 || // in this case, display it for all labels
      display_for_tag_ids.includes(label?.value) // in this case, it is limited to certain labels only
  )

  const requiredLabelIdsFor366 = [96, 102, 365]

  return (
    <div className="CostEntryLabelAndWhatsIncluded">
      <label htmlFor={`cost-entry-${prefix}-label_id`}>
        <span>Label</span>
        <Select
          // className={errors.label_name ? 'error' : ''}
          inputId={`cost-entry-${prefix}-label_id`}
          isClearable
          name={`${prefix}[label_id]`}
          onChange={(selectedOption) => {
            setWhats([]) // clear previous selections
            setLabel(selectedOption)

            // If the label selected is "Telehealth Medication Abortion",
            // "Medication Abortion In-Clinic", or "Abortion Pills for Future Use",
            // auto-populate "whats_included_ids" with "Abortion Pills".
            if (
              selectedOption?.value === 96 ||
              selectedOption?.value === 102 ||
              selectedOption?.value === 365
            ) {
              setWhats((prevWhats) => [
                ...prevWhats,
                { value: 366, label: 'Abortion Pills' },
              ])
            }
          }}
          options={options.label}
          value={label}
        />
      </label>

      <label htmlFor={`cost-entry-${prefix}-whats_included_ids`}>
        <span>What&apos;s included</span>
        <Select
          // className={errors.whats_included_names ? 'error' : ''}
          inputId={`cost-entry-${prefix}-whats_included_ids`}
          isDisabled={!label}
          isMulti
          name={`${prefix}[whats_included_ids][]`}
          onChange={setWhats}
          options={whatsIncludedOptions}
          value={whats}
        />
      </label>
    </div>
  )
}

CostEntryLabelAndWhatsIncluded.propTypes = {
  costEntry: PropTypes.shape({
    label_id: PropTypes.number,
    whats_included_ids: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  // errors: PropTypes.object.isRequired,
  options: PropTypes.shape({
    label: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired, // this is the tag id
        label: PropTypes.string.isRequired,
      })
    ),
    whats_included: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired, // tag id
        label: PropTypes.string.isRequired,
        display_for_tag_ids: PropTypes.arrayOf(PropTypes.number),
      })
    ),
  }).isRequired,
  prefix: PropTypes.string.isRequired,
}

export { CostEntryLabelAndWhatsIncluded }
