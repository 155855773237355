import React from 'react'

const radioOptions = [
  { value: '', label: 'Any' },
  { value: '1w', label: 'Less than 1 week' },
  { value: '2w', label: 'Less than 2 weeks' },
  { value: '3w', label: 'Less than 3 weeks' },
  { value: '4w', label: 'Less than 4 weeks' },
]

const toBool = (maybeString) => {
  switch (maybeString) {
    case true:
    case 'true':
      return true
    case false:
    case undefined:
    case null:
    case 'false':
      return false
    default:
      console.error('Invalid input')
      return undefined
  }
}

const FilterDrawerAppointmentAvailability = ({ value = {}, onChange }) => {
  return (
    <div className="drawer-availability-filter">
      <div className="radio-group">
        {radioOptions.map((option) => (
          <label key={option.value} className="radio-subgroup">
            <input
              type="radio"
              name="appointmentAvailability"
              value={option.value}
              checked={value.weeks === option.value}
              onChange={() => onChange({ ...value, weeks: option.value })}
            />
            <span className="radio-label">{option.label}</span>
          </label>
        ))}
      </div>
      <div className="checkbox-container">
        <label>
          <input
            type="checkbox"
            id="excludeUnknowns"
            checked={toBool(value.exclude_unknowns)}
            onChange={(e) =>
              onChange({ ...value, exclude_unknowns: e.target.checked })
            }
          />
          <span className="checkbox-label">Exclude unknowns</span>
        </label>
      </div>
    </div>
  )
}

export default FilterDrawerAppointmentAvailability
