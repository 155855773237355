import React from 'react'
import Modal from 'react-modal' // married to scss below
import PropTypes from 'prop-types'
import blackXIcon from '../../assets/images/blackX.svg'
import { deepMerge } from '../utilities/compare_and_merge.js'
import genericModalStyle from './genericModalStyle.js'

const GenericModal = ({
  children,
  closeButtonText,
  confirmButtonText,
  contentLabel,
  customStyles,
  header,
  isOpen,
  onConfirm,
  onRequestClose,
}) => {
  const closeButton = closeButtonText && (
    <button className="white-button" type="button" onClick={onRequestClose}>
      {closeButtonText}
    </button>
  )
  const confirmButton = confirmButtonText && onConfirm && (
    <button className="blue-button" type="button" onClick={onConfirm}>
      {confirmButtonText}
    </button>
  )

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      style={deepMerge(genericModalStyle, customStyles)}
    >
      <div className="header">
        <h2>{header}</h2>
        <button className="x-button" type="button" onClick={onRequestClose}>
          <img src={blackXIcon} alt="close modal" />
        </button>
      </div>
      {children}
      {(closeButton || confirmButton) && (
        <div className="modal-buttons" style={{ margin: '24px 0 0' }}>
          {closeButton}
          {confirmButton}
        </div>
      )}
    </Modal>
  )
}

GenericModal.defaultProps = {
  closeButtonText: 'Cancel',
  confirmButtonText: 'Confirm',
  contentLabel: 'modal label',
  customStyles: {},
  header: '',
  onConfirm: undefined,
}

const PropTypeStyleObject = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.number, PropTypes.string])
)

// for the texts, accept a string or false (true is not really acceptable)
GenericModal.propTypes = {
  closeButtonText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  confirmButtonText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  contentLabel: PropTypes.string,
  customStyles: PropTypes.shape({
    content: PropTypeStyleObject,
    overlay: PropTypeStyleObject,
  }),
  header: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
}

export default GenericModal
