import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _uniqueId from 'lodash/uniqueId'
import { CostEntryLabelAndWhatsIncluded } from './CostEntryLabelAndWhatsIncluded.jsx'
import { PriceForm } from './PriceForm.jsx'
import { DeleteHasManyInput } from './DeleteHasManyInput.jsx'
import './CostEntryForm.scss'

const blankGalAttrs = {
  max_gal_days: null,
  max_gal_mode: 'UNDEFINED',
  min_gal_days: null,
  min_gal_mode: 'UNDEFINED',
}

const startsPricedByGal = (costEntry) => {
  if (Object.keys(costEntry.prices).length > 1) return true
  return Object.values(costEntry.prices).some(
    (price) =>
      typeof price.min_gal_days === 'number' ||
      typeof price.max_gal_days === 'number'
  )
}

const sortPrices = (a, b) => {
  if (a.min_gal_days === b.min_gal_days)
    return (a.min_gal_mode || '').localeCompare(b.min_gal_mode || '')
  // Handle NULLS FIRST for min_gal_days
  if (a.min_gal_days === null) return -1
  if (b.min_gal_days === null) return 1
  return (a.min_gal_days || 0) - (b.min_gal_days || 0)
}

const CostEntryForm = ({
  costEntry,
  entryIndex,
  updateCostEntry,
  prefix,
  options,
}) => {
  const [pricedByGal, setPricedByGal] = useState(startsPricedByGal(costEntry))

  const disableRemoveButton = (price) => {
    if (!pricedByGal) return true

    // always allow unchecking of 'delete' checkbox
    if (price.id && price._destroy) return false
    // disable destroying if there are are no others
    return Object.values(costEntry.prices).filter((p) => !p._destroy).length < 2
  }

  let undeletedPriceIndex = 0

  return (
    <div className="CostEntryForm">
      <input type="hidden" name={`${prefix}[id]`} value={costEntry.id || ''} />
      <input
        type="hidden"
        name={`${prefix}[_destroy]`}
        value={costEntry._destroy || ''}
      />
      <div className="cost-entry-header">
        <span className="entry-label">Cost Entry {entryIndex + 1}</span>

        <label className="priced-by-gal">
          <input
            type="checkbox"
            onChange={(e) => setPricedByGal(e.target.checked)}
            checked={!!pricedByGal}
          />
          Priced by GAL
        </label>
      </div>
      <CostEntryLabelAndWhatsIncluded
        costEntry={costEntry}
        options={options}
        prefix={prefix}
      />
      <span className="prices-label">Price</span>
      {Object.entries(costEntry.prices)
        .sort(([, priceA], [, priceB]) => sortPrices(priceA, priceB))
        .map(([key, price]) => {
          const priceOverrides = pricedByGal ? {} : blankGalAttrs
          if (!pricedByGal && undeletedPriceIndex)
            priceOverrides._destroy = true
          if (!price._destroy) undeletedPriceIndex += 1

          return (
            <PriceForm
              key={key}
              i={undeletedPriceIndex}
              price={{ ...price, ...priceOverrides }}
              prefix={`${prefix}[prices_attributes][${key}]`}
              updatePrice={(newPrice) =>
                updateCostEntry({ prices: { [key]: newPrice } })
              }
              removeButtonDisabled={disableRemoveButton(price)}
              hideGalInputs={!pricedByGal}
            />
          )
        })}
      {pricedByGal && (
        <button
          className="new-price-item"
          type="button"
          onClick={() =>
            updateCostEntry({ prices: { [_uniqueId('newPrice-')]: {} } })
          }
        >
          Add Price Item
        </button>
      )}
      <DeleteHasManyInput
        record={costEntry}
        updateRecord={updateCostEntry}
        label="Cost Entry"
      />
    </div>
  )
}

const costEntryShape = PropTypes.shape({
  label_id: PropTypes.number,
  whats_included_ids: PropTypes.arrayOf(PropTypes.number),
  prices: PropTypes.objectOf(PropTypes.shape({})),
})

CostEntryForm.propTypes = {
  costEntry: costEntryShape.isRequired,
  options: PropTypes.shape({
    label: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired, // this is the tag id
        label: PropTypes.string.isRequired,
      })
    ),
    whats_included: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired, // tag id
        label: PropTypes.string.isRequired,
        display_for_tag_ids: PropTypes.arrayOf(PropTypes.number),
      })
    ),
  }).isRequired,
  prefix: PropTypes.string.isRequired,
}

export { CostEntryForm }
