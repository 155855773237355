import React from 'react'
import { TooltipAnchor, Tooltip } from '../../../shared/Tooltip.jsx'
import BluePlus from './blue_plus.svg'
import './PlusButton.scss'

const PlusButton = ({ onClick, text, disabled, disabledTooltip }) => {
  const button = (
    <button type="button" onClick={onClick} disabled={!!disabled}>
      <img src={BluePlus} alt="" />
      <span>{text}</span>
    </button>
  )

  const buttonMaybeWithTooltip = disabled ? (
    <TooltipAnchor additionalClassName="PlusButton">
      {button}
      <Tooltip>{disabledTooltip}</Tooltip>
    </TooltipAnchor>
  ) : (
    button
  )

  return <div className="PlusButton">{buttonMaybeWithTooltip}</div>
}

export default PlusButton
