import React from 'react'
import PropTypes from 'prop-types'
import blackXIcon from '../../../assets/images/blackX.svg'
import { humanizeString } from '../../utilities/humanize_string.js'
import { TooltipAnchor, Tooltip } from '../../shared/Tooltip.jsx'
import './BulkEditSidebar.scss'

const SidebarSection = ({
  disabledAttributes,
  disabled,
  items,
  sectionLabel,
  selectedAttributes,
  setSelectedAttribute,
}) => {
  const sectionDisabledTooltipText =
    'Abortion services must be toggled to "Y" for all selected providers to use bulk edit for abortion services.'

  const disabledTelehealthAbortionInfoTooltipText =
    'To edit telehealth abortion service information, all providers must be the same location type (hybrid, or virtual only).'

  const disabledGestationalAgeLimitsTooltipText =
    'To edit gestational age limits, at least one type of abortion must be toggled to "Y" (pill, procedure, or telehealth) for all selected providers.'

  const disabledAbortionAppointmentAndScheduleTooltipText =
    'To edit abortion schedule and appointment information, at least one type of abortion must be toggled to "Y" (pill, procedure, or telehealth) for all selected providers.'

  const tooltipText = (attribute) => {
    if (disabled) return sectionDisabledTooltipText

    switch (attribute) {
      case 'telehealth_abortion_info':
        return disabledTelehealthAbortionInfoTooltipText
      case 'gestational_age_limits':
        return disabledGestationalAgeLimitsTooltipText
      case 'abortion_appointment_and_schedule_info':
        return disabledAbortionAppointmentAndScheduleTooltipText
      default:
        return console.error(`Invalid attribute: ${attribute}`)
    }
  }

  const checkboxIsDisabled = (attribute) =>
    disabled || disabledAttributes.includes(attribute)

  return (
    <div className="SidebarSection">
      <div className="section-label">{sectionLabel}</div>
      <div className="boxes">
        {Object.keys(items).map((bulkEditKey) => (
          <TooltipAnchor
            key={bulkEditKey}
            placement="left"
            disabled={!checkboxIsDisabled(bulkEditKey)}
          >
            <BulkEditItemCheckbox
              bulkEditKey={bulkEditKey}
              disabled={checkboxIsDisabled(bulkEditKey)}
              selectedAttributes={selectedAttributes}
              setSelectedAttribute={setSelectedAttribute}
            />
            <Tooltip>{`${
              checkboxIsDisabled(bulkEditKey) && tooltipText(bulkEditKey)
            }`}</Tooltip>
          </TooltipAnchor>
        ))}
      </div>
    </div>
  )
}

const BulkEditItemCheckbox = ({
  bulkEditKey,
  selectedAttributes,
  setSelectedAttribute,
  disabled,
}) => {
  return (
    <label key={bulkEditKey} className={disabled ? 'disabled' : ''}>
      <input
        type="checkbox"
        checked={selectedAttributes.includes(bulkEditKey)}
        onChange={(e) => setSelectedAttribute([bulkEditKey, e.target.checked])}
        disabled={disabled}
      />
      {humanizeString(bulkEditKey)}
    </label>
  )
}

const BulkEditSidebar = ({
  clickNextButton,
  closeSidebar,
  editableAttributeGroups,
  recordDetails,
  selectedAttributes,
  setSelectedAttribute,
}) => {
  const disabledSections = []

  const allRecordsHaveAbortionServices = recordDetails.every((record) => {
    const knownOfferings = record.known_offerings || {}
    return knownOfferings.abortion_services === true
  })

  if (!allRecordsHaveAbortionServices) {
    disabledSections.push('Abortion Services')
  }

  const disabledAttributes = []

  const allHybridOrTelehealthOnlyClinics =
    recordDetails.every(
      (record) =>
        record.location_type === 'hybrid' ||
        record.location_type === 'telehealth_only'
    ) &&
    recordDetails.every(
      (record) => record.location_type === recordDetails[0].location_type
    )
  if (!allHybridOrTelehealthOnlyClinics) {
    disabledAttributes.push('telehealth_abortion_info')
  }

  const allClinicsAbortionTelemedicine = recordDetails.every((record) => {
    return record.known_offerings['abortion_telemedicine']
  })
  const allClinicsMedicationAbortionPill = recordDetails.every((record) => {
    return record.known_offerings['medication_abortion_pill']
  })
  const allClinicsSurgicalAbortion = recordDetails.every((record) => {
    return record.known_offerings['surgical_abortion']
  })
  if (
    !allClinicsAbortionTelemedicine &&
    !allClinicsMedicationAbortionPill &&
    !allClinicsSurgicalAbortion
  ) {
    disabledAttributes.push('gestational_age_limits')
    disabledAttributes.push('abortion_appointment_and_schedule_info')
  }

  return (
    <div id="BulkEditSidebar">
      <button className="x-button" onClick={closeSidebar} type="button">
        <img src={blackXIcon} alt="close sidebar" />
      </button>

      <h2 className="title">Select Fields to Bulk Edit</h2>
      {editableAttributeGroups.map(({ label, items }) => (
        <SidebarSection
          sectionLabel={label}
          key={label}
          items={items}
          disabledAttributes={disabledAttributes}
          disabled={disabledSections.includes(label)}
          selectedAttributes={selectedAttributes}
          setSelectedAttribute={setSelectedAttribute}
        />
      ))}
      <button
        className="next"
        onClick={clickNextButton}
        type="button"
        disabled={selectedAttributes.length === 0}
      >
        Next
      </button>
    </div>
  )
}

BulkEditSidebar.propTypes = {
  clickNextButton: PropTypes.func.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  selectedAttributes: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedAttribute: PropTypes.func.isRequired,
}

export default BulkEditSidebar
