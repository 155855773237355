import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { TooltipAnchor, Tooltip } from '../Tooltip.jsx'
import IndexEmptyResults from './IndexEmptyResults.jsx'
import StarUnstarButton from './StarUnstarButton.jsx'
import { setOf } from '../../utilities/custom_prop_types.js'
import './IndexTable.scss'

const IndexTable = ({
  recordType,
  columns,
  hasSelectableColumn,
  onStarredPage = false,
  privateRecordTooltipText,
  records,
  selectedIds,
  setSelectedIds,
}) => {
  // initialize all passed-in records to be starred; in the hash {recordId: true, ...}
  const [starredRecords, setStarredRecords] = useState(
    records.reduce((acc, record) => {
      acc[record.id] = true
      return acc
    }, {})
  )

  // START Selectable Column
  const setSelectedId = (id, newVal) => {
    const newSelections = new Set(selectedIds)
    if (newVal) newSelections.add(id)
    else newSelections.delete(id)
    setSelectedIds(newSelections)
  }

  const handleSelectAll = (event) => {
    if (event.target.checked) setSelectedIds(new Set(records.map((r) => r.id)))
    else setSelectedIds(new Set())
  }
  const allSelected =
    records.length > 0 && records.every((r) => selectedIds.has(r.id))
  const someSelected =
    !allSelected && records.some((r) => selectedIds.has(r.id))

  // React does not handle indeterminate state for a checkbox
  // so we have to use this selectAllRef to set it
  const selectAllRef = useRef(null)
  useEffect(() => {
    if (selectAllRef.current) selectAllRef.current.indeterminate = someSelected
  }, [someSelected])

  // A checkbox for selectable column, blank for starred column
  const thForSelectableColumn = (
    <th>
      {!onStarredPage && (
        <input
          ref={selectAllRef}
          type="checkbox"
          checked={allSelected}
          onChange={handleSelectAll}
        />
      )}
    </th>
  )

  // A checkbox for selectable column, a star form button for starred column
  const tdForInteractionColumn = (record) => (
    <td className="selectable">
      {!onStarredPage ? (
        <TooltipAnchor disabled={!record.private_record}>
          <input
            type="checkbox"
            checked={selectedIds.has(record.id)}
            onChange={(e) => setSelectedId(record.id, e.target.checked)}
            disabled={record.private_record}
          />
          <Tooltip>{privateRecordTooltipText}</Tooltip>
        </TooltipAnchor>
      ) : (
        <StarUnstarButton
          isStarred={starredRecords[record.id]}
          setIsStarred={(newStarred) =>
            setStarredRecords({ ...starredRecords, [record.id]: newStarred })
          }
          recordType={recordType}
          recordId={record.id}
        />
      )}
    </td>
  )

  // END Selectable Column

  const thForColumn = (column) => (
    <th key={column.cssClass} className={column.cssClass}>
      {column.header}
    </th>
  )

  const tdForColumn = (column, record) => (
    <td
      key={column.cssClass}
      className={`col ${column.cssClass}`}
      data-testid={column.testId}
    >
      {column.render(record)}
    </td>
  )

  const tableIfAnyRecords = records.length > 0 && (
    <table>
      <thead>
        <tr>
          {hasSelectableColumn && thForSelectableColumn}
          {columns.map((col) => thForColumn(col))}
        </tr>
      </thead>
      <tbody>
        {records.map((record) => (
          <tr
            data-testid={`tr-${record.id}`}
            key={record.id}
            className={record.private_record ? 'private-record' : ''}
          >
            {hasSelectableColumn && tdForInteractionColumn(record)}
            {columns.map((column) => tdForColumn(column, record))}
          </tr>
        ))}
      </tbody>
    </table>
  )

  return (
    <div className="IndexTable">
      {tableIfAnyRecords || <IndexEmptyResults />}
    </div>
  )
}

IndexTable.defaultProps = {
  privateRecordTooltipText: 'This is a private record and cannot be shared',
  onStarredPage: false,
}

IndexTable.propTypes = {
  onStarredPage: PropTypes.bool,
  hasSelectableColumn: PropTypes.bool.isRequired,
  privateRecordTooltipText: PropTypes.string,
  records: PropTypes.arrayOf(
    PropTypes.shape({
      private_record: (props) => {
        // validate presence of private_record ONLY for clinics and org profiles
        if (
          (props.nearby_airports !== undefined ||
            props.org_type !== undefined) && // if a clinic or an an org profile
          typeof props.private_record !== 'boolean' // then it requires a private record field
        ) {
          return new Error('Field with a private record is not a boolean!')
        }
        return true
      },
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  recordType: PropTypes.oneOf([
    'NavigatorClinic',
    'NavigatorSupportOrg',
    'Profile',
    'NavigatorResourceRecord',
  ]).isRequired,
  selectedIds: setOf(PropTypes.number).isRequired,
  setSelectedIds: PropTypes.func.isRequired,
}

export default IndexTable
