import React from 'react'
import PropTypes from 'prop-types'

import { unixToHumanTime } from './dashboard_charts'
import { Area, AreaChart, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts' // https://recharts.org/en-US/api

export const AllUsersChart = ({ data }) => {
  let userDatumObj = {}
  let userDataArr = []
  let dayCount = 0
  let dayArr = []
  let uname = ''
  data.forEach(function (userData, i) {
    userData.num_logs_per_day.forEach(function (userDatum) {
      if (i === 0) {
        dayArr.push(unixToHumanTime(userDatum['unix_time']))
        dayCount = dayArr.length
      }
      uname = userData['user']
        ? 'user ID: ' + userData['user_id'] + '  views: '
        : ''
      userDatumObj = {
        username: uname,
        clinicViews: userDatum['clinic_views'],
      }
      userDataArr.push(userDatumObj)
    })
  })

  let chartArr = []
  let areaJSX = []
  for (let i = 0; i < dayCount; i++) {
    let emptyObj = {}
    chartArr.push(emptyObj)
    chartArr[i].name = dayArr[i]
    userDataArr.forEach(function (userDatum, j) {
      if (j % dayCount === 0 && i === 0) {
        areaJSX.push(
          <Area
            type="monotone"
            dataKey={userDatum['username']}
            stroke="#8884d8"
            fillOpacity="1"
            fill="url(#colorUv)"
            key={j}
          />
        )
      }
      if (j % dayCount === i) {
        let keyName = userDatum['username']
        chartArr[i][[keyName]] = userDatum['clinicViews']
      }
    })
  }

  return (
    <div className="AllUsersChart">
      <AreaChart
        width={730}
        height={250}
        data={chartArr}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        {areaJSX}
      </AreaChart>
    </div>
  )
}

AllUsersChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.string.isRequired,
      user_id: PropTypes.number.isRequired,
      num_logs_per_day: PropTypes.arrayOf(
        PropTypes.shape({
          clinic_views: PropTypes.number.isRequired,
          unix_time: PropTypes.number.isRequired,
        })
      ).isRequired,
    }).isRequired
  ),
}

export default AllUsersChart
