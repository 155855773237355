import React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import './FellowsPacing.scss'

// https://www.heavy.ai/blog/12-color-palettes-for-telling-better-stories-with-your-data
// Spring Pastels
const colors = [
  '#fd7f6f',
  '#7eb0d5',
  '#b2e061',
  '#bd7ebe',
  '#ffb55a',
  '#ffee65',
  '#beb9db',
  '#fdcce5',
  '#8bd3c7',
]

const yAxisMin = 10

const FellowsPacing = ({ counts }) => {
  return (
    <div id="FellowsPacing">
      <h2>
        Count of clinics assigned to fellows updated within the time frame
        indicated
      </h2>

      <div className="content">
        {/*        {Object.keys(Object.values(counts)[0]).map((key) => (
          <h3 key={key}>{key}</h3>
        ))}
*/}
        {Object.entries(counts).map(([fellow, fellowData]) => (
          <React.Fragment key={fellow}>
            {Object.entries(fellowData).map(([column, columData], i) => (
              <BarChart width={500} height={300} data={columData} key={column}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" />
                <YAxis domain={[0, (dataMax) => Math.max(yAxisMin, dataMax)]} />
                <Tooltip />
                <Bar dataKey="value" fill={colors[i + 3]} />
                <text
                  x="98%"
                  y="06%"
                  textAnchor="end"
                  style={{ fontSize: 14, fontWeight: 'bold', fill: 'grey' }}
                >
                  {column}
                </text>
              </BarChart>
            ))}
            <div className="fellow-label">{fellow}</div>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default FellowsPacing
