window.clinic_changes = {
  setupForm: () => {
    // add checkmarks to each changed item
    // eslint-disable-next-line func-names
    $('.attributes_table.clinic tr.row.has-change').each(function () {
      const attribute = $(this).attr('class').replace('row has-change ', '')
      $(this)
        .find('th')
        .prepend(
          `<input class="change ${attribute}" type="checkbox" name=${attribute}>`
        )
    })

    // mark the checkbox when clicked
    // eslint-disable-next-line func-names
    $('input.change').change(function () {
      const attribute = $(this).attr('name')
      $(`input.change.${attribute}`).not(this).prop('checked', false)
    })

    // handle submitting clinic changes
    // eslint-disable-next-line func-names
    $('form.apply_changes').on('submit', function (event) {
      const checked = $('.change-container input:checked')
      const form = $(this)

      if (checked.length === 0) {
        event.preventDefault()
        // eslint-disable-next-line no-alert
        alert('You must select at least one attribute.')
      } else {
        // eslint-disable-next-line no-alert
        const apply = window.confirm(
          'Apply selected changes?\nUnselected changes will be rejected.'
        )
        if (apply) {
          form.find("input[name='attributes[]']").remove()
          $.each(checked, (index, attribute) => {
            $('<input>')
              .attr({
                type: 'hidden',
                name: `changes[${$(attribute).attr('name')}]`,
                value: $(attribute).parents('.change-container').data('id'),
              })
              .appendTo(form)
          })
        } else {
          event.preventDefault()
        }
      }
    })
  },
}
