import React, { useState } from 'react'
import OfferingsRow from './OfferingsRow.jsx'
// import YesNoUnknownCheckbox from "./YesNoUnknownCheckbox";
// import OfferingCheckbox from "./OfferingCheckbox";

import './OfferingsTree.scss'

// treeData
// [ {
//   key: 'key',
//   label: 'label',
//   priority: true,
//   children: [
//     { (same structure) }
//   ]
// }]

const OfferingsTree = ({
  treeData,
  initialKnownOfferings,
  inputNamePrefix = '', // not needed for show page where inputs are just for display
  disabled = false,
  disabledKeys = [],
  yesNoUnknown = true, // choose between YesNoUnknownCheckbox and OfferingCheckbox
}) => {
  const [knownOfferings, setKnownOfferings] = useState(initialKnownOfferings)
  const applyKnownOfferings = (knownOfferingsChanges) => {
    const newOfferings = { ...knownOfferings, ...knownOfferingsChanges }
    setKnownOfferings(newOfferings)
  }

  return (
    <ControlledOfferingsTree
      treeData={treeData}
      knownOfferings={knownOfferings}
      inputNamePrefix={inputNamePrefix}
      disabled={disabled}
      disabledKeys={disabledKeys}
      yesNoUnknown={yesNoUnknown}
      applyKnownOfferings={applyKnownOfferings}
    />
  )
}

const ControlledOfferingsTree = ({
  treeData,
  knownOfferings,
  inputNamePrefix = '', // not needed for show page where inputs are just for display
  disabled = false,
  disabledKeys = [],
  disabledTooltipText,
  yesNoUnknown = true, // choose between YesNoUnknownCheckbox and OfferingCheckbox
  getExtraStylesForRow,
  applyKnownOfferings,
}) => {
  return (
    <div className="OfferingsTree">
      {treeData.map((rootBranchData) => (
        <OfferingsRow
          branchData={rootBranchData}
          inputNamePrefix={inputNamePrefix}
          getExtraStylesForRow={getExtraStylesForRow}
          knownOfferings={knownOfferings}
          applyKnownOfferings={applyKnownOfferings}
          disabled={disabled}
          disabledKeys={disabledKeys}
          disabledTooltipText={disabledTooltipText}
          isRoot={true}
          key={rootBranchData.key}
          yesNoUnknown={yesNoUnknown}
        />
      ))}
    </div>
  )
}

export default OfferingsTree
export { ControlledOfferingsTree }
