import ReactOnRails from 'react-on-rails'

function initiateChosenAllowNewValues() {
  // eslint-disable-next-line func-names
  $('.chosen-allow-new-values:not(.chosed)').each((i, elem) => {
    if (!elem.dataset.placeholder)
      // eslint-disable-next-line no-param-reassign
      elem.dataset.placeholder = 'Type in, or select some options'
    elem.classList.add('chosed')
    $(elem).chosen({
      allow_single_deselect: true,
      inherit_select_classes: true,
      no_results_text: 'press Tab to add new option',
    })
  })

  $(
    '.chosen-container.chosen-allow-new-values input.chosen-search-input'
  ).keydown((event) => {
    if (event.key !== 'Tab') return

    const typedValue = event.target.value

    const $chosen_container = $(event.target).parents(
      '.chosen-allow-new-values'
    )
    const $og_select = $(
      `#${$chosen_container.prop('id').replace(/_chosen$/, '')}`
    )
    // check if the same value already exists
    const foundExistingOption = $og_select.find(`option[value="${typedValue}"]`)
    if (foundExistingOption.length === 0) {
      // it does not already exist. Append it and update chosen-select field

      event.preventDefault()

      $og_select.append(
        `<option selected="selected" value="${typedValue}" >${typedValue}</option>`
      )
      $og_select.trigger('chosen:updated')

      // if this is a single select, close it.  If multi select, leave open to choose more values.
      if ($chosen_container.prop('class').includes('chosen-container-single'))
        $og_select.trigger('chosen:close')
    }
  })
}

window.NavigateAdminUtils = {
  initializeNewThings() {
    if (!ReactOnRails.reactOnRailsPageLoaded) {
      console.warn(
        'ReactOnRails internals have changed, NavigateAdminUtils needs update!!!'
      )
    }

    // ActiveAdmin's has_many inputs adds new fieldsets to the DOM
    // If these contain react components or chosen components, we need to initialize them
    this.initializeReactOnRailsComponents()
    this.initiateAllChosen()
  },

  initiateAllChosen() {
    $('.chosen:not(.chosed)')
      .addClass('chosed')
      .chosen({ allow_single_deselect: true })
    initiateChosenAllowNewValues()
  },

  initializeReactOnRailsComponents() {
    document
      .querySelectorAll('.js-react-on-rails-component')
      .forEach((scriptTag) => {
        const id = scriptTag.dataset.domId
        const div = document.getElementById(id)

        if (div.innerHTML) return // react component has already been rendered

        const newUniqueId = `${id}-${Date.now()}`
        // eslint-disable-next-line no-param-reassign
        scriptTag.dataset.domId = newUniqueId
        div.id = newUniqueId

        ReactOnRails.render(
          scriptTag.dataset.componentName,
          JSON.parse(scriptTag.textContent),
          newUniqueId,
          false
        )
      })
  },

  // Reload a <turbo-frame> after some time delay.
  reloadTurboFrame(timeMillis) {
    const turboFrame = document.currentScript.closest('turbo-frame')
    if (!turboFrame) {
      throw new Error(
        'Could not locate <turbo-frame>. Must be called in a <script> tag within a <turbo-frame>.'
      )
    }
    setTimeout(() => turboFrame.reload(), timeMillis)
  },

  duplicateButtonsInActiveAdminFilterSidebar() {
    const $filterFormButtons = $(
      'body.active_admin.index form.filter_form div.buttons'
    )
      .clone()
      .addClass('cloned-filter-buttons')
    $('body.active_admin.index form.filter_form').prepend($filterFormButtons)
  },
}

export default window.NavigateAdminUtils
