import React from 'react'
import LoadinDots from './LoadinDots.jsx'
import navigatorSupportOrgModalStyles from '../views/navigator_support_orgs/modals/navigator_support_org_modal_styles.js'

const style = {
  content: {
    position: 'absolute',
    inset: '50% auto auto 50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 7001,
  },

  overlay: {
    backgroundColor: navigatorSupportOrgModalStyles.overlay.backgroundColor,
    position: 'fixed',
    inset: '0px',
    zIndex: 7000,
  },
}

const LoadinDotsFullScreen = () => (
  <div
    className="LoadinDotsFullScreen"
    style={style.overlay}
    data-testid="LoadinDotsFullScreen"
  >
    <div style={style.content}>
      <LoadinDots />
    </div>
  </div>
)

export default LoadinDotsFullScreen
