import axios from 'axios'
import qs from 'qs'

export const queryString = (obj) =>
  qs.stringify(obj, { arrayFormat: 'brackets' })

export const fetchResultsFromServer = async (baseUrl, filterQueryParams) => {
  const qstring = queryString({ q: filterQueryParams })

  const response = await axios.get(`${baseUrl}.json?${qstring}`)
  return response.data
}
