import React from 'react'
import PropTypes from 'prop-types'
import './PaginationLinks.scss'

const gap = (key) => (
  <span className="page gap" key={key}>
    …
  </span>
)

// const recordDisplayNames = {
//   NavigatorClinic: 'Providers',
//   NavigatorSupportOrg: 'Support Organizations',
//   Profile: 'User Profiles',
//   NavigatorResourceRecord: 'Resources',
// }

const PaginationLinks = ({
  currentPage,
  onPageChange,
  perPage,
  // recordType,
  totalRecords,
}) => {
  const totalPages = Math.ceil(totalRecords / perPage)

  // [Olive apr 2024] old version: keeping around in case they change their minds about the design again.
  // Displaying {recordDisplayNames[recordType]}&nbsp;
  // <b>
  //   {(currentPage - 1) * perPage + 1}&nbsp;-&nbsp;
  //   {Math.min(currentPage * perPage, totalRecords)}
  // </b>{' '}
  // of <b>{totalRecords}</b> results

  const paginationInfo =
    totalPages < 1 ? null : (
      <div className="paginationInfo">
        {`Showing ${(currentPage - 1) * perPage + 1} - ${Math.min(
          currentPage * perPage,
          totalRecords
        )} of ${totalRecords} results`}
      </div>
    )

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1)

  const paginationLinksNav = (
    <nav className="paginationLinksNav">
      <button
        type="button"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        ᐸ
      </button>

      {pageNumbers.map((number) => {
        if (
          number === 1 ||
          number === totalPages ||
          (number >= currentPage - 4 && number <= currentPage + 4)
        ) {
          return (
            <button
              key={number}
              type="button"
              className={number === currentPage ? 'selected' : ''}
              onClick={() => onPageChange(number)}
              disabled={number === currentPage}
            >
              {number}
            </button>
          )
        }
        if (
          (number === 2 && currentPage > 5) ||
          (number === totalPages - 1 && currentPage < totalPages - 4)
        )
          return gap(number)
        return null
      })}

      <button
        type="button"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        ᐳ
      </button>
    </nav>
  )

  return (
    <div id="PaginationLinks">
      {totalPages > 1 && paginationLinksNav}
      {paginationInfo}
    </div>
  )
}

PaginationLinks.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  perPage: PropTypes.number.isRequired,
  // recordType: PropTypes.oneOf(Object.keys(recordDisplayNames)).isRequired,
  totalRecords: PropTypes.number.isRequired,
}

export default PaginationLinks
