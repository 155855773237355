// console.debug('loading js: app/javascript/entrypoints/_rails_and_active_admin.js')

//      Other people's JS
import 'chosen-js/chosen.min.css'
import 'chosen-js'
import '../vendor/activeadmin_reorderable-0.1.5/activeadmin_reorderable.js'
import MicroModal from 'micromodal' // es6 module
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css' // optional for styling
import 'tippy.js/animations/perspective.css'
import 'tippy.js/themes/light-border.css'

// Turbo
import { Turbo } from '@hotwired/turbo-rails'

// non-React Javascript
import '../shared/comments.js'
import ReactOnRails from 'react-on-rails'
import Modal from 'react-modal'
import NavigateAdminUtils from '../shared/NavigateAdminUtils.js'
import '../views/clinic_changes/clinic_changes.js'
import '../views/clinics/clinics.js'
import '../shared/area_havers.js'
import '../views/user_orgs/user_orgs.js'
import '../views/support_orgs/sorting_table.js'
import '../views/support_orgs/support_orgs.js'

import '../shared/delete_confirm.js'
import '../shared/tabs.js'
import '../shared/drawer_filters.js'
import '../shared/expandadoodle.js'

// React components that need to be registered
import AppointmentAvailabilityInput from '../views/clinics/AppointmentAvailabilityInput.jsx'
import AppointmentAvailabilityPills, {
  AppointmentAvailabilityPill,
  LastUpdatedAtDate,
  SubmitAvailabilityLink,
} from '../views/navigator_clinics/AppointmentAvailabilityPills.jsx'
import BulkEdit from '../views/clinics/BulkEdit.jsx'
import ExpandableAnthology from '../views/navigator_support_orgs/ExpandableAnthology.jsx'
import FellowsPacing from '../views/analytics/FellowsPacing.jsx'
import FilterDrawers from '../shared/index_pages/FilterDrawers.jsx'
import LinkSharingForm from '../shared/link_sharing/LinkSharingForm.jsx'
import NavigatorClinicIndex from '../views/navigator_clinics/NavigatorClinicIndex.jsx'
import OfferingsTree from '../shared/OfferingsTree.jsx'
import NavigatorSupportOrgIndex from '../views/navigator_support_orgs/NavigatorSupportOrgIndex.jsx'
import ProfileIndex from '../views/profiles/ProfileIndex.jsx'
import { RequestEmailUpdate } from '../views/current_user/RequestEmailUpdate.jsx'
import NavigatorResourceRecordIndex from '../views/navigator_resource_records/NavigatorResourceRecordIndex.jsx'
import TableOfYesNoUnknownQuestions from '../views/provider_clinic_submissions/TableOfYesNoUnknownQuestions'
import TelehealthServiceOfferingsInput from '../shared/TelehealthServiceOfferingsInput.jsx'
import UsMap from '../shared/UsMap.jsx'
import WeekAndDayDropdown from '../shared/WeekAndDayDropdown.jsx'
import YesNoUnknownCheckbox from '../shared/YesNoUnknownCheckbox.jsx'
import { CostEntriesAttributes } from '../views/cost_entries/CostEntriesAttributes.jsx'
import { AllUsersChart } from '../views/system_logs/AllUsersChart'
import { ChartWithThresholds } from '../views/system_logs/dashboard_charts'
import { UncontrolledContactType } from '../views/navigator_support_orgs/inputs/ContactType.jsx'

window.tippy = tippy

// you can use data-turbo="true" to enable Drive on a per-element basis.
Turbo.session.drive = false

ReactOnRails.register({
  AllUsersChart,
  AppointmentAvailabilityInput,
  AppointmentAvailabilityPill,
  AppointmentAvailabilityPills,
  BulkEdit,
  ChartWithThresholds,
  CostEntriesAttributes,
  ExpandableAnthology,
  FellowsPacing,
  FilterDrawers,
  LastUpdatedAtDate,
  LinkSharingForm,
  NavigatorClinicIndex,
  NavigatorResourceRecordIndex,
  NavigatorSupportOrgIndex,
  OfferingsTree,
  ProfileIndex,
  RequestEmailUpdate,
  SubmitAvailabilityLink,
  TableOfYesNoUnknownQuestions,
  TelehealthServiceOfferingsInput,
  UncontrolledContactType,
  UsMap,
  WeekAndDayDropdown,
  YesNoUnknownCheckbox,
})

$(document).ready(() => {
  NavigateAdminUtils.initiateAllChosen()
  NavigateAdminUtils.duplicateButtonsInActiveAdminFilterSidebar()
  MicroModal.init()

  // initiate tippy tooltips
  tippy('[data-tippy-content]', {
    theme: 'light-border',
  })

  // setting up React-Modal
  if (document.getElementById('wrapper')) Modal.setAppElement('#wrapper')
  else if (typeof jest === 'undefined')
    // we are not in a test environment
    console.warn("Modal.setAppElement('#wrapper'): no #wrapper div found")
})
