import React from 'react'
import {
  Area,
  AreaChart,
  BarChart,
  Bar,
  ReferenceLine,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from 'recharts' // https://recharts.org/en-US/api
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import './dashboard_charts_styles.scss'

dayjs.extend(utc)

// console.debug('loading js: dashboard_charts.jsx')

export const unixToHumanTime = (tick) =>
  dayjs.unix(tick).utc().format('ddd D-MMM-YYYY')

const tooltipFormatter = (value, name, props, _chart) => {
  return [unixToHumanTime(props.payload.unix_time), `${value} ${name}`]
}

const colors = ['#bbb', '#BDD9B8', '#788C69', '#EDF0E5', '#D9A679', '#734636']

// Renders a bar graph, one bar for each date in 'data'. Also shows threshold markers and a big red
// outline if the second-to-last day is outside thresholds
export const ChartWithThresholds = ({
  data, // Array of time-stamps and values, e.g.
  // [
  //   { unix_time: 1636588741, value: 123 }, ...
  // ]
  expected_range, // optional. min and max values where data is expected to stay.
  // Chart turns red when thresholds are exceeded.
  data_keys = ['value'], // If you want multiple bars per day, pass something like this:
  // data_keys=['foo', 'bar'],
  // data=[
  //   { unix_time: 1636588741, foo: 123, bar: 456 }, ...
  // ]
}) => {
  const extraHtmlClasses = []
  let expectedMin = null
  let expectedMax = null

  if (expected_range) {
    expectedMin = expected_range[0]
    expectedMax = expected_range[1]

    if (data.length < 2) {
      extraHtmlClasses.push('warning-no-data')
    } else {
      const lastPoint = data[data.length - 2].value // The last data point is often incomplete
      if (lastPoint > expectedMax || lastPoint < expectedMin) {
        extraHtmlClasses.push('warning-outside-threshold')
      }
    }
  }

  return (
    <div className={`ChartWithThresholds ${extraHtmlClasses.join(' ')}`}>
      <BarChart width={200} height={75} data={data}>
        <YAxis
          label={{ value: 'views', angle: -90, position: 'insideBottomLeft' }}
        />
        <XAxis label={{ value: 'day', position: 'insideBottom' }} />
        {data_keys.map((data_key, index) => (
          <Bar
            key={data_key}
            dataKey={data_key}
            stroke={colors[index]}
            fill={colors[index]}
            animation={false}
          />
        ))}
        <Tooltip
          isAnimationActive={false}
          separator=" — "
          position={{ x: 0, y: 0 }}
          cursor={{
            stroke: 'none',
            fill: 'rgba(196, 196, 196, 0.2)',
          }}
          contentStyle={{
            backgroundColor: 'rgba(250, 250, 250, 0.8)',
            border: 'none',
            padding: '0',
          }}
          itemStyle={{
            padding: 0,
            margin: 0,
            color: '#404040',
            fontSize: 10,
            lineHeight: 1.5,
          }}
          labelFormatter={() => ''}
          formatter={tooltipFormatter}
        />
        {expectedMin && (
          <ReferenceLine
            y={expected_range[0]}
            stroke="rgba(0, 128, 0, 0.5)"
            strokeDasharray={[8, 4]}
            alwaysShow={true}
          />
        )}
        {expectedMax && (
          <ReferenceLine
            y={expectedMax}
            stroke="rgba(0, 128, 0, 0.5)"
            strokeDasharray={[8, 4]}
            alwaysShow={true}
          />
        )}
      </BarChart>
    </div>
  )
}

export const AllUsersChart = ({ data }) => {
  let userDatumObj = {}
  const userDataArr = []
  let dayCount = 0

  data.forEach((userData) => {
    userData.num_logs_per_day.forEach((userDatum) => {
      userDatumObj = {
        username: userData['user'],
        clinicViews: userDatum['clinic_views'],
      }
      userDataArr.push(userDatumObj)
      dayCount = userData.num_logs_per_day.length
    })
  })

  const chartArr = []
  const areaJSX = []
  for (let i = 0; i < dayCount; i += 1) {
    const emptyObj = {}
    chartArr.push(emptyObj)
    chartArr[i].name = `day_${i}`
    for (let j = 0; j < userDataArr.length; j += 1) {
      const userDatum = userDataArr[j]
      if (j % dayCount === 0 && i === 0) {
        areaJSX.push(
          <Area
            key={userDatum['username']}
            dataKey={userDatum['username']}
            type="monotone"
            stroke="#8884d8"
            fillOpacity="1"
            fill="url(#colorUv)"
          />
        )
        // console.log("this should only happen 7 times")
      }
      if (j % dayCount === i) {
        const keyName = userDatum['username']
        chartArr[i][[keyName]] = userDatum['clinicViews']
      }
    }
  }

  return (
    <div>
      <AreaChart
        width={730}
        height={250}
        data={chartArr}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        {areaJSX}
      </AreaChart>
    </div>
  )
}
