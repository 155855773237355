import React, { useState } from 'react'
import Modal from 'react-modal' // married to scss below
import blackXIcon from '../../../../assets/images/blackX.svg'
import rubyConstants from '../../../ruby_constants.js'
import {
  OrgDateInput,
  OrgInputPlainLabel,
  OrgRadioInput,
  OrgTextInput,
} from '../inputs/OrgInputs.jsx'
import './SecondStatusModal.scss'

const SecondStatusModal = ({
  closeSecondModal,
  currentNavigatorSupportOrg,
  customStyles,
  ogStatus,
  processSave,
  secondModalIsOpen,
  updateNavigatorSupportOrg,
  isAdmin,
}) => {
  const [statusRadioValue, setStatusRadioValue] = useState(null)
  const handleClickSave = () => {
    if (
      currentNavigatorSupportOrg.paused_status_starts_on &&
      ogStatus !== 'active'
    ) {
      // eslint-disable-next-line no-alert
      alert(
        'Paused status cannot be scheduled unless the organization is active. Save it as active first.'
      )
      return
    }
    if (currentNavigatorSupportOrg.paused_status_starts_on)
      updateNavigatorSupportOrg({
        status: ogStatus,
        paused_status_scheduled: statusRadioValue,
      })
    else
      updateNavigatorSupportOrg({
        status: statusRadioValue,
        paused_status_scheduled: null,
      })
    processSave()
  }

  const formattedTomorrow = (() => {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    return date.toISOString().split('T')[0]
  })()

  const statusRadioInputs = Object.entries(
    rubyConstants.SupportOrg.STATUS_VALUES
  )
    .filter(([key]) => key.startsWith('paused'))
    .map(([key, label]) => {
      return (
        <OrgRadioInput
          label={label}
          checked={statusRadioValue === key}
          onSelected={() => setStatusRadioValue(key)}
          key={key}
        />
      )
    })

  const resumeDateIsInPastWarning = (attribute) => {
    if (!currentNavigatorSupportOrg[attribute]) return undefined
    if (
      new Date(currentNavigatorSupportOrg[attribute]).setHours(0, 0, 0, 0) <
      new Date().setHours(0, 0, 0, 0)
    )
      return 'Date must be in the future.'
    return undefined
  }

  const scheduleStatusInputs = (
    <div className="scheduleStatusInputs">
      <OrgInputPlainLabel label="Date Services Pause (Optional)" />
      <div className="paused-status-starts-radio">
        <OrgRadioInput
          onSelected={() =>
            updateNavigatorSupportOrg({ paused_status_starts_on: null })
          }
          checked={!currentNavigatorSupportOrg.paused_status_starts_on}
          label="Now"
        />
        <OrgRadioInput
          onSelected={() =>
            updateNavigatorSupportOrg({
              paused_status_starts_on: formattedTomorrow,
            })
          }
          checked={!!currentNavigatorSupportOrg.paused_status_starts_on}
          label="Scheduled for the future"
        />
      </div>
      <OrgDateInput
        onChange={(val) =>
          updateNavigatorSupportOrg({ paused_status_starts_on: val })
        }
        value={currentNavigatorSupportOrg.paused_status_starts_on || ''}
        disabled={!currentNavigatorSupportOrg.paused_status_starts_on}
        errors={resumeDateIsInPastWarning('paused_status_starts_on')}
        label="" // label is above the radio buttons
      />
      <OrgDateInput
        label="Date Services Resume (Optional)"
        onChange={(val) =>
          updateNavigatorSupportOrg({ paused_status_ends_on: val })
        }
        value={currentNavigatorSupportOrg.paused_status_ends_on || ''}
        errors={resumeDateIsInPastWarning('paused_status_ends_on')}
      />
      {isAdmin && (
        <>
          <OrgTextInput
            label="Website Paused Note (En)"
            value={currentNavigatorSupportOrg.website_paused_note_en}
            onChange={(value) =>
              updateNavigatorSupportOrg({ website_paused_note_en: value })
            }
            isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
          />
          <OrgTextInput
            label="Website Paused Note (Es)"
            value={currentNavigatorSupportOrg.website_paused_note_es}
            onChange={(value) =>
              updateNavigatorSupportOrg({ website_paused_note_es: value })
            }
            isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
          />
        </>
      )}
    </div>
  )

  return (
    <Modal
      isOpen={secondModalIsOpen}
      onRequestClose={closeSecondModal}
      contentLabel="modal label"
      style={customStyles}
    >
      <div className="SecondStatusModal">
        <div className="header">
          <h2>(Optional) Provide &quot;Paused&quot; Details</h2>
          <button
            className="x-button"
            aria-label="close"
            type="button"
            onClick={closeSecondModal}
          >
            <img src={blackXIcon} alt="close" />
          </button>
        </div>
        <OrgInputPlainLabel label="Pause Reason" />
        <div className="statusRadioButtons" key="statusRadioButtons">
          {statusRadioInputs}
        </div>
        {scheduleStatusInputs}
        <div className="modal-buttons">
          <button
            className="blue-button"
            onClick={handleClickSave}
            type="button"
            disabled={
              !statusRadioValue ||
              ['paused_status_starts_on', 'paused_status_ends_on'].some((x) =>
                resumeDateIsInPastWarning(x)
              )
            }
          >
            Done
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default SecondStatusModal
