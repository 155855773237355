import React from 'react'
import PropTypes from 'prop-types'
import ExpandablePoem from '../ExpandablePoem.jsx'
import ExpandableStanza from '../ExpandableStanza.jsx'
import {
  OrgCheckboxInput,
  OrgTextInput,
  OrgSelectInput,
  OrgInputPlainLabel,
} from '../inputs/OrgInputs.jsx'
import RuleEntries from '../inputs/RuleEntries.jsx'

import './PoemEligibility.scss'

const PoemEligibility = ({
  currentNavigatorSupportOrg,
  updateNavigatorSupportOrg,
  expanded,
  toggleExpanded,
  prefix,
  selectOptions,
  fromServerErrors = {},
  isAdmin,
}) => {
  return (
    <ExpandablePoem
      header="Eligibility & Service Area"
      subheader="Eligibility rules, Eligibility note, Additional provider partners, Support organization partners"
      expanded={expanded}
      toggleExpanded={toggleExpanded}
      poemClass="PoemEligibility"
    >
      <ExpandableStanza sidebar="Set guidelines for who is eligible to get support.">
        <OrgInputPlainLabel
          isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
          label={`Eligibility Rules (${
            Object.entries(currentNavigatorSupportOrg.rules).filter(
              ([_id, rule]) => rule
            ).length
          })`}
        />
        <div className="serves-seekers-label">Serves abortion seekers:</div>
        <RuleEntries
          rules={currentNavigatorSupportOrg.rules}
          prefix={`${prefix}[rules_attributes]`}
          selectOptions={selectOptions}
          updateRules={(newRules) =>
            updateNavigatorSupportOrg({ rules: newRules })
          }
          visible={!currentNavigatorSupportOrg.private_support_org}
        />
      </ExpandableStanza>
      <ExpandableStanza sidebar="Please indicate if abortion seekers must have an abortion appointment booked to be eligible for intake.">
        <div className="find-provider">
          <div className="toggles">
            <input
              type="checkbox"
              id="helps_abortion_seekers_find_a_provider"
              checked={
                !!currentNavigatorSupportOrg.known_offerings['referrals']
              }
              onChange={(e) => {
                updateNavigatorSupportOrg({
                  known_offerings: { referrals: e.target.checked },
                })
              }}
            />
            <OrgInputPlainLabel
              label="We can help abortion seekers find a provider."
              isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
              inputId="helps_abortion_seekers_find_a_provider"
            />
          </div>
          <div className="toggles">
            <OrgCheckboxInput
              id="requires_appt_before_intake"
              checked={!!currentNavigatorSupportOrg.requires_appt_before_intake}
              isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
              label="Abortion appointment must already be scheduled before starting intake."
              name={`${prefix}[requires_appt_before_intake]`}
              onChange={(value) => {
                updateNavigatorSupportOrg({
                  requires_appt_before_intake: value,
                })
              }}
            />
          </div>
        </div>
      </ExpandableStanza>
      <ExpandableStanza sidebar="Text included here will be translated into Spanish before it’s publicly displayed on the Abortion Finder platform.">
        <OrgTextInput
          label="Eligibility Note (Optional)"
          name={`${prefix}[website_eligibility_note_en]`}
          value={currentNavigatorSupportOrg.website_eligibility_note_en}
          onChange={(v) =>
            updateNavigatorSupportOrg({ website_eligibility_note_en: v })
          }
          isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
          errors={fromServerErrors.website_eligibility_note_en}
        />
        {isAdmin && (
          <OrgTextInput
            label="Eligibility Note (Optional) - Spanish"
            name={`${prefix}[website_eligibility_note_es]`}
            value={currentNavigatorSupportOrg.website_eligibility_note_es}
            onChange={(v) =>
              updateNavigatorSupportOrg({ website_eligibility_note_es: v })
            }
            isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
            errors={fromServerErrors.website_eligibility_note_es}
          />
        )}
      </ExpandableStanza>
      <ExpandableStanza sidebar="List providers you commonly work with that have not been defined in your organization’s eligibility rules.">
        <OrgSelectInput
          label="Additional Provider Partners (Optional)"
          name={`${prefix}[provider_partner_ids][]`}
          value={currentNavigatorSupportOrg.provider_partner_ids || []}
          onChange={(ids) =>
            updateNavigatorSupportOrg({ provider_partner_ids: ids })
          }
          isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
          options={selectOptions.clinics.map((clinic) => ({
            value: clinic.id,
            label: clinic.name,
          }))}
          isMulti
          errors={fromServerErrors.provider_partner_ids}
        />
      </ExpandableStanza>
      <ExpandableStanza sidebar="List other support organizations you commonly work with.">
        <OrgSelectInput
          label="Support Organization Partners (Optional)"
          name={`${prefix}[commonly_works_with_ids][]`}
          value={currentNavigatorSupportOrg.commonly_works_with_ids}
          onChange={(ids) =>
            updateNavigatorSupportOrg({ commonly_works_with_ids: ids })
          }
          isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
          options={selectOptions.support_orgs}
          isMulti
          errors={fromServerErrors.commonly_works_with_ids}
        />
      </ExpandableStanza>
    </ExpandablePoem>
  )
}

PoemEligibility.propTypes = {
  currentNavigatorSupportOrg: PropTypes.object.isRequired,
  updateNavigatorSupportOrg: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
  selectOptions: PropTypes.object.isRequired,
}

PoemEligibility.openIfErrors = [
  'website_eligibility_note_en',
  'website_eligibility_note_es',
  'provider_partner_ids',
  'rules',
  'commonly_works_with_ids',
]
export default PoemEligibility
