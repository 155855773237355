// convert inputs String "value" OR Array ["label", "value"] OR two arguments (String label, String value)
// to { value: "value", label: "label or value" }
// for consumption by React Select components
export default function toOption(a, b, options = {}) {
  let arr = [a, b].flat().filter(Boolean)
  if (arr.length === 0) arr = ['']

  const label = arr[0].replaceAll('_', ' ').replace(/\w\S*/g, (txt) => {
    // return short strings unchanged
    if (txt.length && txt.length <= 2) return txt
    // otherwise, 'humanize' the value display
    // convert underscores to spaces, and convert to titlecase
    if (options.titlecase) {
      const titleCase =
        txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      const slashedArray = titleCase.split('/')
      const correctCaseSlashArray = slashedArray.map(
        (word) => word.charAt(0).toUpperCase() + word.substr(1)
      )
      return correctCaseSlashArray.join('/')
    }
    return txt
  })

  return { label, value: arr.length === 2 ? arr[1] : arr[0] }
}
