import React from 'react'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import QuestionRow from './QuestionRow'
import './TableOfYesNoUnknownQuestions.scss'

class TableOfYesNoUnknownQuestions extends React.Component {
  static defaultProps = {
    questions: [],
    custom_options: [' Yes ', ' No '],
    is_showing_as_checkboxes: false,
    editable: true,
  }

  constructor(props) {
    super(props)

    const questions = JSON.parse(this.props.questions)

    this.state = {
      questionValues: lodash.fromPairs(
        questions.map((question) => [question.name, question.value])
      ),
    }
  }

  // Return array of props for QuestionRow, but with 'values' taken from 'state.questionValues'
  questionsWithUpdatedValues = () => {
    const questions = JSON.parse(this.props.questions)

    return questions.map((question) =>
      lodash.merge(question, {
        value: this.state.questionValues[question.name],
      })
    )
  }

  onChange = (name, newValue) => {
    this.setState(({ questionValues }) => ({
      questionValues: {
        ...questionValues,
        [name]: newValue,
      },
    }))
  }

  render() {
    const { custom_options, is_showing_as_checkboxes, editable } = this.props

    return (
      // Caller should create the <table> element
      <table className="TableOfYesNoUnknownQuestions">
        <tbody>
          <tr>
            <th>
              <div className="header_yes">{custom_options[0]}</div>
              <div className="header_no">{custom_options[1]}</div>
            </th>
          </tr>
          {this.questionsWithUpdatedValues().map((question) => (
            <QuestionRow
              key={question.name}
              onChange={this.onChange}
              isShowingAsCheckboxes={is_showing_as_checkboxes}
              editable={editable}
              {...question}
            />
          ))}
        </tbody>
      </table>
    )
  }
}

TableOfYesNoUnknownQuestions.propTypes = {
  questions: PropTypes.string,
  custom_options: PropTypes.arrayOf(PropTypes.string),
  is_showing_as_checkboxes: PropTypes.bool,
  editable: PropTypes.bool,
}

export default TableOfYesNoUnknownQuestions
