import {
  IndexState,
  IndexStateConstructorParams,
} from '../../shared/index_pages/IndexState.ts'

export interface NavigatorClinicIndexStateParams
  extends IndexStateConstructorParams {
  isMapView?: boolean
  clickedId?: number | null
}

export class NavigatorClinicIndexState extends IndexState {
  isMapView: boolean

  clickedId: number | null

  constructor({
    isMapView = false,
    clickedId = null,
    ...rest
  }: NavigatorClinicIndexStateParams) {
    super(rest) // Call the parent constructor
    this.isMapView = isMapView
    this.clickedId = clickedId
  }

  // Clone method that returns a new instance with overridden values
  _clone(
    overrides: Partial<NavigatorClinicIndexStateParams>
  ): NavigatorClinicIndexState {
    return new NavigatorClinicIndexState({
      ...this, // Spread the existing instance properties
      ...overrides, // Override with new properties
    })
  }

  // Static method to set map view
  static setMapView(isShown: boolean) {
    return (oldState: NavigatorClinicIndexState) => {
      return oldState._clone({
        isMapView: isShown,
      })
    }
  }

  // Static method to set clicked clinic ID
  static setClickedId(clinicId: number) {
    return (oldState: NavigatorClinicIndexState) => {
      return oldState._clone({
        clickedId: clinicId,
      })
    }
  }
}
