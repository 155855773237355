import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import _uniqueId from 'lodash/uniqueId'
import PropTypes from 'prop-types'
import { OrgSelectInput } from './OrgInputs.jsx'
import EditContactMethodModal from '../modals/EditContactMethodModal.jsx'
import BigNumberedBullet from './BigNumberedBullet.jsx'
import VisibilityIcon from './VisibilityIcon.jsx'
import DraggableIcon from './draggable_icon.svg'
import EditDelete from './EditDelete.jsx'
import PlusButton from './PlusButton.jsx'
import UndraggableIcon from './undraggable_icon.svg'
import rubyConstants from '../../../ruby_constants.js'
import './DraggableContacts.scss'

function moveAndInsert(arr, oldIndex, newIndex) {
  const newArr = [...arr.slice(0, oldIndex), ...arr.slice(oldIndex + 1)]
  newArr.splice(newIndex, 0, arr[oldIndex])
  return newArr
}

const addButtonText = (type) =>
  ({
    phone_numbers: 'Add Phone',
    emails: 'Add Email',
    urls: 'Add Link',
  })[type]

const contactSubtypes = rubyConstants.ContactMethod.CONTACT_SUBTYPES
const contactSubtypeText = (subtype) => `${subtype.replace('_', ' ')} contact`

const typeToClass = (type) =>
  ({
    phone_numbers: 'PhoneNumber',
    emails: 'Email',
    urls: 'Url',
  })[type] || type

const DraggableContacts = ({
  addNewCmButtons,
  prefix,
  contactMethods,
  onChange,
  positionColumn,
  existingLabels, // gonna have to useState this to add labels if edits or news
  defaultContactMethod = {},
  privateSupportOrg,
}) => {
  const [editModal, setEditModal] = useState({ id: null, type: null })
  const emptyContactMethod = (type) => ({
    ...defaultContactMethod,
    type,
  })

  // order contactMethods based on positionColumn, putting those without a position at the end
  const contactMethodsIdsInOrder = Object.entries(contactMethods)
    .filter(([_key, method]) => !method._destroy)
    .sort((a, b) => (a[1][positionColumn] || 99) - (b[1][positionColumn] || 99))
    .map(([id, _method]) => id)

  const draggable = contactMethodsIdsInOrder.length > 1
  const draggedItemPosish = useRef()
  const dragOverItemPosish = useRef()

  const openEditModal = (id, type) =>
    setEditModal({ id, type: typeToClass(type) })
  const closeEditModal = () => setEditModal({ id: null, type: null })

  const dragStart = (e, newPosition) => {
    draggedItemPosish.current = newPosition
  }

  const dragEnter = (e, newPosition) => {
    dragOverItemPosish.current = newPosition
  }

  const reorderAndUpdate = (newContactMethods, newOrder) => {
    newOrder.forEach((id, i) => {
      // eslint-disable-next-line no-param-reassign
      newContactMethods[id][positionColumn] = i + 1 // start position at 1
    })
    onChange(newContactMethods)
  }

  const drop = () => {
    const newOrder = moveAndInsert(
      contactMethodsIdsInOrder,
      draggedItemPosish.current,
      dragOverItemPosish.current
    )
    const newContactMethods = JSON.parse(JSON.stringify(contactMethods)) // deep copy
    reorderAndUpdate(newContactMethods, newOrder)
  }

  const deleteContact = (id) => {
    const newContactMethods = JSON.parse(JSON.stringify(contactMethods)) // deep copy
    newContactMethods[id]._destroy = true

    // gotta reorder
    contactMethodsIdsInOrder.splice(contactMethodsIdsInOrder.indexOf(id), 1)
    reorderAndUpdate(newContactMethods, contactMethodsIdsInOrder)
  }

  const onSaveEditModal = (newContactMethod, isNew) => {
    const newContactMethods = JSON.parse(JSON.stringify(contactMethods)) // deep copy
    if (isNew) {
      // eslint-disable-next-line no-param-reassign
      newContactMethod[positionColumn] = contactMethodsIdsInOrder.length + 1
    }
    newContactMethods[editModal.id] = newContactMethod
    onChange(newContactMethods)
  }

  return (
    <div className="DraggableContacts">
      {editModal.id && (
        <EditContactMethodModal
          contactMethod={
            contactMethods[editModal.id] || emptyContactMethod(editModal.type)
          }
          existingLabels={existingLabels}
          contactSubtypes={contactSubtypes}
          contactSubtypeText={contactSubtypeText}
          closeModal={closeEditModal}
          saveModalData={onSaveEditModal}
          newCM={!contactMethods[editModal.id]}
          privateSupportOrg={privateSupportOrg}
        />
      )}
      {contactMethodsIdsInOrder.length > 0 && (
        <div className="draggable-area" onDragOver={(e) => e.preventDefault()}>
          {contactMethodsIdsInOrder.map((id, i) => {
            const contactMethod = contactMethods[id]
            const posish = contactMethodsIdsInOrder.indexOf(id)
            // add a positionColumn value if this CM was added in the other section
            contactMethod[positionColumn] ||= i + 1
            return (
              <DraggableContact
                key={id}
                cmId={id}
                prefix={prefix}
                position={contactMethod[positionColumn]}
                draggable={draggable}
                contactMethod={contactMethod}
                onDragStart={(e) => dragStart(e, posish)}
                onDragEnter={(e) => dragEnter(e, posish)}
                onDragEnd={drop}
                deleteContact={() => deleteContact(id)}
                openEditModal={() => openEditModal(id, contactMethod.type)}
                privateSupportOrg={privateSupportOrg}
              />
            )
          })}
        </div>
      )}
      {prefix &&
        Object.values(contactMethods).map(
          (contactMethod) =>
            // checking for new for when validators catch submission &
            // we have to generate pseudo ids in the server
            contactMethod.id &&
            contactMethod._destroy && (
              <React.Fragment key={contactMethod.id}>
                <input
                  type="hidden"
                  name={prefix && `${prefix}[${contactMethod.id}][_destroy]`}
                  value={true}
                />
                <input
                  type="hidden"
                  name={prefix && `${prefix}[${contactMethod.id}][id]`}
                  value={contactMethod.id}
                />
              </React.Fragment>
            )
        )}
      <div className="add-new-contact-plus-buttons">
        {addNewCmButtons.map((type) => (
          <PlusButton
            key={type}
            text={addButtonText(type)}
            onClick={() => openEditModal(_uniqueId('new-contact-'), type)}
          />
        ))}
      </div>
    </div>
  )
}

const DraggableContact = ({
  cmId,
  position,
  prefix,
  draggable,
  contactMethod,
  onDragStart,
  onDragEnter,
  onDragEnd,
  deleteContact,
  openEditModal,
  privateSupportOrg,
}) => {
  const [contactMethodStrings, setContactMethodStrings] = useState({})
  // prob will have to check if in the contact part or the other part

  useEffect(() => {
    async function fetchText() {
      const response = await axios
        .post(
          '/internal_api/contact_method',
          { contact_method: contactMethod },
          { responseType: 'json' }
        )
        .catch((err) => console.error(err))
      setContactMethodStrings(response.data)
    }
    if (contactMethod) fetchText()
  }, [contactMethod])

  function contactText() {
    let text = contactMethodStrings.full_label
    if (contactMethod.number) {
      text += `: ${contactMethodStrings.formatted_phone}`
    } else if (contactMethod.email) {
      // email + link specifics in sep ticket
      text += `: ${contactMethod.email}`
    }
    return text
  }

  const isInfoPublic =
    (contactMethod.general || contactMethod.seeker_assistance) &&
    !privateSupportOrg

  // no point in telling the server what errors are on this method
  const contactAttributes = Object.keys(contactMethod).filter(
    (key) => key !== 'errors'
  )

  return (
    <div
      className="DraggableContact"
      draggable={draggable}
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragEnd={onDragEnd}
    >
      {prefix &&
        contactAttributes.map((key) => {
          const name = `${prefix}[${cmId}][${key}]`
          if (Array.isArray(contactMethod[key])) {
            return (
              <OrgSelectInput
                key={`${cmId}-${key}`}
                name={`${name}[]`}
                value={contactMethod[key]}
                options={contactMethod[key]}
                isMulti={true}
                hidden={true}
              />
            )
          }
          return (
            <input
              key={`${cmId}-${key}`}
              type="hidden"
              name={name}
              value={contactMethod[key] === null ? '' : contactMethod[key]}
            />
          )
        })}
      <BigNumberedBullet number={position} />
      <img
        src={draggable ? DraggableIcon : UndraggableIcon}
        alt={`This contact method is ${!draggable ? 'not ' : ''}draggable`}
      />
      <div className="contact-text">
        {contactText()}
        {contactSubtypes.map(
          (subtype) =>
            contactMethod[subtype] && (
              <div key={subtype} className="contact-subtype">
                {contactSubtypeText(subtype)}
              </div>
            )
        )}
      </div>
      <VisibilityIcon isInfoPublic={isInfoPublic} />
      <EditDelete onClickEdit={openEditModal} onClickDelete={deleteContact} />
    </div>
  )
}

DraggableContact.propTypes = {
  position: PropTypes.number.isRequired,
  prefix: PropTypes.string.isRequired,
  draggable: PropTypes.bool.isRequired,
  privateSupportOrg: PropTypes.bool.isRequired,
}

export default DraggableContacts
