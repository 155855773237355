import React from 'react'
import caret_open from './caret_open.svg'
import caret_closed from './caret_closed.svg'
import './ExpandablePoem.scss'

const ExpandablePoem = ({
  header,
  subheader,
  expanded,
  toggleExpanded,
  poemClass = '',
  children,
}) => {
  const expandedClass = expanded ? 'expanded' : 'collapsed'
  return (
    <div className={`ExpandablePoem ${poemClass}`}>
      <div
        className={`expandable-header ${expandedClass}`}
        onClick={toggleExpanded}
      >
        <img
          className={`carat ${expandedClass}`}
          src={expanded ? caret_open : caret_closed}
          alt={expanded ? 'open' : 'closed'}
        />
        <div className="header">{header}</div>
        <div className="subheader">{subheader}</div>
      </div>

      <div className={`expandable-stanzas ${expandedClass}`}>{children}</div>
    </div>
  )
}

export default ExpandablePoem
