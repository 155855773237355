import React from 'react'
import PropTypes from 'prop-types'
import { TooltipAnchor, Tooltip } from '../Tooltip.jsx'
import { getCsrfToken } from '../../utilities/utilities.ts'

import starFilledIcon from '../../../assets/images/star-filled.svg'
import starEmptyIcon from '../../../assets/images/star-regular.svg'
import './StarUnstarButton.scss'

const StarUnstarButton = ({
  recordType,
  recordId,
  isStarred,
  setIsStarred,
}) => {
  const onToggle = async () => {
    try {
      const response = await fetch('/current_user/toggle_favorite', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCsrfToken(),
        },
        body: JSON.stringify({ record: { type: recordType, id: recordId } }),
      })

      if (response.ok) {
        const data = await response.json()
        setIsStarred(!!data.is_now_starred)
      } else {
        throw new Error(`Error: ${response.status} ${response.statusText}`)
      }
    } catch (error) {
      throw new Error(`Error: ${error.message}`)
    }
  }

  return (
    <TooltipAnchor>
      <button className="StarUnstarButton" onClick={onToggle} type="button">
        {isStarred ? (
          <img
            src={starFilledIcon}
            alt="this record is starred; click to unstar"
          />
        ) : (
          <img
            src={starEmptyIcon}
            alt="this record is not starred; click to star"
          />
        )}
      </button>
      <Tooltip>{isStarred ? 'Unstar' : 'Star'}</Tooltip>
    </TooltipAnchor>
  )
}

StarUnstarButton.propTypes = {
  recordType: PropTypes.string.isRequired,
  recordId: PropTypes.number.isRequired,
}

export default StarUnstarButton
