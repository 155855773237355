import React from 'react'
import PropTypes from 'prop-types'
import ExpandablePoem from '../ExpandablePoem.jsx'
import ExpandableStanza from '../ExpandableStanza.jsx'
import { OrgTextInput, OrgInputPlainLabel } from '../inputs/OrgInputs.jsx'
import NavigatorReferralContacts from '../inputs/NavigatorReferralContacts.jsx'
import './PoemInstructions.scss'

const PoemInstructions = ({
  currentNavigatorSupportOrg,
  expanded,
  prefix,
  selectOptions,
  toggleExpanded,
  updateNavigatorSupportOrg,
  isAdmin,
  fromServerErrors = {},
}) => (
  <ExpandablePoem
    header="Instructions to Get Assistance"
    subheader={
      <span>
        For abortion seekers, For patient navigator (<i>Navigate</i> users)
        referrals
      </span>
    }
    expanded={expanded}
    toggleExpanded={toggleExpanded}
    poemClass="PoemInstructions"
  >
    <ExpandableStanza sidebar="Tell abortion seekers how to access your services. Text included here will be translated into Spanish before it’s publicly displayed on the Abortion Finder platform.">
      <OrgTextInput
        label="For Abortion Seekers"
        name={`${prefix}[abortion_seeker_instructions_en]`}
        value={currentNavigatorSupportOrg.abortion_seeker_instructions_en}
        onChange={(v) =>
          updateNavigatorSupportOrg({ abortion_seeker_instructions_en: v })
        }
        isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
        errors={fromServerErrors.abortion_seeker_instructions_en}
      />
      {isAdmin && (
        <OrgTextInput
          label="For Abortion Seekers - Spanish"
          name={`${prefix}[abortion_seeker_instructions_es]`}
          value={currentNavigatorSupportOrg.abortion_seeker_instructions_es}
          onChange={(v) =>
            updateNavigatorSupportOrg({ abortion_seeker_instructions_es: v })
          }
          isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
          errors={fromServerErrors.abortion_seeker_instructions_es}
        />
      )}
    </ExpandableStanza>
    <ExpandableStanza
      sidebar={
        <span>
          Tell patient <i>navigator</i> Navigate users if they can refer
          abortion seekers to your organization and what process to follow to do
          so.
        </span>
      }
    >
      <OrgTextInput
        label="For Referrals (Optional)"
        name={`${prefix}[referrals_note]`}
        value={currentNavigatorSupportOrg.referrals_note}
        onChange={(v) => updateNavigatorSupportOrg({ referrals_note: v })}
        isInfoPublic={false}
        errors={fromServerErrors.referrals_note}
      />
      <div className="divider" />
      <OrgInputPlainLabel label="Navigator Referral Contact (Optional)" />
      <NavigatorReferralContacts
        currentNavigatorSupportOrg={currentNavigatorSupportOrg}
        updateNavigatorSupportOrg={updateNavigatorSupportOrg}
        existingLabels={selectOptions.contact_methods_existing_labels}
      />
    </ExpandableStanza>
  </ExpandablePoem>
)

PoemInstructions.propTypes = {
  currentNavigatorSupportOrg: PropTypes.object.isRequired,
  updateNavigatorSupportOrg: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
  selectOptions: PropTypes.object.isRequired,
}

PoemInstructions.openIfErrors = [
  'abortion_seeker_instructions_en',
  'abortion_seeker_instructions_es',
  'referrals_note',
]
export default PoemInstructions
