import { AsYouType } from 'libphonenumber-js'

const phoneNumberFormatter = (value, oldPhoneValue) => {
  if (!value) return ''

  const format = (str) => new AsYouType('US').input(str)

  // this is here to deal with backspacing, going from '(345)' to '(345' should delete the 5
  if (/\(\d{3}$/.test(value)) return format(oldPhoneValue.slice(0, -2))
  return format(value)
}

export default phoneNumberFormatter
