import React from 'react'
import _uniqueId from 'lodash/uniqueId'
import AreaInput from '../../shared/AreaInput.jsx'
import { OrgSelectInput } from '../navigator_support_orgs/inputs/OrgInputs.jsx'
import PlusButton from '../navigator_support_orgs/inputs/PlusButton.jsx'
import toOption from '../../utilities/to_option.js'
import rubyConstants from '../../ruby_constants.js'
import usStates from '../../utilities/us_states.ts'
import travelArrowhead from './travel_arrowhead.svg'
import OfferingCheckbox from '../../shared/OfferingCheckbox.jsx'

import './RuleInput.scss'

const usStateOptions = Object.entries(usStates)
  .slice(1) // remove 'National'
  .map((ele) => toOption(ele.reverse()))

export const buildArea = (
  ruleToOrFrom,
  subRuleType = 'anywhere',
  state = ''
) => ({
  cities: [],
  regions: [],
  clinic_ids: [],
  state: subRuleType === 'anywhere' ? state || 'National' : state,
  rule_to_or_from: ruleToOrFrom,
  coverage_type:
    rubyConstants.Rule.RULE_TYPE_TO_AREA_COVERAGE_TYPE[subRuleType],
})

const RuleInput = ({
  rule,
  citiesByState,
  regionsByState,
  clinicsByState,
  updateRule,
  isRuleTypeDisabled,
}) => {
  const ruleTypeOptions = rubyConstants.Rule.RULE_TYPES

  // disable a "to" state if there are no clinics in that state
  const stateHasNoClinics = (state) => !clinicsByState[state]

  const destroyed = (obj) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        key,
        { ...value, _destroy: true },
      ])
    )

  const ruleTypeChanged = (value) =>
    updateRule({
      rule_type: value,
      // clear existing from_areas and to_areas, and add a new anywhere area
      from_areas: {
        ...destroyed(rule.from_areas),
        [_uniqueId('new-from-area')]: buildArea('from', value.split('_to_')[0]),
      },
      to_areas: {
        ...destroyed(rule.to_areas),
        [_uniqueId('new-to-area')]: buildArea('to', value.split('_to_')[1]),
      },
    })

  const addNewArea = (direction, subRuleType) =>
    updateRule({
      [`${direction}_areas`]: {
        [_uniqueId('new-ar')]: buildArea(direction, subRuleType),
      },
    })

  const [fromType, toType] = rule.rule_type.split('_to_')

  return (
    <div className="RuleInput">
      {/* uncomment this for debugging */}
      {/* <pre>rule: {JSON.stringify(rule, undefined, 4)}</pre> */}
      <OrgSelectInput
        label="Rule Type"
        value={rule.rule_type}
        onChange={ruleTypeChanged}
        options={ruleTypeOptions.map((rule_type_string) =>
          toOption(rule_type_string, null, { titlecase: true })
        )}
        isValueDisabled={isRuleTypeDisabled}
        expandsOnOpen
      />

      <div className="divider" />

      {rule.rule_type &&
        [
          ['from', fromType, rule.from_areas],
          ['to', toType, rule.to_areas],
        ].map(([dir, subType, areas]) => (
          <div key={dir} className="from-or-to-container">
            <div className="traveling">
              <h3>{`Traveling ${dir.replace(/^\w/, (c) =>
                c.toUpperCase()
              )}`}</h3>
            </div>
            {dir === 'from' && (
              <div className="arrow">
                <img src={travelArrowhead} alt="" />
              </div>
            )}

            <div
              className={`from-or-to-areas ${dir}`}
              key={`from-or-to-areas ${dir}`}
            >
              {subType === 'states' ? (
                <OrgSelectInput
                  onChange={(states) => {
                    // states were either added or removed
                    const statesBeforeChange = Object.values(areas)
                      .filter((x) => !x._destroy)
                      .map((area) => area.state)
                    // if a state was added, add a new area for that state
                    states.forEach((state) => {
                      if (!statesBeforeChange.includes(state))
                        updateRule({
                          [`${dir}_areas`]: {
                            [_uniqueId('new-anywhere-area-')]: buildArea(
                              dir,
                              'anywhere',
                              state
                            ),
                          },
                        })
                    })
                    // if a state was removed, add _destroy to the corresponding area
                    Object.entries(areas).forEach(([key, area]) => {
                      if (!states.includes(area.state))
                        updateRule({
                          [`${dir}_areas`]: { [key]: { _destroy: true } },
                        })
                    })
                  }}
                  options={usStateOptions}
                  value={Object.values(areas)
                    .filter((x) => !x._destroy)
                    .map((a) => a.state)
                    .filter((x) => x)}
                  isValueDisabled={(stt) =>
                    dir === 'to' && stateHasNoClinics(stt)
                  }
                  label={`${dir} states`}
                  labelVisuallyHidden={true}
                  isMulti
                />
              ) : (
                Object.entries(areas)
                  .filter(([_id, area]) => area && !area._destroy)
                  .map(([areaId, area], index) => (
                    <AreaInput
                      key={areaId}
                      area={area}
                      citiesByState={citiesByState}
                      regionsByState={regionsByState}
                      clinicsByState={clinicsByState}
                      includeNational={subType === 'anywhere'}
                      hideCoverageType={true}
                      disableRemoveStateButton={Object.keys(areas).length === 1}
                      isStateDisabled={(state) =>
                        dir === 'to' && stateHasNoClinics(state)
                      }
                      updateArea={(updatedArea) =>
                        updateRule({
                          [`${dir}_areas`]: { [areaId]: updatedArea },
                        })
                      }
                      stateLabel={`${dir} state ${index + 1}`}
                    />
                  ))
              )}
              {subType !== 'anywhere' && subType !== 'states' && (
                <PlusButton
                  text={`Add "${dir.toUpperCase()}" state`}
                  onClick={() => addNewArea(dir, subType)}
                />
              )}

              {dir === 'from' && <div className="divider" />}
            </div>
          </div>
        ))}
      {rule.rule_type !== 'anywhere_to_providers' &&
        rule.rule_type !== 'states_to_providers' &&
        rule.rule_type !== 'state_regions_to_providers' && (
          <div>
            <OfferingCheckbox
              label="Includes virtual-only providers"
              onChange={(e) =>
                updateRule({
                  includes_virtual_only_providers: e.target.checked,
                })
              }
              checked={!!rule.includes_virtual_only_providers}
            />
          </div>
        )}
    </div>
  )
}

export default RuleInput
