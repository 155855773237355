window.delete_confirm = {
  // On ActiveAdmin show pages, add a confirmation dialog before delete actions
  install_delete_confirmation: (delete_confirm_message) => {
    // eslint-disable-next-line func-names
    $('.action_item a[data-method=delete]').each(function () {
      // console.log('delete confirmation installed on', this)
      $(this).data('confirm', delete_confirm_message)
    })
  },
}
