import { queryString } from './index_pages/fetch_results_from_server.js'
import { objectCompactBlank } from '../utilities/utilities.ts'

const compactQueryString = (params) => queryString(objectCompactBlank(params))

const pushUrlHistory = (baseUrl, params) => {
  const url = `${baseUrl}?${compactQueryString(params)}`
  window.history.pushState({ url }, '', url)
}

export const usePushUrlHistory = (baseUrl, params, { disabled = false }) => {
  return (newParams) => {
    if (disabled) return
    pushUrlHistory(baseUrl, { ...params, ...newParams })
  }
}
