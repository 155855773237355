import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import _uniqueId from 'lodash/uniqueId'
import { deepMerge } from '../../utilities/compare_and_merge.js'
import { CostEntryForm } from './CostEntryForm.jsx'
import './CostEntriesAttributes.scss'

const CostEntriesAttributes = ({ cost_entries, prefix, options, errors }) => {
  const [costEntries, updateCostEntries] = useReducer(
    deepMerge,
    JSON.parse(JSON.stringify(cost_entries)) // initial value (deep copy)
  )

  const costEntriesToShow = Object.entries(costEntries).filter(
    ([, entry]) => !entry._destroy || entry.id
  )

  const addNewCostEntry = () =>
    updateCostEntries({
      [_uniqueId('newCostEntry-')]: {
        prices: { [_uniqueId('newPrice-')]: {} },
      },
    })

  return (
    <div className="CostEntriesAttributes">
      {costEntriesToShow.map(([key, costEntry], i) => (
        <CostEntryForm
          costEntry={costEntry}
          entryIndex={i}
          key={key}
          options={options}
          prefix={`${prefix}[${key}]`}
          updateCostEntry={(newCE) => updateCostEntries({ [key]: newCE })}
          errors={errors}
        />
      ))}
      <button type="button" onClick={addNewCostEntry}>
        Add New Cost Entry
      </button>
    </div>
  )
}

CostEntriesAttributes.propTypes = {
  cost_entries: PropTypes.objectOf(
    PropTypes.shape({
      label_id: PropTypes.number,
      whats_included_ids: PropTypes.arrayOf(PropTypes.number),
      prices: PropTypes.objectOf(PropTypes.shape({ id: PropTypes.number })),
    })
  ).isRequired,
  // errors: PropTypes.object.isRequired,
  options: PropTypes.shape({
    label: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired, // this is the tag id
        label: PropTypes.string.isRequired,
      })
    ),
    whats_included: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired, // tag id
        label: PropTypes.string.isRequired,
        display_for_tag_ids: PropTypes.arrayOf(PropTypes.number),
      })
    ),
  }).isRequired,
  prefix: PropTypes.string.isRequired,
}

export { CostEntriesAttributes }
