window.tabs = {
  activateTabs: () => {
    const tabContentsFromTab = (tab) => {
      const tabContentId = `${tab.id.replace('tab_', '')}`
      const tabContent = document.getElementById(tabContentId)

      if (tabContent) return tabContent
      console.error(
        `Could not find tab content for ${tab.id}. Expected to find a div with id: ${tabContentId}`
      )
      return undefined
    }

    const activateTab = (tab) => {
      tab.parentNode.querySelectorAll('.tab').forEach((tabElem) => {
        tabElem.classList.remove('active')
        tabContentsFromTab(tabElem).classList.remove('active')
      })
      tab.classList.add('active')
      tabContentsFromTab(tab).classList.add('active')
    }

    document.querySelectorAll('.tab').forEach((tab) => {
      // don't set click event unless > 1 tab
      if (tab.parentNode.querySelectorAll('.tab').length < 2) return

      tab.style.cursor = 'pointer' // eslint-disable-line no-param-reassign

      // eslint-disable-next-line no-param-reassign
      tab.onclick = (e) => {
        const target = e.target.closest('.tab') // we may be clicking on an <i> element within the tab; git the actual tab
        window.history.pushState({}, '', `#${target.id}`)
        activateTab(target)
      }
    })

    // on initial activation, iterate through all the tab containers
    const tabIdFromUrl = window.location.hash.slice(1) // slice(1) to remove the leading '#'
    document.querySelectorAll('.tabs').forEach((tabContainer) => {
      const tabClickableElements = tabContainer.querySelectorAll('.tab')
      if (tabClickableElements.length === 0) return

      const tabIds = Array.from(tabClickableElements).map((elem) => elem.id)

      // if the URL contains a tab, e.g., /admin/navigator_clinics/110808#tab_non_abortion_services
      // then activate that tab if it is among the tabs in this container
      if (tabIds.includes(tabIdFromUrl))
        activateTab(tabContainer.querySelector(`#${tabIdFromUrl}`))
      // otherwise, activate the first tab to show its contents
      else activateTab(tabClickableElements[0])
    })
  },
}
