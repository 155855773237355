import React from 'react'
import PropTypes from 'prop-types'
import CancelModal from './CancelModal.jsx'

const CancelEditModalModal = ({ isOpen = true, confirmModal, closeModal }) => {
  return (
    <CancelModal
      customStyles={{ content: { width: '400px' } }}
      header="Discard Edits"
      body={
        <>
          There are unsaved edits on this screen.
          <br />
          Are you sure you want to discard your edits?
        </>
      }
      cancelButtonText="No, keep edits"
      confirmButtonText="Yes, discard edits"
      isOpen={isOpen}
      confirmModal={confirmModal}
      closeModal={closeModal}
    />
  )
}

CancelModal.propTypes = {
  isOpen: PropTypes.bool,
  confirmModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default CancelEditModalModal
