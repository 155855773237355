import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import blueXIcon from '../../../assets/images/light-blue-x.svg'
import blueCaretIcon from '../../../assets/images/blue-caretdown.svg'
import './FilterDrawerWrapper.scss'

const FilterDrawerWrapper = ({
  children,
  className,
  isOpen,
  onApply,
  onClear,
  onOpen,
  onReset,
  title,
  showInnerButtons,
  extraClass = '',
}) => {
  const wrapperRef = useRef(null)

  useEffect(() => {
    // When a user clicks outside this drawer, the drawer should close
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onReset(true)
      }
    }

    // Add the click event listener
    if (isOpen) document.addEventListener('mousedown', handleClickOutside)

    // Return function to remove the click event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  return (
    <div
      className={`FilterDrawerWrapper ${isOpen ? 'open' : ''} ${className}`}
      ref={wrapperRef}
    >
      <button
        type="button"
        onClick={() => (isOpen ? onReset(true) : onOpen())}
        className={`drawer-handle ${extraClass} ${isOpen ? 'open' : ''}`}
      >
        {title}
        <img src={blueCaretIcon} alt="" />
      </button>

      {isOpen && (
        <div className="filters-dropdown">
          <div className="top-o-the-dropdown">
            {showInnerButtons && (
              <>
                <div className="title">{title}</div>
                <button
                  className="close-drawer"
                  type="button"
                  onClick={onReset}
                >
                  <img src={blueXIcon} alt="Close" />
                </button>
              </>
            )}
            <div className="open-drawer-contents">{children}</div>
          </div>
          <hr />
          <div className="top-o-the-dropdown no-top-padding">
            {showInnerButtons && (
              <div className="clear-n-submit">
                <button
                  type="button"
                  onClick={onClear}
                  className="clear-filter"
                >
                  Clear
                </button>
                <button
                  type="button"
                  value="Apply Filters"
                  className="apply-filter"
                  onClick={onApply}
                >
                  Apply Filters
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

FilterDrawerWrapper.defaultProps = {
  showInnerButtons: true,
  onApply: undefined,
}

FilterDrawerWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClear: PropTypes.func.isRequired,
  onApply: (props, propName, componentName) => {
    if (
      props.showInnerButtons !== false &&
      typeof props.onApply !== 'function'
    ) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. If showInnerButtons is not false, onApply must be provided and be a function.`
      )
    }
    return null
  },
  showInnerButtons: PropTypes.bool,
}

export default FilterDrawerWrapper
