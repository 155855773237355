import React from 'react'
import './MapViewWarningCard.scss'

const MapViewWarningCard = ({ shown, onClickDismiss, onClickSetMapView }) => {
  if (!shown) return null

  return (
    <div className="MapViewWarningCard">
      Telehealth-only options are hidden on map view.
      <div className="buttons">
        <button type="button" onClick={onClickDismiss}>
          Dismiss
        </button>
        <button type="button" onClick={onClickSetMapView}>
          See List View
        </button>
      </div>
    </div>
  )
}
export default MapViewWarningCard
