// update state based on Active Admin Collection Select checkboxes
// external to the component

import { useEffect } from 'react'

const collectionSelectionSelector =
  'input.collection_selection[type="checkbox"]'

const disablePrivateClinicCheckboxes = () => {
  // Used on Navigator facing pages for LinkSharing (send to phone) feature
  // Private clinics cannot be texted to user, and thus should not be selectable
  // code in admin/navigator_clinics.rb adds the .private-clinic class to these rows
  const privateRecordsRows = Array.from(
    document.querySelectorAll('tr.private-record')
  )

  privateRecordsRows.forEach((tr) => {
    // eslint-disable-next-line no-param-reassign
    tr.querySelector(collectionSelectionSelector).disabled = true
  })

  const tippyFunc = process.env.NODE_ENV === 'test' ? () => null : window.tippy

  if (tippyFunc) {
    tippyFunc(
      'tr.private-record td.col-selectable div.resource_selection_cell',
      {
        content: 'This is a private record and cannot be shared',
        arrow: true,
        placement: 'left',
        theme: 'light-border',
        delay: 300,
      }
    )
  } else if (process.env.NODE_ENV !== 'test') {
    console.error('tippy not found')
  }
}

const uncheckPrivateClinicCheckboxes = () => {
  // when the collection_selection_toggle_all checkbox is checked,
  // we quickly uncheck the (disabled) private clinic rows
  const disabledCheckboxes = Array.from(
    document.querySelectorAll(`${collectionSelectionSelector}:disabled`)
  )

  disabledCheckboxes.forEach((checkbox) => {
    checkbox.closest('tr').classList.remove('selected')
    checkbox.checked = false // eslint-disable-line no-param-reassign
  })
}

export const getCheckedCollectionSelectionIds = () =>
  Array.from(
    document.querySelectorAll(`${collectionSelectionSelector}:checked`)
  ).map((checkbox) => parseInt(checkbox.value))

// callback should be a function that accepts an array of ids (of the checked checkboxes)
// callback will be invoked each time a checkbox changes
export const useCollectionSelectionCheckboxes = (callback) => {
  // use UseEffect to run this setup function only once when the component loads
  useEffect(() => {
    const recordCheckboxes = Array.from(
      document.querySelectorAll(collectionSelectionSelector)
    )
    const toggleAllCheckbox = document.getElementById(
      'collection_selection_toggle_all'
    )

    disablePrivateClinicCheckboxes()

    const sendIdsToParent = () => callback(getCheckedCollectionSelectionIds())
    const handleToggleAll = () => {
      uncheckPrivateClinicCheckboxes()
      sendIdsToParent()
    }

    // Bind the event listeners
    recordCheckboxes.forEach((checkbox) =>
      checkbox.addEventListener('change', sendIdsToParent)
    )
    toggleAllCheckbox.addEventListener('change', handleToggleAll)

    return () => {
      // Unbind the event listeners on clean up
      recordCheckboxes.forEach((checkbox) =>
        checkbox.removeEventListener('change', sendIdsToParent)
      )
      toggleAllCheckbox.removeEventListener('change', handleToggleAll)
    }
  }, [])
}

export const setCheckedCollectionSelectionIds = (ids) =>
  Array.from(document.querySelectorAll(collectionSelectionSelector)).forEach(
    (checkbox) => {
      if (ids.includes(parseInt(checkbox.value))) {
        checkbox.checked = true // eslint-disable-line no-param-reassign
        checkbox.closest('tr').classList.add('selected')
      } else {
        checkbox.checked = false // eslint-disable-line no-param-reassign
        checkbox.closest('tr').classList.remove('selected')
      }
    }
  )
