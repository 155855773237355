import React from 'react'
import { OrgArrayCheckboxInput } from '../navigator_support_orgs/inputs/OrgInputs.jsx'

const FilterDrawerExpandableCheckboxes = ({
  groupedOptions,
  value = [],
  onChange,
}) => {
  const handleGroupToggle = (groupKey, groupValues) => {
    let newValues
    if (value.includes(groupKey)) {
      // If groupKey is already in value, remove it and all its groupValues
      newValues = value.filter(
        (val) => !groupValues.includes(val) && val !== groupKey
      )
    } else {
      // If groupKey is not in value, add it
      newValues = [...value, groupKey]
    }
    onChange(newValues)
  }

  const handleCheckboxChange = (newValue) => {
    onChange(newValue)
  }

  return (
    <div className="drawer-checkbox-filter expandable">
      {groupedOptions.map(([[groupLabel, groupKey], ...options]) => {
        const groupValues = options.map((option) => option[1])
        const isAnyCheckboxInGroupChecked = groupValues.some((val) =>
          value.includes(val)
        )
        const isGroupExpanded =
          value.includes(groupKey) || isAnyCheckboxInGroupChecked

        return (
          <div key={groupKey} className="expandable-section">
            <div className="toggles">
              <input
                type="checkbox"
                id={groupKey}
                checked={isGroupExpanded}
                onChange={() => handleGroupToggle(groupKey, groupValues)}
              />
              <label htmlFor={groupKey}>{groupLabel}</label>
            </div>

            <div
              className={`checkbox-container ${isGroupExpanded ? 'open' : ''}`}
            >
              <OrgArrayCheckboxInput
                options={options}
                value={value}
                onChange={handleCheckboxChange}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default FilterDrawerExpandableCheckboxes
