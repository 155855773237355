/* eslint camelcase: 0 */
/* eslint no-restricted-syntax: 0 */
/* eslint jsx-a11y/anchor-is-valid: 0 */
// set no-restricted-syntax here - change lint rules or merge method needs to be refactored
// change anchor links to buttons?
import React, { useState, useReducer, useDeferredValue } from 'react'
import { isEqual } from 'lodash'
import { deepMerge } from '../../utilities/compare_and_merge.js'
import PoemAssistanceOffered from './poems/PoemAssistanceOffered.jsx'
import '../../stylesheets/ReactModalPortal.scss' // married to jsx above
import PoemBasic from './poems/PoemBasic.jsx'
import PoemContact from './poems/PoemContact.jsx'
import PoemEligibility from './poems/PoemEligibility.jsx'
import PoemInstructions from './poems/PoemInstructions.jsx'
import PoemAdminOnly from './poems/PoemAdminOnly.jsx'
import StatusChangeModals from './modals/StatusChangeModals.jsx'
import CancelModal from './modals/CancelModal.jsx'
import './ExpandableAnthology.scss'

const redirectBrowser = (path) => {
  window.location.href = path
}

const expandablePoems = [
  PoemBasic,
  PoemContact,
  PoemEligibility,
  PoemAssistanceOffered,
  PoemInstructions,
  PoemAdminOnly,
]

/* Used by:
   - admin/navigator_support_orgs.rb
   - admin/support_orgs.rb */

const ExpandableAnthology = ({
  navigator_support_org, // the initial state of the org profile (as stored in the DB)
  cancelButtonPath,
  prefix,
  selectOptions = {},
  offeringsTreeData,
  fromServerErrors = {},
  is_admin,
  submitButtonText = 'Save',
}) => {
  const initialOpenPoems = expandablePoems.filter(
    (Poem) =>
      Poem.openIfErrors.filter((attr) => fromServerErrors[attr]).length > 0
  )
  const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false)
  const [statusModalIsOpen, setStatusModalIsOpen] = useState(false)
  const [expandedSections, setExpandedSections] = useState(initialOpenPoems)
  const [currentNavigatorSupportOrg, updateNavigatorSupportOrg] = useReducer(
    deepMerge,
    JSON.parse(JSON.stringify(navigator_support_org)) // initial value (deep copy)
  )

  // when submitting the form from within the StatusChangeModals,
  // we need to first re-render the form elements with their proper values
  // (with e.g. website_paused_note, services_resume_on are set to null)
  // then submit the form
  const [submitForm, setSubmitForm] = useState(false)
  const submitFormNow = useDeferredValue(submitForm)
  if (submitFormNow) document.forms[0].submit()

  /* conditionals */
  const navigatorSupportOrgChanged = // return true to enable save button if a new support org
    !navigator_support_org.id ||
    !isEqual(navigator_support_org, currentNavigatorSupportOrg)
  const pageHeader = navigator_support_org.id
    ? `Edit ${is_admin ? navigator_support_org.name : 'My Organization'}`
    : 'New Support Organization'

  /* handlers */
  const expandAll = () => {
    setExpandedSections(expandablePoems)
  }

  const collapseAll = () => {
    setExpandedSections([])
  }

  const toggleExpanded = (poemCompoment) => {
    let removedSections
    if (expandedSections.indexOf(poemCompoment) >= 0) {
      removedSections = [...expandedSections]
      removedSections.splice(removedSections.indexOf(poemCompoment), 1)
      setExpandedSections(removedSections)
    } else {
      setExpandedSections([...expandedSections, poemCompoment])
    }
  }

  const openCancelModalIfChanges = (e) => {
    if (navigatorSupportOrgChanged) {
      e.preventDefault()
      setCancelModalIsOpen(true)
    }
  }

  const processSave = () => {
    if (
      navigator_support_org.status !== currentNavigatorSupportOrg.status &&
      !statusModalIsOpen
    ) {
      setStatusModalIsOpen(true)
      return
    }

    // TODO - don't erase these things if scheduled
    if (currentNavigatorSupportOrg.status === 'active') {
      updateNavigatorSupportOrg({
        paused_status_ends_on: null,
        website_paused_note_en: null,
        website_paused_note_es: null,
      })
    }

    setSubmitForm(true)
  }

  return (
    <div id="ExpandableAnthology">
      <CancelModal
        header="Heads Up!"
        body={
          <>
            <b>There are unsaved edits on this screen</b>
            <br />
            Do you want to discard these edits?
          </>
        }
        cancelButtonText="No, Keep Editing"
        confirmButtonText="Yes, Discard Edits"
        isOpen={cancelModalIsOpen}
        closeModal={() => setCancelModalIsOpen(false)}
        confirmModal={() => redirectBrowser(cancelButtonPath)}
      />
      {currentNavigatorSupportOrg && (
        <StatusChangeModals
          closeModal={() => setStatusModalIsOpen(false)}
          currentNavigatorSupportOrg={currentNavigatorSupportOrg}
          IsOpen={statusModalIsOpen}
          ogPausedStatusEndsOn={navigator_support_org.paused_status_ends_on}
          ogPausedStatusStartsOn={navigator_support_org.paused_status_starts_on}
          ogStatus={navigator_support_org.status}
          processSave={processSave}
          updateNavigatorSupportOrg={updateNavigatorSupportOrg}
          isAdmin={is_admin}
        />
      )}
      <fieldset className="actions top">
        <BookendBar
          pageHeader={pageHeader}
          expandAll={expandAll}
          collapseAll={collapseAll}
          submitButtonText={submitButtonText}
          navigatorSupportOrgChanged={navigatorSupportOrgChanged}
          cancelButtonPath={cancelButtonPath}
          openCancelModalIfChanges={openCancelModalIfChanges}
          processSave={processSave}
        />
      </fieldset>

      <div className="poems-container">
        {expandablePoems.map((Poem) => (
          <Poem
            currentNavigatorSupportOrg={currentNavigatorSupportOrg}
            updateNavigatorSupportOrg={updateNavigatorSupportOrg}
            expanded={expandedSections.indexOf(Poem) >= 0}
            toggleExpanded={() => toggleExpanded(Poem)}
            prefix={prefix}
            selectOptions={selectOptions}
            key={Poem.name}
            offeringsTreeData={offeringsTreeData}
            fromServerErrors={fromServerErrors}
            isAdmin={is_admin}
          />
        ))}
      </div>
      <div>
        <fieldset className="actions bottom">
          <BookendBar
            expandAll={expandAll}
            collapseAll={collapseAll}
            submitButtonText={submitButtonText}
            navigatorSupportOrgChanged={navigatorSupportOrgChanged}
            cancelButtonPath={cancelButtonPath}
            openCancelModalIfChanges={openCancelModalIfChanges}
            processSave={processSave}
          />
        </fieldset>
      </div>
    </div>
  )
}

const BookendBar = ({
  pageHeader = '',
  expandAll,
  collapseAll,
  submitButtonText,
  navigatorSupportOrgChanged,
  cancelButtonPath,
  openCancelModalIfChanges,
  processSave,
}) => (
  <div className="BookendBar">
    <h1>{pageHeader}</h1>

    <div className="expand-collapse-all">
      <a href="#" onClick={expandAll}>
        Expand All
      </a>
      |
      <a href="#" onClick={collapseAll}>
        Collapse All
      </a>
    </div>

    <div className="submit-cancel">
      <input
        type="button"
        name="commit"
        value={submitButtonText}
        disabled={!navigatorSupportOrgChanged}
        data-disable-with="Save"
        onClick={processSave}
      />
      <a
        href={cancelButtonPath}
        onClick={openCancelModalIfChanges}
        className="cancel"
      >
        Cancel
      </a>
    </div>
  </div>
)

export default ExpandableAnthology
