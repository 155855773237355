import React from 'react'
import { TooltipAnchor, Tooltip } from '../../../shared/Tooltip.jsx'
import './EditDelete.scss'

const EditDeleteButton = ({ type, disabled, tooltip, onClick }) => {
  const text = type.charAt(0).toUpperCase() + type.substr(1)
  const span = (
    <span className={type + disabled ? ' disabled' : ''}>{text}</span>
  )

  if (disabled && tooltip)
    return (
      <TooltipAnchor additionalClassName="EditDelete">
        {span}
        <Tooltip>
          <div dangerouslySetInnerHTML={{ __html: tooltip }} />
        </Tooltip>
      </TooltipAnchor>
    )
  if (disabled) return span
  return (
    <button type="button" className="edit" onClick={onClick}>
      {text}
    </button>
  )
}

const EditDelete = ({
  onClickEdit,
  onClickDelete,
  editDisabled = false,
  deleteDisabled = false,
  editDisabledTooltip = '',
  deleteDisabledTooltip = '',
}) => (
  <div className="EditDelete">
    <EditDeleteButton
      type="edit"
      disabled={editDisabled}
      tooltip={editDisabledTooltip}
      onClick={onClickEdit}
    />
    <span>|</span>
    <EditDeleteButton
      type="delete"
      disabled={deleteDisabled}
      tooltip={deleteDisabledTooltip}
      onClick={onClickDelete}
    />
  </div>
)

export default EditDelete
