import React from 'react'
import { ControlledOfferingsTree } from '../../shared/OfferingsTree'

import './BulkEditAbortionServices.scss'

// TO SUBMIT: give offerings as updateEdits({partial_offerings: ...})

const buildOfferingsList = (offering, currentList) => {
  currentList.push(offering.key)
  for (const childOffering of offering.children) {
    buildOfferingsList(childOffering, currentList)
  }
  return currentList
}

const getAbortionKnownOfferings = (record, abortionOfferingsList) => {
  return Object.keys(record.known_offerings)
    .filter((key) => abortionOfferingsList.includes(key))
    .reduce((obj, key) => ({ ...obj, [key]: record.known_offerings[key] }), {})
}

const mixedUnknownStringsToNull = (value) => {
  return value === 'mixed' || value === 'unknown' ? null : value
}

const BulkEditAbortionServices = ({
  edits,
  label,
  recordDetails,
  clinicOfferingsTree,
  updateEdits,
}) => {
  const abortionOfferingsList = buildOfferingsList(
    clinicOfferingsTree[0],
    []
  ).sort()

  // Start off with all offerings as "unknown"
  let initialAggregateKnownOfferings = abortionOfferingsList.reduce(
    (a, v) => ({ ...a, [v]: 'unknown' }),
    {}
  )

  // this nets us a list of all possible offerings with values that match the first selected clinic if known, and "unknown" if not included
  initialAggregateKnownOfferings = {
    ...initialAggregateKnownOfferings,
    ...getAbortionKnownOfferings(recordDetails[0], abortionOfferingsList),
  }

  // go through all the other clinics and change values to "mixed" if they disagree with the current value
  for (const record of recordDetails) {
    // fold in all the present keys in this clinic
    for (const offeringKey of Object.keys(
      getAbortionKnownOfferings(record, abortionOfferingsList)
    )) {
      if (
        record.known_offerings[offeringKey] !==
        initialAggregateKnownOfferings[offeringKey]
      ) {
        initialAggregateKnownOfferings[offeringKey] = 'mixed'
      }
    }

    // If we saw this key on a different clinic and it's 'unknown' in this one, set it to mixed
    for (const offeringKey of Object.keys(initialAggregateKnownOfferings)) {
      if (
        !(offeringKey in record.known_offerings) &&
        initialAggregateKnownOfferings[offeringKey] !== 'unknown'
      ) {
        initialAggregateKnownOfferings[offeringKey] = 'mixed'
      }
    }
  }
  // At this point, the data look like {abortion_services: true, telehealth_pill: 'mixed', ...}
  // console.log('initialAggregateKnownOfferings',initialAggregateKnownOfferings);

  // YesNoUnknownCheckbox displays data True/False/Null, so let's make a copy of knownOfferings with both "mixed" and "unknown" values set to null
  // Change to {abortion_services: true, telehealth_pill: null, ...}
  let aggregateKnownOfferings = {}
  for (const offeringKey of Object.keys(initialAggregateKnownOfferings)) {
    aggregateKnownOfferings[offeringKey] = mixedUnknownStringsToNull(
      initialAggregateKnownOfferings[offeringKey]
    )
  }
  // folds in whatever edits we've made.
  aggregateKnownOfferings = {
    ...aggregateKnownOfferings,
    ...edits.partial_offerings,
  }

  const onChange = (knownOfferingsChanges) => {
    // old changes + new changes
    const oldAndIncomingChangedOfferings = {
      ...aggregateKnownOfferings,
      ...knownOfferingsChanges,
    }

    // take out the nulls and anything that's not changed from the initial data
    const filteredChanges = Object.keys(oldAndIncomingChangedOfferings)
      .filter(
        (key) =>
          aggregateKnownOfferings[key] !== oldAndIncomingChangedOfferings[key]
      )
      .reduce(
        (obj, key) => ({ ...obj, [key]: oldAndIncomingChangedOfferings[key] }),
        {}
      )

    updateEdits({
      partial_offerings: filteredChanges,
    })
  }

  const getExtraStylesForRow = (offeringKey) => {
    const extraStyles = {
      checkbox: '',
      label: '',
    }
    const originalValue = initialAggregateKnownOfferings[offeringKey] // true/false/'mixed'/'unknown'
    const currentValue = aggregateKnownOfferings[offeringKey] // true/false/null

    // distingush between null meaning "unknown" and "mixed"
    if (currentValue === null && originalValue === 'mixed') {
      extraStyles.checkbox = 'mixed'
    }
    // detect "changed" attributes to make the label salmon
    if (mixedUnknownStringsToNull(originalValue) !== currentValue) {
      extraStyles.label = 'changed'
    }
    return extraStyles
  }

  return (
    <div className="BulkEditAbortionServices">
      <hr />
      <h4>{label}</h4>
      <ControlledOfferingsTree
        treeData={clinicOfferingsTree}
        getExtraStylesForRow={getExtraStylesForRow}
        knownOfferings={aggregateKnownOfferings}
        applyKnownOfferings={onChange}
        disabledTooltipText="You cannot change the three top-level abortion services from this view. Please edit those at the clinic level."
        disabledKeys={[
          'abortion_services',
          'abortion_telemedicine',
          'medication_abortion_pill',
          'surgical_abortion',
        ]}
      />
    </div>
  )
}

const paramKeys = ['partial_offerings']

export default BulkEditAbortionServices
export { paramKeys }
