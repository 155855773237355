import React from 'react'
import noResultsIcon from '../../../assets/images/index_no_results.svg'
import './IndexEmptyResults.scss'

const IndexEmptyResults = ({ onMapViewText = false }) => {
  const title = `No results${onMapViewText ? ' on map view' : ''}`
  return (
    <div id="IndexEmptyResults">
      <img src={noResultsIcon} alt="No Results" />
      <div className="title">{title}</div>
      <div className="subtitle">
        Try removing a filter, updating your search criteria, or switching to
        list view to see more results.
      </div>
    </div>
  )
}

export default IndexEmptyResults
