import React from 'react'
import PropTypes from 'prop-types'
import DraggableContacts from './DraggableContacts.jsx'

const getContactMethodType = (cm) =>
  (cm.number && 'phone_numbers') || (cm.email && 'emails') || (cm.url && 'urls')

const NavigatorReferralContacts = ({
  currentNavigatorSupportOrg,
  updateNavigatorSupportOrg,
  existingLabels = [],
}) => {
  const contactMethods = Object.fromEntries(
    Object.entries({
      ...currentNavigatorSupportOrg.emails,
      ...currentNavigatorSupportOrg.phone_numbers,
      ...currentNavigatorSupportOrg.urls,
    }).filter(([_id, contactMethod]) => contactMethod.navigator_referral)
  )

  const onUpdateContactMethods = (newContactMethods) => {
    // newContactMethods is an object eg { [id]: contactMethodObject }
    // though this function can accept multiple contact methods
    // we should only be receiving one at a time
    const newCurrentNavigatorSupportOrg = {
      urls: {},
      phone_numbers: {},
      emails: {},
    }
    Object.entries(newContactMethods).forEach(([id, contactMethod]) => {
      const key = getContactMethodType(contactMethod) // urls, emails, or phone_numbers
      newCurrentNavigatorSupportOrg[key][id] = contactMethod
    })
    updateNavigatorSupportOrg(newCurrentNavigatorSupportOrg)
  }

  return (
    <div className="NavigatorReferralContacts">
      <DraggableContacts
        // type={type}
        prefix="" // don't build form inputs with names; those will be build in PoemContact
        positionColumn="navigator_referral_position"
        contactMethods={contactMethods}
        onChange={onUpdateContactMethods}
        addButtonText="add a foo"
        existingLabels={existingLabels}
        defaultContactMethod={{ navigator_referral: true }}
        addNewCmButtons={['phone_numbers', 'urls', 'emails']}
        privateSupportOrg={currentNavigatorSupportOrg.private_support_org}
      />
    </div>
  )
}

NavigatorReferralContacts.propTypes = {
  currentNavigatorSupportOrg: PropTypes.object.isRequired,
  updateNavigatorSupportOrg: PropTypes.func.isRequired,
  existingLabels: PropTypes.array.isRequired,
}

export default NavigatorReferralContacts
