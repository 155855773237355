import React from 'react'
import './ExpandableStanza.scss'

const ExpandableStanza = ({ sidebar, children }) => (
  <div className="ExpandableStanza">
    <div className="left-content">{sidebar}</div>
    <div className="right-content">{children}</div>
  </div>
)

export default ExpandableStanza
