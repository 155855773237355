import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _uniqueId from 'lodash/uniqueId'
import { OrgSelectInput } from '../navigator_support_orgs/inputs/OrgInputs.jsx'
import rubyConstants from '../../ruby_constants.js'
import './AppointmentAvailabilityInput.scss'

const selectOptions = Object.entries(
  rubyConstants.AppointmentAvailability.VALUES
)
  .sort((a, b) => (a[0] === '' || a[0] < b[0] ? -1 : 1))
  .map((x) => x.reverse())

const selectOptionsForBulkEdit = selectOptions.map(([label, value]) => [
  label,
  value || 'set_to_empty_string',
])
selectOptionsForBulkEdit.unshift(['No Change', ''])

const includesOnlyLowerOrUpperLimit = (value) =>
  Object.values(value).filter(Boolean).length === 1

const lowerLimitGreaterThanOrEqualToUpperLimit = (value) => {
  if (Object.values(value).filter(Boolean).length !== 2) return false
  if (
    value['lower_range'] === 'set_to_empty_string' &&
    value['upper_range'] === 'set_to_empty_string'
  ) {
    return false
  }
  if (value['lower_range'] < value['upper_range']) return false
  return true
}

// note: this only handles bulk edit, but it doesn't handle the other case
// when the value of unknown is ''
const includesOneUnknownSelection = (value) => {
  if (Object.values(value).filter(Boolean).length !== 2) return false
  if (
    value['lower_range'] === 'set_to_empty_string' &&
    value['upper_range'] === 'set_to_empty_string'
  ) {
    return false
  }
  if (
    value['lower_range'] === 'set_to_empty_string' ||
    value['upper_range'] === 'set_to_empty_string'
  ) {
    return true
  }
  return false
}

const AppointmentAvailabilityInput = ({
  expandsOnOpen,
  bulkEdit,
  onChange,
  prefix,
  setErrors,
  short_label,
  show_no_appointment_required_checkbox,
  value,
}) => {
  const [stateValue, setStateValue] = useState(value || {}) // only used if no onChange
  const [noAppointmentRequired, setNoAppointmentRequired] = useState(
    !!(value && value.not_available)
  )

  const checkboxInputId = _uniqueId('checkboxInputId-')

  const currentValue = onChange ? value : stateValue

  const handleChange = (newObj) => {
    if (onChange) {
      onChange(newObj)
    } else {
      setStateValue(newObj)
    }

    if (typeof setErrors === 'function')
      setErrors(
        includesOnlyLowerOrUpperLimit(newObj) ||
          lowerLimitGreaterThanOrEqualToUpperLimit(newObj) ||
          includesOneUnknownSelection(newObj)
      )
  }

  const [lowerRangeInput, upperRangeInput] = ['lower_range', 'upper_range'].map(
    (range) => (
      <OrgSelectInput
        bulkEdit={bulkEdit}
        disabled={noAppointmentRequired}
        expandsOnOpen={expandsOnOpen}
        key={range}
        name={!noAppointmentRequired && prefix && `${prefix}[${range}]`}
        onChange={(v) => handleChange({ ...currentValue, [range]: v })}
        options={bulkEdit ? selectOptionsForBulkEdit : selectOptions}
        value={currentValue[range] || ''}
        placeholder={selectOptions['']}
      />
    )
  )

  // hide and clear counseling related inputs if the clinic does not offer counseling
  // useEffect ensures this is run on initial render AND every time it changes
  useEffect(() => {
    // return if we should show counseling appointments
    // because you are in a state requiring counseling and counseling not req not checked
    if (!show_no_appointment_required_checkbox) return

    // hides the counseling row in choosing days
    window.clinics.toggleConditionalSection(
      'counseling',
      !noAppointmentRequired
    )
    if (noAppointmentRequired) {
      // clears the checked days for counseling
      $("[data-toggle-on-key='counseling']")
        .find('input')
        .each(function clearCounselingRelatedInputs() {
          if ($(this).attr('type') === 'checkbox') {
            $(this).prop('checked', false)
          } else {
            $(this).val('')
          }
        })
    }
  }, [noAppointmentRequired, show_no_appointment_required_checkbox])

  return (
    <div className="AppointmentAvailabilityInput">
      <div className="input-container">
        <div className="lower-to-upper-container">
          {lowerRangeInput}
          <span className="connector">to</span>
          {upperRangeInput}
        </div>
        {show_no_appointment_required_checkbox && (
          <div className="checkbox-container">
            <input
              type="checkbox"
              value="1"
              id={checkboxInputId}
              name={`${prefix}[not_available]`}
              checked={noAppointmentRequired}
              onChange={(e) => setNoAppointmentRequired(e.target.checked)}
            />
            <label htmlFor={checkboxInputId}>
              no {short_label} appointment required
            </label>
          </div>
        )}
      </div>
      {bulkEdit && (
        <div className="appointment-availability-warnings-container">
          {includesOnlyLowerOrUpperLimit(value) && (
            <div className="abortion-schedule-warning-note">
              Changes to appointment availability require both lower and upper
              limit to be set.
            </div>
          )}
          {lowerLimitGreaterThanOrEqualToUpperLimit(value) && (
            <div className="abortion-schedule-warning-note">
              Lower limit must be less than upper limit.
            </div>
          )}
          {includesOneUnknownSelection(value) && (
            <div className="abortion-schedule-warning-note">
              If lower limit or upper limit are set to &quot;Unknown&quot;, both
              have to be set to &quot;Unknown&quot;.
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export const AppointmentAvailabilityPropType = PropTypes.shape({
  not_available: PropTypes.oneOf(['1']),
  lower_range: PropTypes.string,
  upper_range: PropTypes.string,
})

AppointmentAvailabilityInput.propTypes = {
  expandsOnOpen: PropTypes.bool,
  bulkEdit: PropTypes.bool,
  onChange: PropTypes.func,
  prefix: PropTypes.string,
  short_label: PropTypes.string,
  show_no_appointment_required_checkbox: PropTypes.bool,
  value: AppointmentAvailabilityPropType,
}

AppointmentAvailabilityInput.defaultProps = {
  expandsOnOpen: false,
  bulkEdit: false,
  onChange: null,
  prefix: null,
  short_label: '',
  show_no_appointment_required_checkbox: false,
  value: {},
}

export default AppointmentAvailabilityInput
