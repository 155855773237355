import React from 'react'
import {
  OrgSelectInput,
  OrgInputPlainLabel,
} from '../navigator_support_orgs/inputs/OrgInputs.jsx'
import './BulkEditAddAndRemoveFrom.scss'

const BulkEditAddAndRemoveFrom = ({
  attribute,
  edits,
  updateEdits,
  options,
  label,
}) => {
  const selectProps = {
    options,
    isClearable: true,
    isMulti: true,
  }

  const onChangeFunc = (dir) => (newValue) =>
    updateEdits({ [attribute + dir]: newValue })

  return (
    <div className="BulkEditAddAndRemoveFrom">
      <OrgInputPlainLabel label={label} />
      <div
        className="inputs"
        style={{
          margin: '10px 0 0 30px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <OrgSelectInput
          {...selectProps}
          label="Add"
          onChange={onChangeFunc('_add_to')}
          value={edits[`${attribute}_add_to`]}
          isValueDisabled={(val) =>
            edits[`${attribute}_remove_from`]?.includes(val)
          }
        />
        <OrgSelectInput
          {...selectProps}
          label="Remove"
          onChange={onChangeFunc('_remove_from')}
          value={edits[`${attribute}_remove_from`]}
          isValueDisabled={(val) => edits[`${attribute}_add_to`]?.includes(val)}
        />
      </div>
    </div>
  )
}

export default BulkEditAddAndRemoveFrom
