import React from 'react'
import PropTypes from 'prop-types'
import _uniqueId from 'lodash/uniqueId'
import './OfferingCheckbox.scss'

//
//  OfferingCheckbox
//

// Produce values to be consumed by ActiveRecord::Type::Boolean.new.deserialize
// and HasOfferings::partial_offerings=

const OfferingCheckbox = ({ checked, name, label, onChange }) => {
  const inputId = _uniqueId('OfferingCheckbox-')

  return (
    <div className="OfferingCheckbox">
      {typeof checked === 'boolean' && name && (
        <input type="hidden" name={name} value={checked} />
      )}
      <input
        type="checkbox"
        onChange={onChange}
        id={inputId}
        checked={!!checked}
      />
      <label htmlFor={inputId}>{label}</label>
    </div>
  )
}

OfferingCheckbox.defaultProps = {
  name: null,
  checked: null,
}

OfferingCheckbox.propTypes = {
  name: PropTypes.string, // Rails form input name, e.g. clinic[known_offerings][birth_control]
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default OfferingCheckbox
