import React from 'react'
import IconPublic from './visible_to_public.svg'
import IconNavigate from './visible_to_navigators.svg'
import { TooltipAnchor, Tooltip } from '../../../shared/Tooltip.jsx'

const computeCopies = (isOrgPrivate) => {
  let copy = {
    default: {
      pub: 'Displays on <i>Navigate</i> and publicly on Abortion Finder.',
      nav: 'Displays on <i>Navigate</i> only.',
    },
    navigator_referral: {
      nav: 'If checked, will display on <i>Navigate</i> as a primary contact method for navigators to make referrals.',
    },
  }

  if (isOrgPrivate) {
    copy = {
      ...copy,
      seeker_assistance: {
        pub: 'If checked, will display on <i>Navigate</i> as a primary contact method for abortion seekers to get support.',
      },
      general: {
        pub: 'If checked, will display on <i>Navigate</i> as a general contact method.',
      },
    }
  } else {
    copy = {
      ...copy,
      seeker_assistance: {
        pub: 'If checked, will display on Abortion Finder and <i>Navigate</i> as a primary contact method for abortion seekers to get support.',
      },
      general: {
        pub: 'If checked, will display on Abortion Finder and <i>Navigate</i> as a general contact method.',
      },
    }
  }

  return copy
}

const VisibilityIcon = ({
  attribute = 'default',
  extraStyleClassName,
  isInfoPublic,
  privateSupportOrg,
}) => {
  const copy = computeCopies(privateSupportOrg)
  const subKey = isInfoPublic ? 'pub' : 'nav'
  const tooltipCopy = copy[attribute][subKey]

  const imgSrc = isInfoPublic && !privateSupportOrg ? IconPublic : IconNavigate

  return (
    <TooltipAnchor
      additionalClassName={`VisibilityIcon ${extraStyleClassName}`}
    >
      <img src={imgSrc} style={{ padding: '0 10px' }} alt="Visibility Icon" />
      <Tooltip>
        <div dangerouslySetInnerHTML={{ __html: tooltipCopy }} />
      </Tooltip>
    </TooltipAnchor>
  )
}

export default VisibilityIcon
