//
//      Private functions
//

const getPrefix = (idString) => idString.match(/.*\d+/)[0]
// eg provider_network_geographic_areas_attributes_0

const showHideInputs = (prefix) => {
  const coverageSelect = document.getElementById(`${prefix}_coverage_type`)
  const liElements = {
    'City/Cities Only': document.getElementById(`${prefix}_cities_input`),
    'State Region(s) Only': document.getElementById(`${prefix}_regions_input`),
    'Provider Partner(s) Only': document.getElementById(
      `${prefix}_clinics_input`
    ),
  }

  for (const [key, value] of Object.entries(liElements)) {
    if (coverageSelect.value === key) {
      $(value).fadeIn('fast')
    } else {
      $(value).fadeOut('fast').find('option').prop('selected', false)
      $(value).find('select').trigger('chosen:updated')
    }
  }
}

const setCoverageTypes = (prefix) => {
  let state = ''
  let optionArr = []
  let selectedCoverage = ''
  state = document.getElementById(`${prefix}_state`).value
  if (state === 'National') {
    optionArr = ['All', 'Provider Partner(s) Only']
  } else {
    optionArr = [
      'All',
      'City/Cities Only',
      'State Region(s) Only',
      'Provider Partner(s) Only',
    ]
  }
  const coverageSelect = document.getElementById(`${prefix}_coverage_type`)
  selectedCoverage = coverageSelect.value
  coverageSelect.innerHTML = '' // clear any old options
  let option
  optionArr.forEach((thisCoverageType) => {
    option = document.createElement('option')
    option.appendChild(document.createTextNode(thisCoverageType))
    if (selectedCoverage === thisCoverageType) {
      option.selected = true
    }
    coverageSelect.appendChild(option)
  })
  // eslint-disable-next-line no-use-before-define
  populateClinicDropDowns(document.getElementById(`${prefix}_state`), false)
  $(coverageSelect).trigger('chosen:updated')
}

// Expects variables to be defined by parent page:
/* global regionsByState, citiesByState, clinicsByState */
const populateClinicDropDowns = (clickedObj, doSetCoverageTypes = true) => {
  const prefix = getPrefix(clickedObj.id)
  const citiesSelect = document.getElementById(`${prefix}_cities`)
  const regionsSelect = document.getElementById(`${prefix}_regions`)
  const clinicsSelect = document.getElementById(`${prefix}_clinic_ids`)
  const state = clickedObj.value

  // need to make sure the right options are there for the coverage_type dropdown
  if (doSetCoverageTypes) {
    setCoverageTypes(prefix)
  } else {
    showHideInputs(prefix)
  }

  citiesSelect.innerHTML = '' // clear any old options
  let option
  ;(citiesByState[state] || []).forEach((thisCity) => {
    // add options for any cities for this state from other areas in the database
    option = document.createElement('option')
    option.appendChild(document.createTextNode(thisCity))
    citiesSelect.appendChild(option)
  })
  $(citiesSelect).trigger('chosen:updated')

  regionsSelect.innerHTML = '' // clear any old options
  ;(regionsByState[state] || []).forEach((thisRegion) => {
    // add options for any regions for this state from other areas in the database
    option = document.createElement('option')
    option.appendChild(document.createTextNode(thisRegion))
    regionsSelect.appendChild(option)
  })
  $(regionsSelect).trigger('chosen:updated')

  clinicsSelect.innerHTML = '' // clear any old options
  ;(clinicsByState[state] || []).forEach((thisClinic) => {
    option = document.createElement('option')
    option.appendChild(document.createTextNode(thisClinic[0]))
    // eslint-disable-next-line prefer-destructuring
    option.value = thisClinic[1]
    clinicsSelect.appendChild(option)
  })
  $(clinicsSelect).trigger('chosen:updated')
}

//
//      Globally accessible functions
//
window.areaHavers = {
  coverageTypeHasChanged(clickedObj) {
    const prefix = getPrefix(clickedObj.id)
    if (document.getElementById(`${prefix}_state`).disabled === false) {
      setCoverageTypes(prefix)
    }
    showHideInputs(prefix)
  },

  setCoverageTypesForElement(elem) {
    setCoverageTypes(getPrefix(elem.id))
  },

  showHideInputsForAll() {
    // eslint-disable-next-line func-names
    $('select.coverage-type-dropdown').each(function () {
      showHideInputs(getPrefix(this.id))
    })
  },
}

export default window.areaHavers
