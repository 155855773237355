import React from 'react'
import externalLinkIcon from '../../../assets/images/external_link_button_cornflower_icon.svg'
import phoneLinkIcon from '../../../assets/images/phone_link_cornflower_icon.svg'
import './navigator_clinic_snippets.scss'

export const FullAddressColumn = ({ clinic }) => {
  const hybrid =
    clinic.location_type === 'hybrid' ? (
      <div className="virtual-offered">Virtual Offered</div>
    ) : null

  const distance = clinic.distance ? (
    <div className="distance miles">
      <span>{clinic.distance}</span>
    </div>
  ) : null

  const driveTime = clinic.driveTime ? (
    <div className="distance minutes">
      <span>{clinic.driveTime}</span>
    </div>
  ) : null

  const nearestAirport = clinic.nearby_airports?.[0] ? (
    <div className="distance airport">
      <span>{`${clinic.nearby_airports[0].miles} miles to ${clinic.nearby_airports[0].code}`}</span>
    </div>
  ) : null

  return (
    <div className="FullAddressColumn">
      <div>{clinic.full_address_with_private_address_or_virtual_only}</div>
      {hybrid}
      {distance}
      {driveTime}
      {nearestAirport}
    </div>
  )
}

export const ExternalLink = ({ href, text, withIcon = false }) => {
  if (!href) return <div /> // element needed for grid layout

  let image = externalLinkIcon
  let defaultText = 'Website'

  if (href.startsWith('tel:')) {
    image = phoneLinkIcon
    defaultText = href.replace('tel:', '')
  }

  return (
    <a
      className="ExternalLink"
      href={href}
      target={href.startsWith('http') ? '_blank' : '_self'}
      rel={href.startsWith('http') ? 'noopener noreferrer' : undefined}
    >
      {withIcon && (
        <div className="link-icon-container">
          <img src={image} alt="" />
        </div>
      )}
      {text || defaultText}
    </a>
  )
}

export const PillLimitColumn = ({ json = {}, scopeToUsStates = [] }) => {
  let entries = Object.entries(json)
  const entriesScopedToQueryState = entries.filter(([stateOrType]) =>
    // stateOrType may be a US State, or one of "In Person", "Telehealth"
    scopeToUsStates.includes(stateOrType)
  )
  if (entriesScopedToQueryState.length) entries = entriesScopedToQueryState

  // if all the entries have the same value (eg 12 weeks 6 days), just show it once
  if (entries.every((entry) => entry[1] === entries[0][1])) return entries[0][1]

  return (
    <ul className="PillLimitColumn">
      {entries.map(([state, val]) => (
        <li key={state}>{`${state}: ${val}`}</li>
      ))}
    </ul>
  )
}

export const ProcedureLimitColumn = ({ json = {} }) => (
  <div className="ProcedureLimitColumn">
    <div>{json.limit}</div>
    {json.exceptions && <i>exceptions: {json.exceptions}</i>}
  </div>
)
