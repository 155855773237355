export const urlAddHttps = (
  url: string | undefined,
  updateFnc: (newUrl: string) => void
): void => {
  if (url && !/^https?:\/\//.test(url)) {
    const newUrl = `https://${url}`
    updateFnc(newUrl)
  }
}

export const getCsrfToken = (): string | null => {
  const csrfElement = document.querySelector("meta[name='csrf-token']")
  if (csrfElement) return csrfElement.getAttribute('content')
  if (typeof jest === 'object') return null // expected to not find csrf tag in test environment
  console.warn('CSRF token meta tag not found!')
  return null
}

export const calculateBottomOfHeader = (): number | void => {
  const header = document.getElementById('root-sticky-top-navlinks')
  if (!header) return console.error('Could not find header element')
  return header.offsetTop + header.offsetHeight
}

// like ruby's partition, splits an array into two arrays
// based on the return value of the predicate
export const partition = <T>(
  arr: T[],
  predicate: (item: T) => boolean
): [T[], T[]] => {
  const [truthy, falsy]: [T[], T[]] = [[], []]
  arr.forEach((item) => (predicate(item) ? truthy : falsy).push(item))
  return [truthy, falsy]
}

// like ruby's hash.compact, removes undefined values from an object
export const objectCompact = <T extends Record<string, unknown>>(obj: T): T =>
  Object.fromEntries(
    Object.entries(obj).filter(([, value]) => value !== undefined)
  ) as T

export const isBlank = (value: unknown): boolean => {
  if (value === null) return true
  if (Array.isArray(value)) return value.length === 0
  if (typeof value === 'object') return Object.keys(value).length === 0
  return !value
}
// like ruby's hash.compact_blank, removes falsey values from an object
export const objectCompactBlank = <T extends Record<string, unknown>>(
  obj: T
): T =>
  Object.fromEntries(
    Object.entries(obj).filter(([, value]) => !isBlank(value))
  ) as T

export const objectExcept = <T extends Record<string, unknown>>(
  obj: T,
  keys: string | string[]
): T => {
  const newObj = { ...obj }
  ;(typeof keys === 'string' ? [keys] : keys).forEach((k) => delete newObj[k])
  return newObj
}
