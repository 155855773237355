import React from 'react'
import ExpandablePoem from '../ExpandablePoem.jsx'
import ExpandableStanza from '../ExpandableStanza.jsx'
import VisibilityIcon from '../inputs/VisibilityIcon.jsx'
import {
  OrgUrlInput,
  OrgInputPlainLabel,
  OrgRadioInput,
} from '../inputs/OrgInputs.jsx'
import ContactType from '../inputs/ContactType.jsx'
import HoursInputs from '../inputs/HoursInputs.jsx'
import rubyConstants from '../../../ruby_constants.js'
import './PoemContact.scss'

const socialMedia = ['facebook', 'instagram', 'twitter']
const contactTypes = ['phone_numbers', 'emails', 'urls']

const PoemContact = ({
  currentNavigatorSupportOrg,
  updateNavigatorSupportOrg,
  expanded,
  toggleExpanded,
  prefix,
  selectOptions = {},
  fromServerErrors = {},
  isAdmin,
}) => {
  const abortionSeekerContactSidebar = (
    <div>
      <b>Communicate how your organization interacts with abortion seekers.</b>{' '}
      This will ensure your organization is presented in the most user-friendly
      way on AbortionFinder.org.
    </div>
  )
  const contactSidebar = (
    <div>
      Contact methods only marked as <b>“Navigator referral contact”</b> will
      ONLY display in <i>Navigate</i> and will not display publicly on the
      Abortion Finder platform.
    </div>
  )

  return (
    <ExpandablePoem
      header="Contact"
      subheader="General website URL, Phone, Email, Links/URLs, Hours, Social media"
      expanded={expanded}
      toggleExpanded={toggleExpanded}
      poemClass="PoemContact"
    >
      <ExpandableStanza sidebar={abortionSeekerContactSidebar}>
        <div>
          <span className="input-header">Abortion Seeker Contact</span>
          <VisibilityIcon
            extraStyleClassName="abortion-seeker-contact-preferences-visibility-icon"
            isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
          />
        </div>
        {Object.entries(
          rubyConstants.SupportOrg.ABORTION_SEEKER_CONTACT_PREFERENCES
        ).map(([db_key, display_name]) => (
          <div className={`${db_key}`} key={db_key}>
            <OrgRadioInput
              label={display_name}
              checked={
                currentNavigatorSupportOrg.abortion_seeker_contact_preferences ===
                db_key
              }
              key={db_key}
              name={`${prefix}[abortion_seeker_contact_preferences]`}
              value={db_key}
              onChange={() =>
                updateNavigatorSupportOrg({
                  abortion_seeker_contact_preferences: db_key,
                })
              }
            />
          </div>
        ))}
      </ExpandableStanza>
      <ExpandableStanza sidebar={contactSidebar}>
        <OrgUrlInput
          name={`${prefix}[website]`}
          label="General Website URL"
          value={currentNavigatorSupportOrg.website}
          onChange={(value) => updateNavigatorSupportOrg({ website: value })}
          isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
          errors={fromServerErrors.website}
        />

        <OrgUrlInput
          name={`${prefix}[nnaf_link]`}
          label="NNAF Webpage URL (Optional)"
          value={currentNavigatorSupportOrg.nnaf_link}
          onChange={(value) => updateNavigatorSupportOrg({ nnaf_link: value })}
          isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
          errors={fromServerErrors.nnaf_link}
        />
        <div className="divider" />

        <div className="contacts-container">
          {contactTypes.map((contactType) => (
            <React.Fragment key={contactType}>
              <ContactType
                contactMethods={currentNavigatorSupportOrg[contactType]}
                existingLabels={selectOptions.contact_methods_existing_labels}
                key={contactType}
                type={contactType}
                prefix={prefix}
                privateSupportOrg={
                  currentNavigatorSupportOrg.private_support_org
                }
                onChange={(contactMethods) =>
                  updateNavigatorSupportOrg({ [contactType]: contactMethods })
                }
              />
              {contactType !== 'urls' && <div className="divider" />}
            </React.Fragment>
          ))}
        </div>

        <div className="divider" />

        <div className="hours-container">
          <HoursInputs
            currentNavigatorSupportOrg={currentNavigatorSupportOrg}
            updateNavigatorSupportOrg={updateNavigatorSupportOrg}
            prefix={`${prefix}[business_hours_attributes]`}
            isAdmin={isAdmin}
            fromServerErrors={fromServerErrors.business_hours}
          />
        </div>

        <div className="divider" />

        <div className="soc-meds">
          <OrgInputPlainLabel
            label="Social Media (Optional)"
            isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
          />

          {socialMedia.map((socType) => (
            <OrgUrlInput
              name={`${prefix}[${socType}]`}
              label={`${socType} URL`}
              value={
                currentNavigatorSupportOrg.social_media &&
                currentNavigatorSupportOrg.social_media[socType]
              }
              onChange={(value) =>
                updateNavigatorSupportOrg({
                  social_media: { [socType]: value },
                })
              }
              singleRow={true}
              key={`${prefix}[${socType}]`}
              serverErrors={fromServerErrors[socType]}
            />
          ))}
        </div>
      </ExpandableStanza>
    </ExpandablePoem>
  )
}

PoemContact.openIfErrors = ['website', 'nnaf_link', 'business_hours'].concat(
  socialMedia,
  contactTypes
)
export default PoemContact
