import React from 'react'
import PropTypes from 'prop-types'
import GalModeDaysInput from '../../shared/GalModeDaysInput.jsx'
import { DeleteHasManyInput } from './DeleteHasManyInput.jsx'
import rubyConstants from '../../ruby_constants.js'
import { OrgStringInput } from '../navigator_support_orgs/inputs/OrgInputs.jsx'
import './PriceForm.scss'

/* eslint-disable jsx-a11y/label-has-associated-control */

const PriceForm = ({
  hideGalInputs,
  i,
  prefix,
  price,
  removeButtonDisabled,
  updatePrice,
}) => {
  if (!price.id && price._destroy) return null
  const costInput = (
    <label>
      <OrgStringInput
        errors={(price.errors && price.errors.cost) || null}
        label={`Item ${price._destroy ? '' : i}`}
        name={`${prefix}[cost]`}
        onChange={(newCostValue) => updatePrice({ cost: newCostValue })}
        type="text"
        value={price.cost || ''}
      />
    </label>
  )

  const galsInputs = (
    <div className={`gals-container ${hideGalInputs ? 'hidden' : ''}`}>
      {/* inputs for min_gal_days, min_gal_mode, max_gal_days, max_gal_mode */}
      {['min', 'max'].map((minOrMax) => (
        <label key={`${minOrMax}_gal`}>
          <div className="min-or-max">{`${minOrMax.toUpperCase()} GAL`}</div>
          <GalModeDaysInput
            daysRange={[35, 280]}
            daysName={`${prefix}[${minOrMax}_gal_days]`}
            daysValue={price[`${minOrMax}_gal_days`]}
            disabled={price._destroy}
            galModeDays={rubyConstants.Price.GAL_MODE_DAYS}
            modeName={`${prefix}[${minOrMax}_gal_mode]`}
            modeValue={price[`${minOrMax}_gal_mode`]}
            onChange={({ days, mode }) =>
              updatePrice({
                [`${minOrMax}_gal_days`]: days,
                [`${minOrMax}_gal_mode`]: mode,
              })
            }
            errors={price.errors && price.errors[`${minOrMax}_gal_days`]}
          />
        </label>
      ))}
    </div>
  )

  return (
    <div className="PriceForm">
      <input type="hidden" name={`${prefix}[id]`} value={price.id || ''} />
      <input
        type="hidden"
        name={`${prefix}[_destroy]`}
        value={price._destroy || ''}
      />
      {costInput}
      {galsInputs}
      <DeleteHasManyInput
        record={price}
        disabled={removeButtonDisabled}
        updateRecord={updatePrice}
      />
    </div>
  )
}

PriceForm.propTypes = {
  price: PropTypes.shape({}).isRequired,
  prefix: PropTypes.string.isRequired,
  updatePrice: PropTypes.func.isRequired,
  hideGalInputs: PropTypes.bool.isRequired,
}

export { PriceForm }
