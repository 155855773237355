import React, { useState, useRef, useEffect } from 'react'
import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
  Polyline,
  useJsApiLoader,
} from '@react-google-maps/api'
import PropTypes from 'prop-types'
import NoResultsMapCard from './NoResultsMapCard.jsx'
import NavigatorClinicCard from './NavigatorClinicCard.jsx'
import MapViewWarningCard from './MapViewWarningCard.jsx'
import pinSvg from './map_pin.svg'
import pinSvgOrange from './map_pin_orange.svg'
import pinSelectedSvg from './map_pin_selected.svg'
import pinClusterSvg from './cluster_pin_icon_empty.svg'
import magnifySvg from './magnify.svg'
import { clinicTypeBase } from './NavigatorClinicTable.jsx'
import './NavigatorClinicMapView.scss'
import BackToTopButton from './BackToTopButton.jsx'

// pad the "search this area" map dashed outline to make room for clinic cards
const boundsSearchPadding = { top: 10, right: 10, bottom: 10, left: 388 }
const zoomLevelWhenZoomedToAClinic = 12
const numRecordsToFitBounds = 10
const clusterIconStyles = [
  {
    textColor: '#406dc0', // cornflower_blue
    textSize: 16,
    url: pinClusterSvg,
    height: 40,
    width: 40,
    fontFamily: 'MetaSerifPro, MetaSerif, "Times New Roman", Times, serif',
  },
  {
    textColor: '#FFA500', // orange
    textSize: 16,
    url: pinClusterSvg,
    height: 40,
    width: 40,
    fontFamily: 'MetaSerifPro, MetaSerif, "Times New Roman", Times, serif',
  },
]

const mapBoundsPolyline = (bounds) => {
  if (!bounds) return null

  const path = [
    { lat: bounds.north, lng: bounds.east },
    { lat: bounds.north, lng: bounds.west },
    { lat: bounds.south, lng: bounds.west },
    { lat: bounds.south, lng: bounds.east },
    { lat: bounds.north, lng: bounds.east }, // Close the loop
  ]

  const dashSymbol = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1.0,
    scale: 4,
  }

  return (
    <Polyline
      path={path}
      options={{
        strokeColor: '#34589c', // pressed_cornflower_blue
        strokeOpacity: 0,
        icons: [
          {
            icon: dashSymbol,
            offset: '0',
            repeat: '20px',
          },
        ],
      }}
    />
  )
}

export const centerMapOn = (mapRef, records, defaultRecenterOnUsa = true) => {
  const mapPadding = { top: 40, right: 30, bottom: 30, left: 515 }

  if (!mapRef.current) {
    // Google Map is not yet loaded. Try again after a delay.
    setTimeout(() => centerMapOn(mapRef, records, defaultRecenterOnUsa), 50)
    return
  }

  const bounds = new window.google.maps.LatLngBounds()
  records.forEach((record) => {
    if (!(record.lat && record.lng)) return

    const [lat, lng] = [record.lat, record.lng].map((x) => parseFloat(x))
    if ([lat, lng].every((x) => typeof x === 'number'))
      bounds.extend({ lat, lng })
    else console.error('Invalid coordinates for record:', record)
  })

  // if bounds is empty (no map pins had bounds), set to default bounds (Continental US)
  if (defaultRecenterOnUsa && bounds.isEmpty()) {
    bounds.extend(new window.google.maps.LatLng(44.3074816, -75.50888))
    bounds.extend(new window.google.maps.LatLng(29.4731944, -116.42746))
  }

  if (!bounds.isEmpty()) mapRef.current.fitBounds(bounds, mapPadding)
}

const NavigatorClinicMapView = ({
  appointment_availability_filter,
  clickedId,
  google_api_key,
  mapNeedsRecenter,
  mappedAreaFilter,
  records,
  selectedIds,
  setClickedId,
  setMappedAreaFilter,
  setMapView,
  setSelectedId,
  setShowTelehealthHidden,
  showTelehealthHiddenCard,
  totalRecords,
  isStagingEnvironment,
}) => {
  const recordsToFitBounds = records.slice(0, numRecordsToFitBounds)

  const clusterCalculator = (markers) => {
    // For simplicity, we're using the first style for all clusters.
    // You can add logic to vary the icon based on the number of markers if desired.

    let clusterMarkerOrange = false
    if (isStagingEnvironment) {
      // debug styling for cluster icons
      const latThreshold = 0.0001
      const isCloseEnough = (l1, l2) => Math.abs(l1 - l2) < latThreshold

      const hasMatchingLatLng = markers.some((marker) => {
        const markerLat = marker.getPosition().lat()
        return recordsToFitBounds.some(
          (record) =>
            isCloseEnough(markerLat, parseFloat(record.lat)) &&
            isCloseEnough(marker.getPosition().lng(), parseFloat(record.lng))
        )
      })

      if (hasMatchingLatLng) clusterMarkerOrange = true
    }

    return {
      text: `${markers.length.toString()}+`,
      index: clusterMarkerOrange ? 2 : 1,
    }
  }

  const [hoveredId, setHoveredId] = useState(null)
  const [mapBounds, setMapBounds] = useState(null) // will be updated by the map as user interacts
  const mapRef = useRef(null)
  const cardsContainerRef = useRef(null)
  const cardRefs = useRef({})

  const showSearchThisAreaButton = mapBounds && mappedAreaFilter !== mapBounds

  const isSpecial = (id) => id && [clickedId, hoveredId].includes(id)
  const markerZIndex = (id, fallback) => {
    if (id === clickedId) return 3000
    if (id === hoveredId) return 2000
    return fallback
  }

  const [showMapBounds, setShowMapBounds] = useState(false)

  const onClusterClick = (cluster) => {
    const latLngs = cluster
      .getMarkers()
      .map((marker) => marker.getPosition())
      .map((position) => ({ lat: position.lat(), lng: position.lng() }))

    centerMapOn(mapRef, latLngs)
  }

  const scrollCardToTop = (id) => {
    if (!id || !cardRefs.current?.[clickedId]) return

    const cardTop = cardRefs.current[clickedId].offsetTop
    // Scroll the card to the top of its container
    // The 15px should match the 15px for .cards  in NavigatorClinicMapView.scss
    cardsContainerRef.current.scrollTop = cardTop - 15
  }

  useEffect(() => {
    if (cardsContainerRef.current) scrollCardToTop(clickedId)

    // center the clicked pin and adjust the zoom
    const adjustCenter = { 10: -0.35, 12: -0.085 }
    // const maxZoom = 20
    if (clickedId && mapRef.current) {
      const record = records.find((rec) => rec.id === clickedId)
      if (record) {
        const clickedLocation = new window.google.maps.LatLng(
          parseFloat(record.lat),
          parseFloat(record.lng) + adjustCenter[zoomLevelWhenZoomedToAClinic] // move center to the right a bit to adjust for cards
        )
        mapRef.current.panTo(clickedLocation)
        mapRef.current.setZoom(zoomLevelWhenZoomedToAClinic)
      }
    }
  }, [clickedId])

  useEffect(
    // on first render, the cards take a little time to render their height
    () => {
      setTimeout(() => scrollCardToTop(clickedId), 400)
    },
    []
  )

  // set the map bounds to records initially on page load
  // and when mapNeedsRecenter
  useEffect(() => {
    if (!mapNeedsRecenter) return
    if (!numRecordsToFitBounds) return

    centerMapOn(mapRef, records.slice(0, numRecordsToFitBounds))
    // dependency is stringified because we will have a new array every time
  }, [mapRef.current, records.map((r) => r.id).join(',')])

  const cssClasses = (id) =>
    [clickedId === id && 'clicked', hoveredId === id && 'hovered']
      .filter((x) => x)
      .join(' ')

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: google_api_key,
  })

  const adjustedBounds = (map) => {
    const originalBounds = map.getBounds()
    if (!originalBounds) return null

    const normalizeLng = (lng) => {
      let normalized = lng
      while (normalized > 180) normalized -= 360
      while (normalized < -180) normalized += 360
      return normalized
    }

    const normalizeDiff = (difference) => {
      // the difference, meaning the width of the rectangle in degrees, should always be > 0
      let normalized = difference
      while (normalized < 0) normalized += 360
      return normalized
    }

    const ne = originalBounds.getNorthEast()
    const sw = originalBounds.getSouthWest()

    // we are too zoomed out, it makes no sense to search this area,
    // and we get strange behavior.  So return null which hides the "Search This Area" button
    if (Math.abs(ne.lng() - sw.lng()) > 180) return null

    const latPerPixel = (ne.lat() - sw.lat()) / map.getDiv().offsetHeight
    const lngPerPixel =
      normalizeDiff(ne.lng() - sw.lng()) / map.getDiv().offsetWidth

    return {
      north: ne.lat() - boundsSearchPadding.top * latPerPixel,
      south: sw.lat() + boundsSearchPadding.bottom * latPerPixel,
      east: normalizeLng(ne.lng() - boundsSearchPadding.right * lngPerPixel),
      west: normalizeLng(sw.lng() + boundsSearchPadding.left * lngPerPixel),
    }
  }

  let maybeNoResultsMapCard
  if (!totalRecords) maybeNoResultsMapCard = <NoResultsMapCard />
  else if (!records.length)
    maybeNoResultsMapCard = <NoResultsMapCard onMapViewText={true} />

  const isPinOrange = (id) =>
    isStagingEnvironment && recordsToFitBounds.some((x) => x.id === id)

  return (
    <div id="NavigatorClinicMapView">
      <div className="map">
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={{ height: '100%', width: '100%' }}
            onLoad={(map) => {
              mapRef.current = map // eslint-disable-line no-param-reassign
              setMapBounds(adjustedBounds(map))
              map.addListener('idle', () => setMapBounds(adjustedBounds(map)))
            }}
            onClusterClick={onClusterClick}
            options={{
              mapTypeControl: false, // remove the map/satellite toggle
              streetViewControl: false,
              fullscreenControl: false,
            }}
          >
            <MarkerClusterer
              zoomOnClick={false}
              onClick={onClusterClick}
              calculator={clusterCalculator}
              styles={clusterIconStyles}
              maxZoom={zoomLevelWhenZoomedToAClinic - 1}
            >
              {(clusterer) =>
                records.map((record, i) => (
                  <Marker
                    key={record.id}
                    position={{
                      lat: parseFloat(record.lat),
                      lng: parseFloat(record.lng),
                    }}
                    clusterer={clusterer}
                    onClick={() => setClickedId(record.id)}
                    onMouseOver={() => setHoveredId(record.id)}
                    onMouseOut={() => setHoveredId(null)}
                    label={{
                      text: `${i + 1}`,
                      color: isSpecial(record.id) ? '#406dc0' : 'white', // cornflower_blue
                      fontFamily:
                        'MetaSerifPro, MetaSerif, "Times New Roman", Times, serif',
                      fontSize: '14px', // Adjust the font size
                      fontWeight: 'bold', // Adjust the font weight
                    }}
                    icon={{
                      url: isPinOrange(record.id) // eslint-disable-line no-nested-ternary
                        ? pinSvgOrange
                        : isSpecial(record.id)
                        ? pinSelectedSvg
                        : pinSvg,
                      labelOrigin: new window.google.maps.Point(17.5, 15.5),
                    }}
                    zIndex={markerZIndex(record.id, 2000 - i)}
                  >
                    {isSpecial(record.id) && (
                      // Using InfoWindowF to remove extra info box bug
                      // https://github.com/JustFly1984/react-google-maps-api/issues/86
                      // https://www.pivotaltracker.com/n/projects/2490928/stories/186087256
                      <InfoWindow
                        onCloseClick={() => setClickedId(null)}
                        options={{ disableAutoPan: true }}
                      >
                        <div className="tooltipContent">
                          <div className="name">{record.formatted_name}</div>
                          <div className="address">
                            {
                              record.full_address_with_private_address_or_virtual_only
                            }
                          </div>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                ))
              }
            </MarkerClusterer>
            {showMapBounds && mapBoundsPolyline(mapBounds)}
          </GoogleMap>
        )}
      </div>
      {showSearchThisAreaButton && (
        <button
          type="button"
          className="bounds-button"
          disabled={!mapBounds}
          onClick={() => {
            setMappedAreaFilter(mapBounds)
            setShowMapBounds(false)
          }}
          onMouseEnter={() => setShowMapBounds(true)}
          onMouseLeave={() => setShowMapBounds(false)}
        >
          <img src={magnifySvg} alt="" />
          Search This Area
        </button>
      )}
      <div className="cards-container" ref={cardsContainerRef}>
        <BackToTopButton
          cardsContainerRef={cardsContainerRef}
          mapRef={mapRef}
        />
        <div className="cards">
          <MapViewWarningCard
            shown={showTelehealthHiddenCard}
            onClickDismiss={() => setShowTelehealthHidden(false)}
            onClickSetMapView={() => setMapView(false)}
          />
          {records.map((record, i) => (
            <NavigatorClinicCard
              ref={(el) => {
                cardRefs.current[record.id] = el
              }}
              clinic={record}
              cssClasses={cssClasses(record.id)}
              appointment_availability_filter={appointment_availability_filter}
              isSelected={selectedIds.has(record.id)}
              key={record.id}
              onClick={() => setClickedId(record.id)}
              oneBasedIndex={i + 1}
              onMouseEnter={() => setHoveredId(record.id)}
              onMouseLeave={() => setHoveredId(null)}
              setSelectedId={setSelectedId}
            />
          ))}
          {maybeNoResultsMapCard}
        </div>
      </div>
    </div>
  )
}

NavigatorClinicMapView.propTypes = {
  records: PropTypes.arrayOf(
    PropTypes.shape({
      ...clinicTypeBase,
      lat: PropTypes.string,
      lng: PropTypes.string,
    })
  ).isRequired,
  google_api_key: PropTypes.string.isRequired,
  selectedIds: PropTypes.objectOf(PropTypes.bool).isRequired,
  setSelectedId: PropTypes.func.isRequired,
}

export default NavigatorClinicMapView
