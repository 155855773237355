/* A controlled version of WeekAndDayDropdown
 * Provides inputs for _gal_gays and _gal_mode attributes
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import './GalModeDaysInput.scss'

const GalModeDaysInput = ({
  blankOption,
  daysRange,
  daysName,
  daysValue,
  disabled,
  galModeDays,
  hidden,
  modeName,
  modeValue,
  onChange,
  errors,
}) => {
  useEffect(() => {
    // If modeValue is not set, and there is no blankOption,
    // set modeValue to the first entry in galModeDays
    if (
      !modeValue &&
      !blankOption &&
      galModeDays &&
      Object.keys(galModeDays).length > 0
    )
      if (galModeDays && Object.keys(galModeDays).length > 0) {
        const [defaultMode, defaultDays] = Object.entries(galModeDays)[0]
        onChange({ mode: defaultMode, days: defaultDays })
      } else console.error('galModeDays is empty or not provided')
  }, [modeValue, galModeDays, daysValue, onChange])

  const modeOptions = Object.entries(galModeDays || {}).map(([key]) => (
    <option key={key} value={key}>
      {key.replace(/_/g, ' ')}
    </option>
  ))

  const handleWeeksOrModeChange = (e) => {
    const newWeeksOrMode = e.target.value
    const isMode = !(parseInt(newWeeksOrMode, 10) > 0)
    const newMode = isMode ? newWeeksOrMode : 'DAYS'
    const newDays = isMode
      ? galModeDays[newWeeksOrMode]
      : parseInt(newWeeksOrMode, 10) * 7
    onChange({ mode: newMode, days: newDays })
  }

  const handleDaysChange = (e) => {
    const newDays = parseInt(e.target.value, 10)
    onChange({ mode: modeValue, days: Math.floor(daysValue / 7) * 7 + newDays })
  }

  const weeksRange = [
    Math.floor(daysRange[0] / 7),
    Math.floor(daysRange[1] / 7) + 1,
  ]

  const weekOrModeDropdown = (
    <div className="container">
      <select
        data-testid="week-or-mode-dropdown"
        onChange={handleWeeksOrModeChange}
        value={
          modeValue === 'DAYS' ? Math.floor(daysValue / 7) : modeValue || ''
        }
        aria-label="Gestational Age Limit (weeks)"
        disabled={disabled}
      >
        {blankOption && (
          <option value="">
            {typeof blankOption === 'string' ? blankOption : ''}
          </option>
        )}
        {modeOptions}
        {_.range(...weeksRange).map((week) => (
          <option key={week} value={week}>
            {week} weeks
          </option>
        ))}
      </select>
    </div>
  )

  const dayDropdown = modeValue === 'DAYS' && (
    <div className="container">
      <select
        data-testid="day-dropdown"
        onChange={handleDaysChange}
        value={daysValue % 7 || ''}
        aria-label="Gestational Age Limit (days)"
        disabled={disabled}
      >
        {_.range(Math.min(7, daysRange[1] - daysValue + 1)).map((day) => (
          <option key={day} value={day}>
            {day} days
          </option>
        ))}
      </select>
    </div>
  )

  // classname not hitting anything - getting repeated in the min
  // gal days too
  return (
    <div className="GalModeDaysInput">
      {!hidden && weekOrModeDropdown}
      {!hidden && dayDropdown}
      {modeName && (
        <input type="hidden" name={modeName} value={modeValue || ''} />
      )}
      {daysName && (
        <input type="hidden" name={daysName} value={daysValue ?? ''} />
      )}
      <div className="errors">{errors}</div>
    </div>
  )
}

GalModeDaysInput.defaultProps = {
  blankOption: false,
  daysRange: [1, 706],
  daysName: undefined,
  daysValue: null,
  disabled: false,
  galModeDays: {},
  hidden: false,
  modeName: undefined,
  modeValue: null,
}

GalModeDaysInput.propTypes = {
  blankOption: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  daysRange: PropTypes.arrayOf(PropTypes.number),
  daysName: PropTypes.string,
  daysValue: PropTypes.number,
  disabled: PropTypes.bool,
  galModeDays: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])])
  ),
  hidden: PropTypes.bool,
  modeName: PropTypes.string,
  modeValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default GalModeDaysInput
