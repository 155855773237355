import React, { useState } from 'react'
import OfferingsTree from './OfferingsTree'
import './TelehealthServiceOfferingsInput.scss'

/* eslint-disable camelcase, react/prop-types, prefer-template */
const TelehealthServiceOfferingsInput = ({
  treeData,
  initialKnownOfferings,
  use_clinic_known_offerings,
  inputNamePrefix,
}) => {
  // const [knownOfferings, setKnownOfferings] = useState(initialKnownOfferings)
  const [useClinic, setUseClinic] = useState(use_clinic_known_offerings)
  const changeUseClinic = (elem) => {
    setUseClinic(elem.target.checked)
  }

  return (
    <div className="TelehealthServiceOfferingsInput">
      <label>
        <input
          type="checkbox"
          name={inputNamePrefix + '[use_clinic_known_offerings]'}
          checked={useClinic}
          onChange={changeUseClinic}
        />
        Use clinic default telehealth services
      </label>
      {!useClinic && (
        <OfferingsTree
          treeData={treeData}
          initialKnownOfferings={initialKnownOfferings}
          inputNamePrefix={inputNamePrefix + '[known_offerings]'}
          disabledKeys={[
            'abortion_telemedicine',
            'abortion_telehealth_pill_visit',
          ]}
        />
      )}
    </div>
  )
}

export default TelehealthServiceOfferingsInput
