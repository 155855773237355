import React from 'react'
import { humanizeString } from '../../utilities/humanize_string.js'
import GalModeDaysInput from '../../shared/GalModeDaysInput.jsx'
import TrueFalseMixedCheckbox from './TrueFalseMixedCheckbox.jsx'
import rubyConstants from '../../ruby_constants.js'
import './BulkEditAbortionGestationalAgeLimits.scss'

const BulkEditAbortionGestationalAgeLimits = ({
  edits,
  label,
  recordDetails,
  updateEdits,
}) => {
  const aggregateValue = (attribute) => {
    let value
    for (const record of recordDetails) {
      if (record[attribute]) {
        value = value === 'false' ? 'mixed' : 'true'
      } else {
        value = value === 'true' ? 'mixed' : 'false'
      }
      if (value === 'mixed') return 'mixed'
    }
    return value
  }

  const sharedGalTypes = Object.entries(
    rubyConstants.Clinic.GAL_COLUMN_TO_OFFERING
  )
    .filter(([, offering_key]) =>
      recordDetails.every((record) => record.known_offerings[offering_key])
    )
    .map(([days_col]) => days_col)

  // the possible abortion types offered by ALL the clinics
  // eg ['abortion_pill', 'telehealth_pill', 'abortion_procedure']
  // excluding 'counseling', which is not handled by bulk edit (too complicated)
  const abortionTypesOffered = recordDetails
    .reduce(
      (acc, { possible_appointment_types }) =>
        acc.filter((arr) => possible_appointment_types.includes(arr)),
      recordDetails[0].possible_appointment_types
    )
    .filter((el) => el !== 'counseling')

  return (
    <div className="BulkEditAbortionGestationalAgeLimits">
      <hr />
      <h4>{label}</h4>
      {abortionTypesOffered.length < 3 && (
        <div className="abortion-gal-warning-note">
          Note: You will only be able to change gestational age limits for
          abortion types that all selected providers share.
        </div>
      )}
      {sharedGalTypes.map((key) => (
        <div key={key}>
          <h5>{humanizeString(key.replace('_gal_days', ''))}</h5>
          <GalModeDaysInput
            daysValue={edits[key] || null}
            blankOption="No Change"
            galModeDays={rubyConstants.Clinic.GAL_MODE_DAYS}
            modeValue={edits[key.replace('days', 'mode')] || ''}
            onChange={({ days, mode }) =>
              updateEdits({ [key]: days, [key.replace('days', 'mode')]: mode })
            }
          />
          {['abortion_procedure_gal_days', 'abortion_pill_gal_days'].includes(
            key
          ) && (
            <TrueFalseMixedCheckbox
              label={humanizeString(key.replace('_days', '_protected'))}
              onChange={(value) =>
                updateEdits({ [key.replace('_days', '_protected')]: value })
              }
              preexistingValue={aggregateValue(
                key.replace('_days', '_protected')
              )}
              value={`${edits[key.replace('_days', '_protected')] ?? ''}`}
            />
          )}
        </div>
      ))}
    </div>
  )
}

const paramKeys = [
  'abortion_pill_gal_days',
  'abortion_pill_gal_mode',
  'abortion_pill_gal_protected',
  'abortion_procedure_gal_days',
  'abortion_procedure_gal_mode',
  'abortion_procedure_gal_protected',
  'abortion_telehealth_gal_days',
  'abortion_telehealth_gal_mode',
]

export default BulkEditAbortionGestationalAgeLimits
export { paramKeys }
