import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './YesNoUnknownCheckbox.scss'

//
//  YesNoUnknownCheckbox
//

// Produce values to be consumed by ActiveRecord::Type::Boolean.new.deserialize
// and ClinicMixin::known_offerings=
const formOutput = (jsValue) => {
  if (jsValue === true) return '1'
  if (jsValue === false) return '0'
  return ''
}

const YesNoUnknownCheckbox = ({
  disabled,
  isShowingAsCheckboxes,
  name,
  labelId,
  onChange,
  sectionKey,
  value,
  visualStyle,
}) => {
  const [currentValue, setCurrentValue] = useState(value)

  const sendChange = (clickedValue) => {
    if (disabled) return

    let newValue

    if (onChange) {
      if (value !== clickedValue) {
        newValue = clickedValue
      } else {
        newValue = null // clicking True when already True -> Unknown
      }
      onChange(name, newValue)
    } else {
      setCurrentValue(currentValue === clickedValue ? null : clickedValue)
    }
  }

  const valueToCheck = () => (onChange ? value : currentValue)

  const markedClass = (isYes) => (valueToCheck() === isYes ? 'marked' : '')
  const unknownClass = value === null ? 'unknown' : ''

  const disabledClass = disabled ? 'disabled' : ''
  const selectedVisualArr = isShowingAsCheckboxes ? ['✓', '✓'] : ['Y', 'N']
  const inputValue = formOutput(valueToCheck())

  // for conditonally showing/hiding sections associated with checkbox
  if (sectionKey && window.clinics)
    window.clinics.toggleConditionalSection(sectionKey, valueToCheck())

  return (
    <div className={`YesNoUnknownCheckbox ${disabledClass} ${visualStyle}`}>
      <input type="hidden" name={name} value={inputValue} />
      <button
        type="button"
        role="checkbox"
        aria-label="yes"
        aria-labelledby={labelId}
        tabIndex="0"
        aria-checked={valueToCheck() === true}
        className={`box yes ${markedClass(true)} ${unknownClass}`}
        onClick={() => sendChange(true)}
      >
        {selectedVisualArr[0]}
      </button>
      <button
        type="button"
        role="checkbox"
        aria-label="no"
        aria-labelledby={labelId}
        tabIndex="0"
        aria-checked={valueToCheck() === false}
        className={`box no ${markedClass(false)} ${unknownClass}`}
        onClick={() => sendChange(false)}
      >
        {selectedVisualArr[1]}
      </button>
    </div>
  )
}

YesNoUnknownCheckbox.propTypes = {
  name: (props, propName, _componentName) =>
    // Rails form input name, e.g. clinic[known_offerings][birth_control]
    !props.disabled && typeof props[propName] !== 'string'
      ? new Error('name is required unless disabled=true')
      : null,
  value: PropTypes.oneOf([true, false, null]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func, // function( name, newValue: true|false|null )
  visualStyle: PropTypes.string, // class on main div. See YesNoUnknownCheckbox.scss for possible values
  labelId: PropTypes.string,
  sectionKey: PropTypes.string,
}

YesNoUnknownCheckbox.defaultProps = {
  name: null,
  disabled: false,
  visualStyle: '',
  labelId: null,
  sectionKey: null,
  onChange: null,
  value: null,
}

export default YesNoUnknownCheckbox
