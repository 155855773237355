import React, { useState, useEffect, useReducer } from 'react'
import qs from 'qs'
import BulkEditSidebar from './BulkEditSidebar.jsx'
import BulkEditMain from './BulkEditMain.jsx'
import {
  getCheckedCollectionSelectionIds,
  setCheckedCollectionSelectionIds,
  useCollectionSelectionCheckboxes,
} from '../../utilities/active_admin_collection_select_helper.js'
import {
  OrgEmailInput,
  OrgSelectInput,
  OrgUrlInput,
  // OrgInputPlainLabel,
} from '../navigator_support_orgs/inputs/OrgInputs.jsx'
import BulkEditAbortionScheduleInput, {
  paramKeys as BulkEditAbortionScheduleInputParamKeys,
} from './BulkEditAbortionScheduleInput.jsx'
import BulkEditAbortionServices, {
  paramKeys as BulkEditAbortionServicesParamKeys,
} from './BulkEditAbortionServices.jsx'
// import BulkEditAbortionCostAndPricing from './BulkEditAbortionCostAndPricing.jsx'
import PhoneNumberWithExtensionInput from './PhoneNumberWithExtensionInput.jsx'
import BulkEditAbortionGestationalAgeLimits, {
  paramKeys as BulkEditAbortionGestationalAgeLimitsParamKeys,
} from './BulkEditAbortionGestationalAgeLimits.jsx'
import BulkEditAbortionContactInformation, {
  paramKeys as BulkEditAbortionContactInformationParamKeys,
} from './BulkEditAbortionContactInformation.jsx'
import BulkEditAddAndRemoveFrom from './BulkEditAddAndRemoveFrom.jsx'
import './BulkEdit.scss'

const setToNullOption = ['<Set To Null>', 'set_to_nil']

const BulkEdit = ({
  clinicNames,
  selectOptions,
  successPath,
  clinicOfferingsTree,
}) => {
  const [selectedRecordIds, setSelectedRecordIds] = useState([])
  const [recordDetails, setRecordDetails] = useState({})

  useEffect(() => {
    if (selectedRecordIds.length === 0) {
      setRecordDetails([])
      return
    }

    const queryString = qs.stringify(
      { q: { id_eq_any: selectedRecordIds } },
      { arrayFormat: 'brackets' }
    )

    fetch(`/admin/clinics/bulk_edit_clinics_info?scope=all&${queryString}`)
      .then((response) => response.json())
      .then((data) => {
        setRecordDetails(data)
      })
      .catch((error) => console.error('Error fetching data:', error))
  }, [selectedRecordIds])

  const editableAttributeGroups = [
    {
      label: 'Basic Info',
      items: {
        provider_network_id: {
          InputComponent: OrgSelectInput,
          extraProps: {
            options: [setToNullOption, ...selectOptions.provider_network_ids],
          },
          paramKeys: ['provider_network_id'],
        },
        assigned_to_id: {
          InputComponent: OrgSelectInput,
          extraProps: {
            options: [setToNullOption, ...selectOptions.assigned_to_ids],
          },
          paramKeys: ['assigned_to_id'],
        },
        affiliate_membership_ids: {
          InputComponent: BulkEditAddAndRemoveFrom,
          extraProps: {
            attribute: 'affiliate_membership_ids',
            options: selectOptions.affiliate_membership_ids,
          },
          paramKeys: ['affiliate_membership_ids'],
        },
      },
    },
    {
      label: 'Provider Details - General',
      items: {
        phone: {
          InputComponent: PhoneNumberWithExtensionInput,
          paramKeys: ['phone'],
        },
        url: { InputComponent: OrgUrlInput, paramKeys: ['url'] },
        general_email: {
          InputComponent: OrgEmailInput,
          paramKeys: ['general_email'],
        },
        other_languages: {
          InputComponent: BulkEditAddAndRemoveFrom,

          extraProps: {
            options: selectOptions.other_languages,
            attribute: 'other_languages',
          },
          paramKeys: ['other_languages_add_to', 'other_languages_remove_from'],
        },
      },
    },
    {
      label: 'Funding & Payment Details',
      items: {
        clinic_program_ids: {
          InputComponent: BulkEditAddAndRemoveFrom,
          extraProps: {
            options: selectOptions.clinic_program_ids,
            attribute: 'clinic_program_ids',
          },
          paramKeys: [
            'clinic_program_ids_add_to',
            'clinic_program_ids_remove_from',
          ],
        },
      },
    },
    {
      label: 'Abortion Services',
      // disabledText:
      //   !recordDetails?.every((r) => r.known_offerings.abortion_services) &&
      //   'Abortion services must be toggled to "Y" for all selected providers to use bulk edit for abortion services.',
      items: {
        abortion_specific_contact_info: {
          InputComponent: BulkEditAbortionContactInformation,
          paramKeys: BulkEditAbortionContactInformationParamKeys,
        },
        abortion_services: {
          InputComponent: BulkEditAbortionServices,
          paramKeys: BulkEditAbortionServicesParamKeys,
        },
        gestational_age_limits: {
          InputComponent: BulkEditAbortionGestationalAgeLimits,
          paramKeys: BulkEditAbortionGestationalAgeLimitsParamKeys,
        },
        abortion_appointment_and_schedule_info: {
          InputComponent: BulkEditAbortionScheduleInput,
          paramKeys: BulkEditAbortionScheduleInputParamKeys,
        },
        // telehealth_abortion_info: {
        //   InputComponent: OrgInputPlainLabel, // TODO: Replace with correct input
        //   paramKeys: [],
        // },
        // cost_and_pricing not implemented yet
        // cost_and_pricing_of_abortion_services_info: {
        //   InputComponent: BulkEditAbortionCostAndPricing,
        //   paramKeys: [],
        // },
      },
    },
  ]

  const [BulkEditSidebarIsOpen, setBulkEditSidebarIsOpen] = useState(false)
  const [BulkEditMainIsOpen, setBulkEditMainIsOpen] = useState(false)
  const [selectedAttributes, setSelectedAttribute] = useReducer(
    (arr, [attribute, bool]) =>
      bool // based on the checkbox state (true or false), do either
        ? [...arr, attribute] // add the element to selectedAttributes
        : arr.filter((elem) => elem !== attribute), // remove the selected element
    []
  )

  const clickNextButton = () => {
    setBulkEditSidebarIsOpen(false)
    setBulkEditMainIsOpen(true)
  }

  const clickBackButton = () => {
    setBulkEditSidebarIsOpen(true)
    setBulkEditMainIsOpen(false)
    setCheckedCollectionSelectionIds(selectedRecordIds) // also check/uncheck the records in the active admin page
  }

  const clickCancelButton = () => {
    setBulkEditMainIsOpen(false)
    setSelectedRecordIds(getCheckedCollectionSelectionIds())
  }

  useCollectionSelectionCheckboxes(setSelectedRecordIds)

  // make the Active Admin bulk action button open the sidebar
  useEffect(() => {
    const openSidebar = () => {
      $('.dropdown_menu_list_wrapper').fadeOut('fast')
      setBulkEditSidebarIsOpen(true)
    }
    const batchActionButton = document.querySelector(
      'a.batch_action[data-action="bulk_edit"]'
    )
    batchActionButton.addEventListener('click', openSidebar)
    return () => {
      // Unbind the event listener on clean up
      batchActionButton.removeEventListener('click', openSidebar)
    }
  }, [])

  if (BulkEditMainIsOpen && selectedRecordIds.length === 0) clickCancelButton()

  return (
    <div id="BulkEdit">
      {BulkEditSidebarIsOpen && (
        <BulkEditSidebar
          clickNextButton={clickNextButton}
          closeSidebar={() => setBulkEditSidebarIsOpen(false)}
          editableAttributeGroups={editableAttributeGroups}
          recordDetails={recordDetails}
          selectedAttributes={selectedAttributes}
          setSelectedAttribute={setSelectedAttribute}
        />
      )}
      {BulkEditMainIsOpen && (
        <BulkEditMain
          clickBackButton={clickBackButton}
          clickCancelButton={clickCancelButton}
          clinicNames={clinicNames}
          closeMain={() => setBulkEditMainIsOpen(false)}
          editableAttributeGroups={editableAttributeGroups}
          recordDetails={recordDetails}
          clinicOfferingsTree={clinicOfferingsTree}
          selectedRecordIds={selectedRecordIds}
          selectedAttributes={selectedAttributes}
          setSelectedRecordIds={setSelectedRecordIds}
          setSelectedAttribute={setSelectedAttribute}
          successPath={successPath}
        />
      )}
    </div>
  )
}

export default BulkEdit
