import React from 'react'
import Modal from 'react-modal' // married to scss below
import PropTypes from 'prop-types'
import blackXIcon from '../../../../assets/images/blackX.svg'
import { deepMerge } from '../../../utilities/compare_and_merge.js'
import navigatorSupportOrgModalStyles from './navigator_support_org_modal_styles.js'
import './CancelModal.scss'

const baseModalStyle = deepMerge(navigatorSupportOrgModalStyles, {
  overlay: { zIndex: '6010' }, // cancel modal should appear above other modals
})

const CancelModal = ({
  body,
  cancelButtonText,
  closeModal,
  confirmButtonDisabled,
  confirmButtonText,
  confirmModal,
  customStyles,
  header,
  headerClassName,
  isOpen,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={closeModal}
    contentLabel="modal label"
    style={deepMerge(baseModalStyle, customStyles)}
  >
    <div className="CancelModal">
      <div className={`header ${headerClassName}`}>
        <h2>{header}</h2>
        <button className="x-button" type="button" onClick={closeModal}>
          <img src={blackXIcon} alt="close modal" />
        </button>
      </div>
      <div className="cancel-modal-body">{body}</div>
      <div className="modal-buttons">
        <button className="white-button" type="button" onClick={closeModal}>
          {cancelButtonText}
        </button>
        <button
          className="blue-button"
          disabled={confirmButtonDisabled}
          type="button"
          onClick={confirmModal}
        >
          {confirmButtonText}
        </button>
      </div>
    </div>
  </Modal>
)

CancelModal.defaultProps = {
  cancelButtonText: 'No',
  confirmButtonDisabled: false,
  confirmButtonText: 'Yes',
  customStyles: {},
  header: '',
}

CancelModal.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired, // string or JSX
  cancelButtonText: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.string,
  confirmButtonDisabled: PropTypes.bool,
  confirmModal: PropTypes.func.isRequired,
  customStyles: PropTypes.objectOf(PropTypes.string),
  header: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
}

export default CancelModal
