import React, { useState } from 'react'
import { TooltipAnchor, Tooltip } from '../Tooltip'
import './LinkSharingFormClinicOptions.scss'

const optionsTrue = {
  counseling: true,
  pill: true,
  procedure: true,
}

const tooltipText = {
  counseling:
    'State-mandated counseling is not required by any of the selected providers.',
  pill: 'Not offered by any of the selected providers.',
  procedure: 'Not offered by any of the selected providers.',
}

const LinkSharingFormClinicOptions = ({
  availableAbortionOfferings,
  prefix,
}) => {
  const [chosenTypes, setChosenTypes] = useState(optionsTrue)
  const setTypeAsChosenOrNot = (key, bool) =>
    setChosenTypes((prev) => ({ ...prev, [key]: bool }))

  // just in case the network call has an error, we'll default to true if it is undefined
  const isAvailable = (key) => availableAbortionOfferings[key] !== false

  const toggleIsToggled = (key) => isAvailable(key) && chosenTypes[key]

  const nameIfKeyIsChecked = (key) =>
    toggleIsToggled(key) ? `${prefix}[show][${key}][]` : undefined

  return (
    <fieldset id="LinkSharingFormClinicOptions">
      <div className="spacer" />
      <div className="top-row">
        <div>Appointment Availability</div>
        <div>(e.g., usually 3 weeks or less)</div>
      </div>
      <div className="top-row">
        <div>Gestational age limit</div>
        <div>(e.g., through 6 weeks, 0 days)</div>
      </div>
      {Object.keys(optionsTrue).map((key) => (
        <React.Fragment key={key}>
          <TooltipAnchor disabled={isAvailable(key)}>
            <div className="toggles">
              <input
                id={`left-column-checkbox-${key}`}
                type="checkbox"
                value="label"
                name={`${prefix}[show][${key}][]`}
                checked={toggleIsToggled(key)}
                disabled={!isAvailable(key)}
                onChange={(e) => setTypeAsChosenOrNot(key, e.target.checked)}
              />
              <label htmlFor={`left-column-checkbox-${key}`}>{key}</label>
            </div>
            <Tooltip>{tooltipText[key]}</Tooltip>
          </TooltipAnchor>
          <div className="appt-column">
            <input
              type="checkbox"
              disabled={!toggleIsToggled(key)}
              name={nameIfKeyIsChecked(key)}
              value="appt"
              defaultChecked={true}
              data-testid={`check-appt-${key}`}
            />
          </div>
          <div className="gal-column">
            {key !== 'counseling' && (
              <input
                type="checkbox"
                disabled={!toggleIsToggled(key)}
                name={nameIfKeyIsChecked(key)}
                value="gal"
                defaultChecked={true}
                data-testid={`check-gal-${key}`}
              />
            )}
          </div>
        </React.Fragment>
      ))}
    </fieldset>
  )
}

export default LinkSharingFormClinicOptions
