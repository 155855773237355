//
//      Private functions
//

function tippify_with_tippy(domId) {
  const domElement = document.getElementById(domId)
  const { tippy } = global
  tippy(`#${domId}`, {
    content: domElement.firstElementChild,
    allowHTML: true,
    arrow: false,
    trigger: 'click',
    placement: 'bottom',
    theme: 'light-border',
    delay: [0, null],
    offset: [0, 0],
    interactive: true,
    hideOnClick: true,
    onShown(instance) {
      document
        .querySelector('[data-tippy-root]')
        .addEventListener('click', () => {
          instance.hide()
        })
    },
  })
  domElement.innerHTML = ' • • • '
}

//
//      Globally accessible functions
//
window.comments = {
  tippify(domId) {
    if (typeof tippy === 'function') {
      tippify_with_tippy(domId)
    } else {
      document.addEventListener('DOMContentLoaded', () => {
        tippify_with_tippy(domId)
      })
    }
  },
}

export default window.comments
