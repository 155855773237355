import React from 'react'
import { OrgSelectInput } from '../navigator_support_orgs/inputs/OrgInputs.jsx'

const languageServices = ['Translation Services', 'Spanish']

const FilterDrawerLanguageServices = ({ options, value = [], onChange }) => {
  const handleCheckboxChange = (service) => {
    const newValues = value.includes(service)
      ? value.filter((val) => val !== service)
      : [...value, service]

    onChange(newValues)
  }

  const handleSelectChange = (selectedLanguages) => {
    const newValues = [
      ...value.filter((val) => languageServices.includes(val)),
      ...selectedLanguages,
    ]
    onChange(newValues)
  }

  return (
    <div className="drawer-language-filter">
      <div className="checkbox-container">
        {languageServices.map((service) => (
          <label key={service} htmlFor={service}>
            <input
              type="checkbox"
              id={service}
              checked={value.includes(service)}
              onChange={() => handleCheckboxChange(service)}
            />
            <span className="checkbox-label">{service}</span>
          </label>
        ))}
      </div>

      <label className="dropdown-label" htmlFor="additional-languages-select">
        Additional Languages
      </label>
      <div className="visible-select" id="visible-select-id">
        <OrgSelectInput
          isMulti={true}
          options={options}
          value={value.filter((val) => !languageServices.includes(val))}
          onChange={handleSelectChange}
        />
      </div>
    </div>
  )
}

export default FilterDrawerLanguageServices
