import React, { useState } from 'react'
import CancelModal from './CancelModal.jsx'
import SecondStatusModal from './SecondStatusModal.jsx'
import navigatorSupportOrgModalStyles from './navigator_support_org_modal_styles.js'

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)

const StatusChangeModals = ({
  closeModal,
  currentNavigatorSupportOrg,
  IsOpen,
  ogStatus,
  ogPausedStatusStartsOn,
  ogPausedStatusEndsOn,
  processSave,
  updateNavigatorSupportOrg,
  isAdmin,
}) => {
  const [secondModalIsOpen, setSecondModalIsOpen] = useState(false)
  const displayStatus = capitalize(
    currentNavigatorSupportOrg.status.split('_')[0]
  )

  const processSaveOrOpenSecondModal = () => {
    if (currentNavigatorSupportOrg.status.match(/paused/i)) {
      setSecondModalIsOpen(true)
    } else {
      processSave()
    }
  }

  const modalStatusCopy =
    displayStatus.toLowerCase() === 'blocked'
      ? 'This status will cause the support org to only be visible to admins. It will no longer display on Abortionfinder. It will no longer display to navigators.'
      : 'This status will display in Navigate and publicly on Abortion Finder.'

  const scheduleStatusCopy =
    'Scheduling options will be shown on the next screen.'

  const resetStatusAndCloseModal = () => {
    updateNavigatorSupportOrg({
      status: ogStatus,
      paused_status_starts_on: ogPausedStatusStartsOn,
      paused_status_ends_on: ogPausedStatusEndsOn,
    })
    closeModal()
  }

  return (
    <>
      <CancelModal
        header={`Update Status to “${displayStatus}”?`}
        body={
          <>
            <b>
              Are you sure you want to update your organization’s status to “
              {displayStatus}”?
            </b>
            <div style={{ margin: '2px 0' }}>{modalStatusCopy}</div>
            <div>{displayStatus === 'Paused' && scheduleStatusCopy}</div>
          </>
        }
        cancelButtonText="No, Cancel"
        confirmButtonText="Yes, Update"
        isOpen={IsOpen && !secondModalIsOpen}
        closeModal={resetStatusAndCloseModal}
        confirmModal={processSaveOrOpenSecondModal}
      />
      <SecondStatusModal
        closeSecondModal={() => setSecondModalIsOpen(false)}
        currentNavigatorSupportOrg={currentNavigatorSupportOrg}
        customStyles={navigatorSupportOrgModalStyles}
        ogStatus={ogStatus}
        processSave={processSave}
        secondModalIsOpen={secondModalIsOpen}
        updateNavigatorSupportOrg={updateNavigatorSupportOrg}
        isAdmin={isAdmin}
      />
    </>
  )
}

export default StatusChangeModals
