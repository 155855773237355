import PropTypes from 'prop-types'

// Usage
// MyComponent.propTypes = {
//   mySetProp: setOf(PropTypes.number),
// }
export const setOf = (typeChecker) => {
  const validate = (props, propName, componentName) => {
    const propValue = props[propName]
    if (!(propValue instanceof Set)) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Was ${typeof propValue}; must be a Set.`
      )
    }

    for (const value of propValue) {
      const typeError = PropTypes.checkPropTypes(
        { [propName]: typeChecker },
        { [propName]: value },
        propName,
        componentName
      )
      if (typeError) return typeError
    }
    return null
  }

  validate.isRequired = (props, propName, componentName) => {
    if (props[propName] == null) {
      return new Error(
        `Required prop \`${propName}\` was not specified in \`${componentName}\`.`
      )
    }
    return validate(props, propName, componentName)
  }

  return validate
}
