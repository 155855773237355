import React from 'react'
import PropTypes from 'prop-types'
import YesNoUnknownCheckbox from '../../shared/YesNoUnknownCheckbox'

const QuestionRow = ({
  name,
  value,
  onChange,
  label = '',
  isShowingAsCheckboxes = false,
  editable = true,
  labelId = (Math.random() + 1).toString(36).substring(2), // random characters
}) => (
  // KnownOfferings expects "1", "0", or anything else for unknown
  <tr className="QuestionRow">
    <td className="widget">
      <YesNoUnknownCheckbox
        name={name}
        value={value}
        onChange={onChange}
        disabled={!editable}
        isShowingAsCheckboxes={isShowingAsCheckboxes}
        labelId={labelId}
        visualStyle="apart bedsider-colors"
      />
    </td>
    <td className="label">
      <label id={labelId}>{label}</label>
    </td>
  </tr>
)

QuestionRow.propTypes = {
  label: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  ...YesNoUnknownCheckbox.propTypes,
}

export default QuestionRow
