import React from 'react'
import ExpandablePoem from '../ExpandablePoem.jsx'
import ExpandableStanza from '../ExpandableStanza.jsx'
import {
  OrgStringInput,
  OrgSelectInput,
  OrgCreatableSelectInput,
  OrgRadioInput,
} from '../inputs/OrgInputs.jsx'
import rubyConstants from '../../../ruby_constants.js'
import './PoemBasic.scss'
import VisibleToPublic from './visible_to_public.svg'
import Navigators from './visible_to_navigators.svg'

const BasicSidebar = ({
  prefix,
  currentNavigatorSupportOrg,
  updateNavigatorSupportOrg,
  isPrivate,
}) => {
  const privacyLabel = isPrivate ? 'Private:' : 'Public:'
  const notCopy = isPrivate ? 'NOT ' : ''

  return (
    <>
      <div>
        <strong>{privacyLabel}</strong> Your organization will display in{' '}
        <em>Navigate</em> and will {notCopy}publicly display on the Abortion
        finder platform.
      </div>
      <span className="input-header">Organization Display</span>
      <div className="private-option">
        <OrgRadioInput
          key="private_support_org_false"
          label="Public"
          checked={!currentNavigatorSupportOrg.private_support_org}
          name={`${prefix}[private_support_org]`}
          value="false"
          onChange={() =>
            updateNavigatorSupportOrg({ private_support_org: false })
          }
        />
        <img
          src={VisibleToPublic}
          style={{ padding: '0 10px' }}
          alt="Visible to Public"
        />
      </div>
      <div className="private-option">
        <OrgRadioInput
          key="private_support_org_true"
          label="Private"
          checked={currentNavigatorSupportOrg.private_support_org}
          name={`${prefix}[private_support_org]`}
          value="true"
          onChange={() =>
            updateNavigatorSupportOrg({ private_support_org: true })
          }
        />
        <img
          src={Navigators}
          style={{ padding: '0 10px' }}
          alt="Visible to Navigators"
        />
      </div>
    </>
  )
}

const PoemBasic = ({
  currentNavigatorSupportOrg,
  updateNavigatorSupportOrg,
  expanded,
  toggleExpanded,
  prefix,
  selectOptions = {},
  fromServerErrors = {},
  isAdmin,
}) => {
  const { affiliations } = selectOptions
  const orgTypeOptions = rubyConstants.SupportOrg.ALLOWABLE_ORG_TYPE.map(
    (orgType) => ({
      value: orgType,
      label: orgType.replace('PSO', 'Practical Support Organization'),
    })
  )

  const isOrgPaused = currentNavigatorSupportOrg.status.match(/paused/i)

  const adminStatusOptions = Object.entries(
    rubyConstants.SupportOrg.STATUS_VALUES
  )
    .filter(([key, _value]) => !key.startsWith('paused'))
    .map((array) => array.reverse())
    .concat([['Paused', 'paused']])
  const statusOptions = isAdmin
    ? adminStatusOptions
    : [
        ['Active', 'active'],
        ['Paused (may be scheduled for the future)', 'paused'],
      ]

  let pausedStatusHelperText
  if (
    currentNavigatorSupportOrg.status.startsWith('paused') &&
    currentNavigatorSupportOrg.status !== 'paused'
  )
    pausedStatusHelperText = (
      <i>
        To change paused status type or schedule, choose some other status, then
        choose paused
      </i>
    )
  else if (currentNavigatorSupportOrg.status === 'paused')
    pausedStatusHelperText = (
      <i>Paused status scheduling options will be shown when you click save.</i>
    )
  else if (currentNavigatorSupportOrg.paused_status_starts_on) {
    let text = 'Paused status is scheduled '
    if (currentNavigatorSupportOrg.paused_status_ends_on)
      text += `from ${currentNavigatorSupportOrg.paused_status_starts_on} to ${currentNavigatorSupportOrg.paused_status_ends_on}. `
    else
      text += `to start on ${currentNavigatorSupportOrg.paused_status_starts_on}. `
    pausedStatusHelperText = (
      <div className="scheduled-pause-helper-text">
        <div>{text}</div>
        <div>Choose &quot;Paused&quot; and click save to change schedule</div>
      </div>
    )
  }

  return (
    <ExpandablePoem
      header="Basics"
      subheader="Full organization name, Affiliation(s), Organization type, Status"
      expanded={expanded}
      toggleExpanded={toggleExpanded}
      poemClass="PoemBasic"
    >
      <ExpandableStanza
        sidebar={
          <BasicSidebar
            prefix={prefix}
            currentNavigatorSupportOrg={currentNavigatorSupportOrg}
            updateNavigatorSupportOrg={updateNavigatorSupportOrg}
            isPrivate={currentNavigatorSupportOrg.private_support_org}
          />
        }
      >
        <OrgStringInput
          name={`${prefix}[name]`}
          label="Full Organization Name"
          onChange={(value) => updateNavigatorSupportOrg({ name: value })}
          value={currentNavigatorSupportOrg.name}
          isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
          errors={fromServerErrors.name}
        />
        {isAdmin && (
          <OrgStringInput
            name={`${prefix}[short_name]`}
            label="Organization Short Name"
            onChange={(value) =>
              updateNavigatorSupportOrg({ short_name: value })
            }
            value={currentNavigatorSupportOrg.short_name}
          />
        )}
        <OrgCreatableSelectInput
          name={`${prefix}[affiliations][]`}
          label="Affiliation(s) (Optional)"
          value={currentNavigatorSupportOrg.affiliations}
          onChange={(value) =>
            updateNavigatorSupportOrg({ affiliations: value })
          }
          isMulti={true}
          isClearable={true}
          options={affiliations}
          isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
        />
        <OrgSelectInput
          name={`${prefix}[org_type]`}
          label="Organization Type"
          value={currentNavigatorSupportOrg.org_type}
          options={orgTypeOptions}
          onChange={(value) => updateNavigatorSupportOrg({ org_type: value })}
          isMulti={false}
          isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
          unvalid={fromServerErrors.org_type}
          errors={fromServerErrors.org_type}
        />
        {(isAdmin || !(currentNavigatorSupportOrg.status === 'pending')) && (
          <div>
            <OrgSelectInput
              name={null} // this component is for display only
              label="Status"
              value={isOrgPaused ? 'paused' : currentNavigatorSupportOrg.status}
              options={statusOptions}
              onChange={(value) => updateNavigatorSupportOrg({ status: value })}
              isMulti={false}
              isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
            />
            {pausedStatusHelperText}
          </div>
        )}
        {/* The UI elements are in the SecondStatusModal.jsx, but we need the inputs here because the SSM is not in the DOM when you click 'save' */}
        <div className="status-hidden-inputs">
          {[
            'status',
            'paused_status_scheduled',
            'paused_status_starts_on',
            'paused_status_ends_on',
            'website_paused_note_en',
            'website_paused_note_es',
          ].map((key) => (
            <input
              key={key}
              name={`${prefix}[${key}]`}
              type="hidden"
              value={currentNavigatorSupportOrg[key] || ''}
            />
          ))}
        </div>
      </ExpandableStanza>
    </ExpandablePoem>
  )
}

PoemBasic.openIfErrors = [
  'name',
  'short_name',
  'affiliations',
  'org_type',
  'status',
  'website_paused_note_en',
  'website_paused_note_es',
]
export default PoemBasic
