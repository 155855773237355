import React from 'react'
import { humanizeString } from '../../utilities/humanize_string.js'
import {
  OrgPhoneInput,
  OrgSelectInput,
  OrgUrlInput,
} from '../navigator_support_orgs/inputs/OrgInputs.jsx'
import rubyConstants from '../../ruby_constants.js'
import './BulkEditAbortionContactInformation.scss'

const BulkEditAbortionContactInformation = ({
  edits,
  label,
  recordDetails,
  updateEdits,
}) => {
  const getLocationTypes = () => {
    const locationTypes = recordDetails.map((record) => record.location_type)
    const allSame = locationTypes.every(
      (locationType) => locationType === locationTypes[0]
    )
    if (allSame) {
      return locationTypes[0]
    }
    return 'mixed'
  }

  let abortionBookingUrlTypes
  const allLocationTypes = getLocationTypes()

  switch (allLocationTypes) {
    case 'physical_only':
      abortionBookingUrlTypes = 'In Person Only'
      break
    case 'telehealth_only':
      abortionBookingUrlTypes = 'Virtual Only'
      break
    case 'mixed':
      abortionBookingUrlTypes = 'No Change'
      break
    default:
      abortionBookingUrlTypes = null
      break
  }

  const abortionBookingUrlTypeOptions = [
    { value: '', label: 'No Change' },
    ...rubyConstants.Clinic.ALLOWABLE_ABORTION_BOOKING_TYPES.map((value) => ({
      label: humanizeString(value),
      value,
    })),
  ]

  return (
    <div
      className="BulkEditAbortionContactInformation"
      style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
    >
      <h4>{label}</h4>
      <OrgPhoneInput
        label="Phone Number"
        onChange={(v) => updateEdits({ abortion_specific_phone: v })}
        placeholder="No Change"
        value={edits.abortion_specific_phone || ''}
      />
      {['en', 'es'].map((locale) => (
        <OrgUrlInput
          key={locale}
          label={`Website URL (${locale})`}
          onChange={(val) =>
            updateEdits({
              [`abortion_specific_url_${locale}`]: val,
            })
          }
          value={edits[`abortion_specific_url_${locale}`] || ''}
          placeholder="No Change"
        />
      ))}
      <OrgSelectInput
        label="Abortion Booking Url Type"
        value={edits.abortion_booking_type || ''}
        onChange={(v) => updateEdits({ abortion_booking_type: v })}
        options={abortionBookingUrlTypeOptions}
        isValueDisabled={getLocationTypes() !== 'hybrid'}
        placeholder={abortionBookingUrlTypes}
      />
      <br />
      <div
        className="abortion-booking-url"
        style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
      >
        {['en', 'es'].map((locale) => (
          <OrgUrlInput
            key={locale}
            label={`Abortion Booking URL (${locale})`}
            onChange={(val) =>
              updateEdits({
                [`abortion_booking_url_${locale}`]: val,
              })
            }
            value={edits[`abortion_booking_url_${locale}`]}
            placeholder="No Change"
          />
        ))}
        {edits['abortion_booking_type'] === 'both_with_separate_urls' && (
          <>
            {['en', 'es'].map((locale) => (
              <OrgUrlInput
                key={locale}
                label={`Abortion Booking URL alt-Telehealth (${locale})`}
                onChange={(val) =>
                  updateEdits({
                    [`abortion_booking_url_alt_telehealth_${locale}`]: val,
                  })
                }
                value={edits[`abortion_booking_url_alt_telehealth_${locale}`]}
                placeholder="No Change"
              />
            ))}
          </>
        )}
      </div>
    </div>
  )
}

const paramKeys = [
  'abortion_booking_type',
  'abortion_booking_url_alt_telehealth_en',
  'abortion_booking_url_alt_telehealth_es',
  'abortion_booking_url_en',
  'abortion_booking_url_es',
  'abortion_specific_phone',
  'abortion_specific_url_en',
  'abortion_specific_url_es',
]

export default BulkEditAbortionContactInformation
export { paramKeys }
