import { useState, useEffect } from 'react'

const useScrollDirection = (ref) => {
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [scrollDirection, setScrollDirection] = useState(null)

  useEffect(() => {
    const handleScroll = () => {
      // Ensure the ref is current and the element exists
      if (ref.current) {
        const currentScroll = ref.current.scrollTop

        if (currentScroll > lastScrollTop) {
          setScrollDirection('down')
        } else {
          setScrollDirection('up')
        }

        setLastScrollTop(currentScroll)
      }
    }

    // Add the event listener to the passed ref's current element
    const scrollableElement = ref.current
    if (scrollableElement) {
      scrollableElement.addEventListener('scroll', handleScroll, {
        passive: true,
      })
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', handleScroll)
      }
    }
  }, [lastScrollTop, ref])

  return scrollDirection
}

export default useScrollDirection
