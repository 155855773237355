import React from 'react'
import { humanizeString } from '../../utilities/humanize_string.js'
import {
  OrgInputPlainLabel,
  OrgTextInput,
} from '../navigator_support_orgs/inputs/OrgInputs.jsx'
import AppointmentAvailabilityInput from './AppointmentAvailabilityInput.jsx'
import TrueFalseMixedCheckbox from './TrueFalseMixedCheckbox.jsx'
import './BulkEditAbortionScheduleInput.scss'

const paramKeys = [
  'appointment_availability_abortion_procedure',
  'appointment_availability_abortion_pill',
  'appointment_availability_counseling',
  'appointment_availability_telehealth_pill',
]

const originalAbortionTypeValueFor = (abortionType, day, recordDetails) => {
  let value
  for (const record of recordDetails) {
    const recordValue =
      record.abortion_days_by_type[abortionType]?.includes(day)

    if (recordValue) {
      value = value === 'false' ? 'mixed' : 'true'
    } else {
      value = value === 'true' ? 'mixed' : 'false'
    }
    if (value === 'mixed') return 'mixed'
  }
  return value
}

const BulkEditAbortionScheduleInput = ({
  edits,
  label,
  recordDetails,
  setErrors,
  updateEdits,
}) => {
  // the possible abortion types offered by ALL the clinics
  // eg ['abortion_pill', 'telehealth_pill', 'abortion_procedure']
  // excluding 'counseling', which is not handled by bulk edit (too complicated)
  const abortionTypesOffered = recordDetails
    .reduce(
      (acc, { possible_appointment_types }) =>
        acc.filter((arr) => possible_appointment_types.includes(arr)),
      recordDetails[0].possible_appointment_types
    )
    .filter((el) => el !== 'counseling')

  const getAbbreviatedDayLabel = (fullDay) => {
    const dayMappings = {
      mon: 'm',
      tue: 'tu',
      wed: 'w',
      thu: 'th',
      fri: 'f',
      sat: 's',
      sun: 'su',
    }

    return dayMappings[fullDay] || fullDay
  }

  return (
    <div className="BulkEditAbortionScheduleInput">
      <hr />
      <h4 className="abortion-appt-and-schedule-info-subtitle">{label}</h4>
      {abortionTypesOffered.length < 3 && (
        <div className="abortion-schedule-warning-note">
          Note: You will only be able to change abortion schedule and
          appointment availability for abortion types that all selected
          providers share.
        </div>
      )}

      <h5 className="appointment-availability-subtitle">
        Appointment Availability
      </h5>

      {abortionTypesOffered.map((abortionType) => (
        <div key={abortionType} className="appointment-availability-container">
          <h5>{humanizeString(abortionType)}</h5>
          <AppointmentAvailabilityInput
            onChange={(val) =>
              updateEdits({ [`appointment_availability_${abortionType}`]: val })
            }
            bulkEdit={true}
            setErrors={(bool) => setErrors({ [abortionType]: bool || '' })}
            short_label={abortionType}
            show_no_appointment_required_checkbox={false}
            value={edits[`appointment_availability_${abortionType}`]}
          />
        </div>
      ))}

      <h5 className="abortion-schedule-subtitle">Abortion Schedule</h5>
      {abortionTypesOffered.map((abortionType) => (
        <React.Fragment key={abortionType}>
          <OrgInputPlainLabel
            spanStyle="abortion-schedule-labels"
            label={humanizeString(abortionType)}
          />
          <div className="abortion-days-container" key={abortionType}>
            {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day) => (
              <TrueFalseMixedCheckbox
                key={day}
                onChange={(val) =>
                  updateEdits({
                    partial_abortion_days_by_type: {
                      [abortionType]: { [day]: val },
                    },
                  })
                }
                label={getAbbreviatedDayLabel(day)}
                preexistingValue={originalAbortionTypeValueFor(
                  abortionType,
                  day,
                  recordDetails
                )}
                value={
                  edits.partial_abortion_days_by_type?.[abortionType]?.[day] ||
                  ''
                }
              />
            ))}
          </div>
        </React.Fragment>
      ))}

      {/* abortion notes section */}
      {abortionTypesOffered.map((abortionType) => (
        <div className={abortionType} key={abortionType}>
          {['en', 'es'].map((locale) => (
            <OrgTextInput
              key={locale}
              label={`${humanizeString(
                abortionType
              )} Schedule Note (${locale})`}
              placeholder="No Change"
              onChange={(val) =>
                updateEdits({
                  [`abortion_days_note_${abortionType}_${locale}`]: val,
                })
              }
              value={edits[`abortion_days_note_${abortionType}_${locale}`]}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

export default BulkEditAbortionScheduleInput
export { paramKeys }
