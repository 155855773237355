import React, { useState, useEffect } from 'react'
import axios from 'axios'
import _uniqueId from 'lodash/uniqueId'
import PropTypes from 'prop-types'
import RuleInputModal from './RuleInputModal.jsx'
import { buildArea } from '../../support_orgs/RuleInput.jsx'
import CancelModal from '../modals/CancelModal.jsx'
import EditDelete from './EditDelete.jsx'
import PlusButton from './PlusButton.jsx'
import BigNumberedBullet from './BigNumberedBullet.jsx'
import UsMap from '../../../shared/UsMap.jsx'
import rubyConstants from '../../../ruby_constants.js'
import './RuleEntries.scss'

const AREA_ATTRIBUTES = [
  'state',
  'coverage_type',
  'cities',
  'regions',
  'clinic_ids',
  'id',
  '_destroy',
]

const buildRule = (ruleType = '') => ({
  rule_type: ruleType,
  to_areas: { [_uniqueId('new-to-area-')]: buildArea('to') },
  from_areas: { [_uniqueId('new-from-area-')]: buildArea('from') },
})

const position = (ruleType) => {
  const index = rubyConstants.Rule.RULE_TYPES.indexOf(ruleType)
  if (index < 0) console.warn(`Unexpected type ${JSON.stringify(ruleType)}`)
  return index
}

const RuleEntries = ({
  rules,
  prefix,
  updateRules,
  selectOptions,
  visible,
}) => {
  const orderedRuleIds = Object.entries(rules)
    .filter(([_key, rule]) => !rule._destroy)
    .sort(([_a, a], [_b, b]) => position(a.rule_type) - position(b.rule_type))
    .map(([id, _rule]) => id)

  useEffect(() => {
    // this will apply to new support orgs with no rules yet.  Add one.
    if (orderedRuleIds.length === 0)
      updateRules({ [_uniqueId('new-rul')]: buildRule('anywhere_to_anywhere') })
  })

  const [modalRuleId, setModalRuleId] = useState(null)

  const getStates = (dir) =>
    orderedRuleIds.some((id) => rules[id].rule_type === 'anywhere_to_anywhere')
      ? ['National']
      : orderedRuleIds
          .map((key) => Object.values(rules[key][`${dir}_areas`]))
          .flat()
          .filter((x) => !x._destroy)
          .map((x) => x.state)
          .filter((x, i, self) => self.indexOf(x) === i) // get unique values
          .filter((x) => x.toLowerCase() !== 'national') // only return 'National' for anywhere_to_anywhere

  const isRuleTypeDisabled = (RuleType) => {
    // if we are editing the only rule, anything is fair game
    if (orderedRuleIds.length === 1 && rules[modalRuleId]) return false

    // get the rule types of other rules
    const existingRuleTypes = orderedRuleIds
      .filter((id) => id !== modalRuleId)
      .map((id) => rules[id].rule_type)

    if (existingRuleTypes.length && RuleType === 'anywhere_to_anywhere')
      return true

    // disable an option if another rule has it and it can't have dups
    return (
      existingRuleTypes.includes(RuleType) &&
      rubyConstants.Rule.RULE_TYPE_NO_DUBS.includes(RuleType)
    )
  }

  const areaAttributesInputs = (ruleId) =>
    ['to_areas', 'from_areas'].map((relation) => (
      <div key={relation}>
        {Object.entries(rules[ruleId][relation]).map(([areaId, area]) => (
          <div key={`${ruleId}${areaId}`}>
            {AREA_ATTRIBUTES.map((attr) =>
              attr.endsWith('s') ? (
                area[attr].map((val) => (
                  <input
                    type="hidden"
                    value={val || ''}
                    key={val}
                    name={`${prefix}[${ruleId}][${relation}_attributes][${areaId}][${attr}][]`}
                  />
                ))
              ) : (
                <input
                  type="hidden"
                  name={`${prefix}[${ruleId}][${relation}_attributes][${areaId}][${attr}]`}
                  value={area[attr] || ''}
                  key={attr}
                />
              )
            )}
          </div>
        ))}
      </div>
    ))

  return (
    <div className="RuleEntries">
      {modalRuleId && (
        <RuleInputModal
          closeModal={() => setModalRuleId(null)}
          isOpen={true}
          rule={rules[modalRuleId] || buildRule()}
          ruleNumber={orderedRuleIds.indexOf(modalRuleId) + 1}
          // will be 0 for new rules, which will render 'New Rule' text
          updateRule={(savedRule) => updateRules({ [modalRuleId]: savedRule })}
          selectOptions={selectOptions}
          isRuleTypeDisabled={isRuleTypeDisabled}
        />
      )}

      {Object.values(rules).map(
        (rule) =>
          rule.id &&
          rule._destroy && (
            <React.Fragment key={rule.id}>
              <input
                type="hidden"
                name={`${prefix}[${rule.id}][_destroy]`}
                value={true}
              />
              <input
                type="hidden"
                name={`${prefix}[${rule.id}][id]`}
                value={rule.id}
              />
            </React.Fragment>
          )
      )}

      <div className="rules-and-maps">
        <div className="rules">
          {orderedRuleIds.map((ruleId, i) => (
            <div key={ruleId}>
              <RuleEntry
                rule={rules[ruleId]}
                onClickEdit={() => setModalRuleId(ruleId)}
                disableDelete={orderedRuleIds.length === 1}
                bulletNumber={i + 1}
                key={ruleId}
                updateRule={(newValue) => updateRules({ [ruleId]: newValue })}
                visible={visible}
              />
              <input
                type="hidden"
                name={`${prefix}[${ruleId}][rule_type]`}
                value={rules[ruleId].rule_type}
              />
              <input
                type="hidden"
                name={`${prefix}[${ruleId}][id]`}
                value={rules[ruleId].id || ''}
              />
              <input
                type="hidden"
                name={`${prefix}[${ruleId}][includes_virtual_only_providers]`}
                value={!!rules[ruleId].includes_virtual_only_providers}
              />
              {areaAttributesInputs(ruleId)}
            </div>
          ))}
          <PlusButton
            onClick={() => setModalRuleId(_uniqueId('new-rule-'))}
            text="Add Rule"
            disabled={Object.values(rules).some(
              (rule) => rule.rule_type === 'anywhere_to_anywhere'
            )}
            disabledTooltip='You cannot add a new rule when the current rule is "anywhere to anywhere". Please edit the current rule before adding new rules.'
          />
        </div>
        <UsMap
          highlightStates={getStates('from')}
          highlightStatesAlt={getStates('to')}
          width="175"
        />
      </div>
    </div>
  )
}

const RuleEntry = ({
  rule,
  bulletNumber,
  disableDelete,
  onClickEdit,
  updateRule,
  visible,
}) => {
  if (!rule) return ''

  const [ruleTextFromRails, setRuleTextFromRails] = useState('')
  const [deleteRuleModalIsOpen, setDeleteRuleModalIsOpen] = useState(false)

  useEffect(() => {
    async function fetchText() {
      const response = await axios
        .post('/internal_api/rule_text', { rule }, { responseType: 'text' })
        .catch((err) => console.warn(err))
      if (response.data) setRuleTextFromRails(response.data)
      else setRuleTextFromRails(`Rule #${bulletNumber}`)
    }
    fetchText()
  }, [rule])

  return (
    <div className="RuleEntry">
      <BigNumberedBullet number={bulletNumber} />
      {/* <div className="bullet">•</div> */}
      <ul>
        <li
          className="rule-text"
          // this text is coming from our own rails and can be trusted
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{ __html: ruleTextFromRails }}
        />
      </ul>
      <EditDelete
        onClickEdit={onClickEdit}
        onClickDelete={() => setDeleteRuleModalIsOpen(true)}
        deleteDisabled={disableDelete}
        deleteDisabledTooltip="Support organizations must have at least one eligibility rule set."
      />
      <CancelModal
        header={`Delete Eligibility Rule ${bulletNumber}?`}
        cancelButtonText="No, Cancel"
        confirmButtonText="Yes, Delete Rule"
        body={
          <>
            <b>Are you sure you want to delete this eligibility rule?</b>
            <br />
            This change will show in <i>Navigate</i>
            {visible && ' and publicly on Abortion Finder'}.
          </>
        }
        isOpen={deleteRuleModalIsOpen}
        closeModal={() => setDeleteRuleModalIsOpen(false)}
        confirmModal={() => {
          updateRule({ _destroy: true })
          setDeleteRuleModalIsOpen(false)
        }}
      />
    </div>
  )
}

const areaType = PropTypes.shape({
  state: PropTypes.string,
  coverage_type: PropTypes.string,
  cities: PropTypes.arrayOf(PropTypes.string),
  regions: PropTypes.arrayOf(PropTypes.string),
  clinic_ids: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.number,
  _destroy: PropTypes.bool,
})

const ruleType = PropTypes.shape({
  id: PropTypes.number,
  rule_type: PropTypes.string,
  to_areas: PropTypes.objectOf(areaType),
  from_areas: PropTypes.objectOf(areaType),
  includes_virtual_only_providers: PropTypes.bool,
  _destroy: PropTypes.bool,
})

RuleEntries.propTypes = {
  rules: PropTypes.objectOf(ruleType).isRequired,
  prefix: PropTypes.string.isRequired,
  updateRules: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  selectOptions: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

RuleEntry.propTypes = {
  rule: ruleType.isRequired,
  bulletNumber: PropTypes.number.isRequired,
  disableDelete: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  updateRule: PropTypes.func.isRequired,
}

export default RuleEntries
