import React, { useReducer, useState, useMemo } from 'react'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import blackXIcon from '../../../../assets/images/blackX.svg'
import CancelEditModalModal from '../modals/CancelEditModalModal.jsx'
import NavigatorSupportOrgModalStyles from '../modals/navigator_support_org_modal_styles.js'
import RuleInput from '../../support_orgs/RuleInput.jsx'
import { deepMerge } from '../../../utilities/compare_and_merge.js'

function areaComplete(subRuleType, area) {
  if (!subRuleType || !area.state) return false
  if (subRuleType === 'anywhere' || subRuleType === 'states') return true
  if (subRuleType === 'state_regions') return !!area.regions.length
  if (subRuleType === 'providers') return !!area.clinic_ids.length
  return console.warn(`Unexpected subRuleType "${subRuleType}"`)
}

const rulesInputModalStyle = {
  ...NavigatorSupportOrgModalStyles,
  content: {
    ...NavigatorSupportOrgModalStyles.content,
    width: 'auto',
    minWidth: '600px',
    maxWidth: '850px',
  },
}

const RuleInputModal = ({
  rule,
  ruleNumber,
  updateRule,
  isOpen,
  closeModal,
  selectOptions,
  isRuleTypeDisabled,
}) => {
  // keep local state of the rule as it is edited.
  // only save to the parent if the modal is confirmed.
  const [currentRule, updateCurrentRule] = useReducer(
    (oldValues, newValues) => deepMerge(oldValues, newValues),
    JSON.parse(JSON.stringify(rule)) // start with deep copy
  )

  const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false)

  const ruleChanged = !isEqual(rule, currentRule)

  const confirmModal = () => {
    updateRule(currentRule)
    closeModal()
  }

  const handleCancelRequest = (e) => {
    if (ruleChanged) {
      setCancelModalIsOpen(true)
    } else {
      closeModal(e)
    }
  }

  const ruleComplete = [currentRule.from_areas, currentRule.to_areas].every(
    (areas, i) =>
      Object.values(areas)
        .filter((x) => !x._destroy)
        .every((area) =>
          areaComplete(currentRule.rule_type.split('_to_')[i], area)
        )
  )

  const sortedClinicsByStatesServed = useMemo(() => {
    const sortedClinics = selectOptions.clinics.reduce(
      (accumulator, clinic) => {
        clinic.states_served?.forEach((state) => {
          accumulator[state] ||= []
          accumulator[state].push([clinic.name, clinic.id])
        })
        return accumulator
      },
      {}
    )

    // Sort clinics within each state alphabetically by clinic name
    Object.keys(sortedClinics).forEach((state) => {
      sortedClinics[state].sort((a, b) => a[0].localeCompare(b[0]))
    })

    return sortedClinics
  }, [selectOptions.clinics])

  return (
    <div className="RuleInputModal">
      <Modal
        isOpen={isOpen}
        onRequestClose={handleCancelRequest}
        contentLabel="modal label"
        style={rulesInputModalStyle}
      >
        <div className="header">
          <h2>
            {ruleNumber
              ? `Update Eligibility Rule ${ruleNumber}`
              : 'Add Eligibility Rule'}
          </h2>
          <button
            className="x-button"
            type="button"
            onClick={handleCancelRequest}
          >
            <img src={blackXIcon} alt="close modal" />
          </button>
        </div>

        {/* <div>{JSON.stringify(rule)}</div> */}
        <RuleInput
          rule={currentRule}
          citiesByState={selectOptions.citiesByState}
          regionsByState={selectOptions.regionsByState}
          clinicsByState={sortedClinicsByStatesServed}
          updateRule={updateCurrentRule}
          isRuleTypeDisabled={isRuleTypeDisabled}
        />
        <div className="modal-buttons">
          <button
            className="white-button"
            type="button"
            onClick={handleCancelRequest}
            onKeyDown={handleCancelRequest}
          >
            Cancel
          </button>
          <button
            className="blue-button"
            type="button"
            disabled={!ruleChanged || !ruleComplete}
            onClick={confirmModal}
          >
            {ruleNumber ? 'Update' : 'Add '} Rule
          </button>
        </div>
        {cancelModalIsOpen && (
          <CancelEditModalModal
            confirmModal={closeModal}
            closeModal={() => setCancelModalIsOpen(false)}
          />
        )}
      </Modal>
    </div>
  )
}

RuleInputModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  rule: PropTypes.shape({
    id: PropTypes.number,
    to_areas: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    from_areas: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  updateRule: PropTypes.func.isRequired,
}

export default RuleInputModal
